import { LOG_OUT } from 'src/api/configs';
import { axiosMockAdapterInstance } from 'src/api/request';

axiosMockAdapterInstance.onPost(LOG_OUT).reply((config) => {
  const { refreshToken } = JSON.parse(config.data);

  if (refreshToken) {
    return [204, { message: 'success' }];
  }
  return [404, { message: 'Not found' }];
});
