import React from 'react';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';

import { List } from './components';
import { Sortable, Props as SortableProps } from './Sortable';
import { UniqueIdentifier } from '@dnd-kit/core';
import { ColorTab } from './components/Item/type';

const itemCount = 50;
const baseStyles: React.CSSProperties = {
  width: '200px',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
};

const props: Partial<SortableProps> = {
  Container: (props: any) => <List horizontal {...props} />,
  itemCount,
  getItemStyles: () => baseStyles,
  strategy: horizontalListSortingStrategy,
};

export interface FooterItem {
  id: string;
  name: string;
  color?: ColorTab;
}

interface HorizontalItemProps {
  onChangeId?: (id: UniqueIdentifier) => void;
  tabsItem: FooterItem[];
  tabsPosition: string[];
  tabsSelected: string;
  onChangePosition?: (position: string[]) => void;
  onRemoveTab?: (id: string) => void;
  onChangeColor?: (itemSelected: FooterItem, color: ColorTab) => void;
  indexLoading?: number | null;
}

const MIN_TABS = 1;

const HorizontalItem: React.FC<HorizontalItemProps> = ({
  onChangeId,
  tabsItem,
  tabsPosition,
  onChangePosition,
  tabsSelected,
  onRemoveTab,
  indexLoading,
  onChangeColor,
}) => (
  <Sortable
    {...props}
    onChangeId={onChangeId}
    removable={tabsItem.length > MIN_TABS}
    tabsItem={tabsItem}
    tabsPosition={tabsPosition}
    tabsSelected={tabsSelected}
    onChangePosition={onChangePosition}
    onRemoveTab={onRemoveTab}
    indexLoading={indexLoading}
    onChangeColor={onChangeColor}
  />
);
export default HorizontalItem;
