import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import classNames from 'classnames';
import React, { useEffect } from 'react';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { Handle, Remove } from './components';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import styles from './Item.module.scss';
import { Box } from '@mui/material';
import { ColorTab } from './type';
export interface Props {
  onClickItem?: () => void;
  isActive?: boolean;
  valueTitle?: string;
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  onRemove?(): void;
  backgroundTabColor?: ColorTab;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    value: Props['value'];
  }): React.ReactElement;
  isLoading?: boolean;
}

export const Item = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        backgroundTabColor,
        transition,
        transform,
        value,
        wrapperStyle,
        isActive,
        valueTitle,
        onClickItem,
        isLoading = false,
        ...props
      },
      ref,
    ) => {
      const isHasBackgroundTabColor = backgroundTabColor ? true : false;
      const isWhiteColor =
        backgroundTabColor?.r === 255 &&
        backgroundTabColor?.g === 255 &&
        backgroundTabColor?.b === 255;

      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      const onClick = () => {
        onClickItem && onClickItem();
      };

      const onClickRemoveButton = (event: any) => {
        event.stopPropagation();
        onRemove && onRemove();
      };

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <li
            onClick={onClick}
            className={classNames(
              styles.Wrapper,
              fadeIn && styles.fadeIn,
              sorting && styles.sorting,
              dragOverlay && styles.dragOverlay,
            )}
            style={
              {
                ...wrapperStyle,
                transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
                '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                '--index': index,
                '--color': color,
              } as React.CSSProperties
            }
            ref={ref}
          >
            <div
              className={classNames(
                styles.Item,
                isActive && styles.ItemActive,
                dragging && styles.dragging,
                handle && styles.withHandle,
                dragOverlay && styles.dragOverlay,
                disabled && styles.disabled,
                color && styles.color,
              )}
              style={{
                ...style,
                backgroundColor: isHasBackgroundTabColor
                  ? `rgba(${backgroundTabColor.r}, ${backgroundTabColor.g}, ${backgroundTabColor.b}, ${backgroundTabColor.a})`
                  : 'white',
                opacity: isActive ? 1 : 0.5,
                color: isHasBackgroundTabColor && !isWhiteColor ? 'white' : 'black',
              }}
              data-cypress="draggable-item"
              {...(!handle ? listeners : undefined)}
              {...props}
              tabIndex={!handle ? 0 : undefined}
            >
              <div
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {valueTitle}
              </div>
              {/* <span
              className={styles.Actions}
            >
              {onRemove ? (
                <Remove
                  className={styles.Remove}
                  onClick={onRemove} />
              ) : null}
              {handle ? <Handle {...handleProps} {...listeners} /> : null}
            </span> */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div id={`portal-root-${index}`}></div>
                <LoadingButton
                  onClick={onClickRemoveButton}
                  loading={isLoading}
                  sx={{
                    color: 'gray',
                    minWidth: '0 !important',
                    borderRadius: '50%',
                    padding: 0,
                  }}
                  disabled={!onRemove}
                >
                  <CloseIcon />
                </LoadingButton>
              </Box>
            </div>
          </li>
        </div>
      );
    },
  ),
);
