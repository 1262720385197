import React from 'react'
import AdminLogin from 'src/views/Admin/AdminLogin'

const AdminLoginPage: React.FC = () => {
    return (
        <AdminLogin />
    )
}


export default AdminLoginPage