import { Box, CircularProgress } from '@mui/material';
import { forwardRef } from 'react';
import Modal, { ModalRef } from 'src/components/Modal';

interface ConfigModalProps {}

const LoadingModal = forwardRef<ModalRef, ConfigModalProps>((props, ref) => {
  return (
    <Modal ref={ref}>
      <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    </Modal>
  );
});

export default LoadingModal;
