import { Box, Theme, Tooltip, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { CONFIG_SYNTAX_3C_4C_TOOLTIP } from 'src/constants';
import { CheckboxCustom } from 'src/styles/muiCustoms';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
  },
  formGroup: {
    marginLeft: '20px',
  },
}));

interface ConfigSyntax3c4cCustomerProps {
  value?: boolean;
  handleChange?: (value: boolean) => void;
}

const ConfigSyntax3c4cCustomer: React.FC<ConfigSyntax3c4cCustomerProps> = ({
  value,
  handleChange,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [checks, setChecks] = useState<boolean>(false);
  React.useEffect(() => {
    if (value !== undefined && value !== checks) {
      setChecks(value);
    }
  }, [value]);

  const _onChange = (evt: any) => {
    const checked = evt.target.checked;
    setChecks(checked);
    handleChange && handleChange(checked);
  };

  return (
    <Box className={classes.container}>
      <FormGroup aria-label="position" row className={classes.formGroup}>
        <Tooltip
          title={
            <Typography
              dangerouslySetInnerHTML={{
                __html: CONFIG_SYNTAX_3C_4C_TOOLTIP,
              }}
            ></Typography>
          }
          arrow
        >
          <FormControlLabel
            value={value}
            control={<CheckboxCustom checked={checks} />}
            label={'Cú pháp ĐUÔI 3c, 4c'}
            labelPlacement="end"
            onChange={_onChange}
          />
        </Tooltip>
      </FormGroup>
    </Box>
  );
};

export default React.memo(
  ConfigSyntax3c4cCustomer,
  (preValue, nextValue) => preValue.value === nextValue.value,
);
