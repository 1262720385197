import React from 'react';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import { SynTaxResponse } from 'src/services/agenciesServices';
import { useAppSelector } from 'src/store';
import { PercentsConfigTable } from 'src/views/Customer/ConfigTable';
import { DeliverValue } from 'src/views/Customer/DeliveryCustomer';
import { CheckCalKI } from 'src/views/Customer/DirectionsCustomer';
import { StorkTypeID } from 'src/views/Customer/StorkCustomer';

export interface DataTableConfigHookReturn {
  dealerOrder?: string[];
  name?: string;
  deliver?: DeliverValue;
  syntax?: string;
  type?: StorkTypeID;
  calKI?: CheckCalKI;
  percentNorth?: number;
  percentCentral?: number;
  percentSouth?: number;

  sharePercentNorth?: number;
  sharePercentCentral?: number;
  sharePercentSouth?: number;

  northern?: PercentsConfigTable;
  central?: PercentsConfigTable;
  south?: PercentsConfigTable;
  isConfigB?: boolean;
  isSyntax3C4C?: boolean;
  createAt?: string;
  key?: string;
  user_key?: string;
  warning?: number;

  mid_channels?: number[];
  enable_da_cap?: boolean;
  enable_da_trung?: boolean;
}

// const mapDeliversWithResponseApi = (value: boolean): DeliverValue => {
//   return value ? 'deliver' : 'take';
// };

const stringToBoolean = (value: string) => {
  return value === '1' ? true : false;
};

// const mapSyntaxWithResponseApi = (value: SynTaxResponse): string => {
//   return value !== 'DSK' ? '0' : '1';
// };

// const mapTypeWithResponseApi = (value: boolean): StorkTypeID => {
//   return value ? '1' : '0';
// };

export const mapCalKiWithResponseApi = (value: number): CheckCalKI => {
  //   const binaryString = dec2bin(value);
  let binaryString = value.toString(2);
  if (binaryString.length < 3) {
    if (binaryString.length === 1) {
      binaryString = '00' + binaryString;
    } else if (binaryString.length === 2) {
      binaryString = '0' + binaryString;
    }
  }

  return {
    North: stringToBoolean(binaryString[0]),
    Central: stringToBoolean(binaryString[1]),
    South: stringToBoolean(binaryString[2]),
  };
};

export const restoreCalKi = (callKi: CheckCalKI): number => {
  const north = callKi.North ? '1' : '0';
  const central = callKi.Central ? '1' : '0';
  const south = callKi.South ? '1' : '0';
  const temp = north + central + south;
  return parseInt(temp, 2);
  //   return parseInt(temp.split('').reverse().join(''), 2);
};

export const mapPercentWithResponseApi = (
  pecents: number[],
  titles: Readonly<string[]>,
): PercentsConfigTable => {
  // const pecents = value.split(',')
  return titles.map((item, index) => ({
    title: item,
    initValueTop: +pecents[index],
    initValueBottom: +pecents[titles.length + index],
  }));
};

export const restorePercent = (data: PercentsConfigTable): number[] => {
  const topData: number[] = data.map((item) => item.initValueTop);
  const bottomData: number[] = data.map((item) => item.initValueBottom);
  return [...topData, ...bottomData];
};

export const useDataTableConfig = (id: string): DataTableConfigHookReturn => {
  const { agencies } = useAppSelector(selectAgencies);
  return React.useMemo(() => {
    let data: DataTableConfigHookReturn = {};
    // if (agencies?.results?.length > 0 && id) {
    //     const agency = agencies.results.find(agencyItem => agencyItem.id === id)
    //     data['deliver'] = mapDeliversWithResponseApi(agency.deOrRe)
    //     data['syntax'] = mapSyntaxWithResponseApi(agency.syntax)
    //     data['type'] = mapTypeWithResponseApi(agency.type)
    //     data['calKI'] = mapCalKiWithResponseApi(agency.calKI)

    //     const directionPercent = agency.divide.split(',')
    //     data['percentNorth'] = directionPercent[0] as any
    //     data['percentCentral'] = directionPercent[1] as any
    //     data['percentSouth'] = directionPercent[2] as any

    //     const northern = mapPercentWithResponseApi(agency.northern, titleNorthType)
    //     const central = mapPercentWithResponseApi(agency.central, titleCentralType)
    //     const south = mapPercentWithResponseApi(agency.south, titleSouthType)

    //     data['northern'] = northern
    //     data['central'] = central
    //     data['south'] = south
    //     data['name'] = agency.name
    //     data['dealerOrder'] = agency.dealerOrder
    // }
    return data;
  }, [agencies, id]);
};
