import { Box, Button, Theme, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { REDIRECT_TO } from 'src/constants/searchParams';
import { breakpointUp } from 'src/hooks/useBreakpoints';
import { selectAuth } from 'src/reducer/authSlice';
import { HOME_PAGE_PATH } from 'src/routes/paths';
import { useAppSelector } from 'src/store';
import TextInput from 'src/views/Login/TextInput';
import useLogin from './hooks/useLogin';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundImage: `url(/assets/images/background.png)`,
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginContainer: {
    backgroundColor: 'rgba(200, 185, 232, 0.6)',
    borderRadius: '10px',
    width: '600px',
    height: '298px',
    margin: '0 10px',
    boxShadow: '7px 7px 4px rgba(0, 0, 0, 0.30)',
    ...breakpointUp(theme, {
      sm: {
        height: '355px',
      },
      lg: {
        height: '390px',
      },
    }),
  },
  input: {
    color: '#D6C9FA !important',
  },
  loginInput: {
    borderRadius: '7px',
    backgroundColor: '#9978F3',
    width: '100%',
  },
  textInputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  btnLogin: {
    backgroundColor: '#4C5E97 !important',
    padding: '14px 35px !important',
    borderRadius: '10px !important',
  },
  loginMarginTop: {
    marginTop: '70px',
    ...breakpointUp(theme, {
      lg: {
        marginTop: '90px',
      },
    }),
  },
  marginTop: {
    marginTop: '30px',
    ...breakpointUp(theme, {
      lg: {
        marginTop: '50px',
      },
    }),
  },
  txtError: {
    color: theme.palette.error.main,
    marginBottom: '10px !important',
  },
}));

const Login: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  let [searchParams] = useSearchParams();

  const { errorMessage, token } = useAppSelector(selectAuth);
  const { loginWithEmailPassword } = useLogin();

  const [userName, setUserName] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const onClickLogin = () => {
    loginWithEmailPassword(userName, password);
  };

  //TODO: flash screen
  if (token) {
    const redirectToParams = searchParams.get(REDIRECT_TO);
    return <Navigate to={redirectToParams ? `/${redirectToParams}` : HOME_PAGE_PATH} />;
  }
  return (
    <Paper className={classes.background}>
      <Box className={classes.loginContainer}>
        <Box className={clsx(classes.textInputContainer, classes.loginMarginTop)}>
          {errorMessage && <Typography className={classes.txtError}>{errorMessage}</Typography>}
          <TextInput
            label="Tên Đăng Nhập"
            type={'text'}
            value={userName}
            onChange={(value) => setUserName(value)}
          />
        </Box>
        <Box className={clsx(classes.textInputContainer, classes.marginTop)}>
          <TextInput
            label="Mật Khẩu"
            type={'mk'}
            value={password}
            onChange={(value) => setPassword(value)}
          />
        </Box>
        <Box className={clsx(classes.textInputContainer, classes.marginTop)}>
          <Button variant="contained" className={classes.btnLogin} onClick={onClickLogin}>
            Đăng Nhập
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default Login;
