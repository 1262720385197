import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { selectAuth } from 'src/reducer/authSlice';
import { useAppSelector } from 'src/store';
import { PRIMARY_COLOR } from 'src/styles/colors';
import LockEmergency from './LockEmergency';
import NavBar from './NavBar';
import SettingMenus from './SettingMenus';

const Header = () => {
  const { name, id, expired } = useAppSelector(selectAuth);

  return (
    <AppBar position="static" sx={{ backgroundColor: PRIMARY_COLOR }}>
      <Container
        maxWidth={false}
        sx={{
          userSelect: 'all',
        }}
      >
        <Toolbar disableGutters sx={{ minHeight: { sm: '50px', xs: '40px' } }}>
          <NavBar LeftComponents={<LockEmergency id={id} />} />
          <Typography sx={{ marginRight: '20px', fontSize: '0.875rem' }}>{name}</Typography>
          <SettingMenus expired={+expired} />
          {/* <NotificationMenu /> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
