export const orderChannelData = [
  {
    id: '1',
    channel: 'Thứ 2',
    data: [
      {
        id: '1',
        value: 'Thành Phố',
      },
      {
        id: '2',
        value: 'Đồng Tháp',
      },
      {
        id: '3',
        value: 'Cà Mau',
      },
    ],
  },
  {
    id: '2',
    channel: 'Thứ 3',
    data: [
      {
        id: '1',
        value: 'Bến Tre',
      },
      {
        id: '2',
        value: 'Vũng Tàu',
      },
      {
        id: '3',
        value: 'Bạc Liêu',
      },
    ],
  },
  {
    id: '3',
    channel: 'Thứ 4',
    data: [
      {
        id: '1',
        value: 'Đồng Nai',
      },
      {
        id: '2',
        value: 'Cần Thơ',
      },
      {
        id: '3',
        value: 'Sóc Trăng',
      },
    ],
  },
  {
    id: '4',
    channel: 'Thứ 5',
    data: [
      {
        id: '1',
        value: 'Tây Ninh',
      },
      {
        id: '2',
        value: 'An Giang',
      },
      {
        id: '3',
        value: 'Bình Thuận',
      },
    ],
  },
  {
    id: '5',
    channel: 'Thứ 6',
    column: 4,
    data: [
      {
        id: '1',
        value: 'Vĩnh Long',
      },
      {
        id: '2',
        value: 'Bình Dương',
      },
      {
        id: '3',
        value: 'Trà Vinh',
      },
    ],
  },
  {
    id: '6',
    channel: 'Thứ 7',
    column: 3,
    data: [
      {
        id: '1',
        value: 'Thành Phố',
      },
      {
        id: '2',
        value: 'Long An',
      },
      {
        id: '3',
        value: 'Bình Phước',
      },
      {
        id: '4',
        value: 'Hậu Giang',
      },
    ],
  },
  {
    id: '7',
    channel: 'Chủ nhật',
    data: [
      {
        id: '1',
        value: 'Tiền Giang',
      },
      {
        id: '2',
        value: 'Kiên Giang',
      },
      {
        id: '3',
        value: 'Đà Lạt',
      },
    ],
  },
];
