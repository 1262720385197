import React, { forwardRef, useImperativeHandle } from 'react';
import ModalMUI from '@mui/material/Modal';

export interface ModalRef {
  handleOpen: () => void;
  handleClose: (callback?: () => void) => void;
}

interface ModalProps {
  children: React.ReactElement;
}

const Modal = forwardRef<ModalRef, ModalProps>((props, ref) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (callback?: () => void) => {
    setOpen(false);
    callback && callback();
  };

  const onCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  useImperativeHandle(
    ref,
    () => ({
      handleOpen,
      handleClose,
    }),
    [],
  );

  return (
    <ModalMUI
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      {children}
    </ModalMUI>
  );
});

export default Modal;
