import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, IconButton, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { breakpointDown, breakpointUp } from 'src/hooks/useBreakpoints';
import { useTabsHandle } from 'src/hooks/useTabsHandle';
import HorizontalItem, { FooterItem } from '../../../components/HorizontalItem';
import React from 'react';
import _ from 'lodash';
import { ColorTab } from 'src/components/HorizontalItem/components/Item/type';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100px',
    width: '100%',
    display: 'flex',
    paddingTop: '3px',
    ...breakpointDown(theme, {
      xl: {
        height: '100%',
      },
      sm: {
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        background: 'rgba(221, 221, 221, 1)',
        height: '66px',
        overflowY: 'hidden',
        overflowX: 'auto',
        '-webkit-overflow-scrolling': 'touch',
      },
      xs: {
        height: '47px',
      },
    }),
  },
  addIcon: {
    fontSize: '32px !important',
    ...breakpointUp(theme, {
      xl: {
        fontSize: '35px !important',
      },
    }),
  },
  densityIcon: {
    fontSize: '1.25rem !important',
    margin: '0 auto !important',
    ...breakpointUp(theme, {
      xl: {
        fontSize: '1.5rem !important',
      },
    }),
  },
  containerButton: {
    width: '70px',
    height: '100%',
  },
  divider: {
    backgroundColor: 'gray',
    width: '1px',
    height: '100%',
    boxShadow:
      'box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;',
  },
}));

function BetFooter() {
  const theme = useTheme();
  const classes = useStyles(theme);
  // const { isBottomBarVisible } = useIsVisibleBottomBar();
  const {
    tabsItem,
    tabsPosition,
    tabsSelected,
    addTabsWithDateSelected,
    onChangeTabId,
    onChangeTabPosition,
    onChangeTabColor,
    onRemoveTab,
    isLoadingAdd,
    indexRemoveLoading,
  } = useTabsHandle();

  const onClickAdd = () => {
    addTabsWithDateSelected();
  };

  const onChangeId = React.useCallback(
    _.debounce((id: string) => {
      onChangeTabId && onChangeTabId(id);
    }, 400),
    [],
  );

  const onChangePosition = (position: string[]) => {
    onChangeTabPosition(position);
  };

  const _onRemoveTab = (id: string) => {
    onRemoveTab(id);
  };

  const onChangeColor = (itemSelected: FooterItem, color: ColorTab) => {
    onChangeTabColor(itemSelected.id, color);
  };

  return (
    <Box
      className={classes.container}
      paddingBottom={{ xs: '80px', sm: 0 }}
      // paddingBottom={{ xs: isBottomBarVisible ? 0 : '80px' }}
    >
      <Box className={classes.containerButton}>
        <IconButton
          sx={{
            height: { xs: 'fit-content', lg: '100%' },
            alignItems: { xs: 'flex-start', lg: 'center' },
            paddingTop: { sm: '12px', lg: '7px' },
            width: '100%',
          }}
          onClick={onClickAdd}
          disabled={isLoadingAdd}
        >
          {isLoadingAdd ? (
            <CircularProgress size={'30px'} />
          ) : (
            <AddIcon className={classes.addIcon} />
          )}
        </IconButton>
      </Box>
      {/* TODO: hide */}
      {/* <Box className={classes.containerButton}>
                <IconButton sx={{
                    height: { xs: 'fit-content', lg: '100%' },
                    alignItems: { xs: 'flex-start' },
                    paddingTop: { xs: '14px', sm: '18px', xl: '22px', lg: '16px' },
                    width: '100%'
                }} >
                    <DensityMediumIcon className={classes.densityIcon} />
                </IconButton>
            </Box> */}

      <Box className={classes.divider} />
      <HorizontalItem
        onChangeId={onChangeId}
        onChangePosition={onChangePosition}
        tabsItem={tabsItem}
        tabsPosition={tabsPosition}
        tabsSelected={tabsSelected}
        onRemoveTab={_onRemoveTab}
        indexLoading={indexRemoveLoading}
        onChangeColor={onChangeColor}
      />
    </Box>
  );
}

export default BetFooter;
