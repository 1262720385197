import { AxiosResponse } from 'axios';
import axios from 'src/api';
import {
  CREATE_TAB,
  GET_TABS,
  REMOVE_TAB,
  UPDATE_TAB,
  UPDATE_TAB_COLOR,
  UPDATE_TAB_OPTION_CUSTOMER,
} from 'src/api/configs';

export interface Tab {
  customer_id: string;
  customer_name: string;
  key: string | number;
  order: number;
  user_id: string | number;
  color?: string;
  optional_customer_id: number | null;
}

export const getTabsApi = (): Promise<AxiosResponse<Tab[]>> => {
  return axios.get(GET_TABS);
};

export const DEFAULT_ORDER = 0;

export const createTabApi = ({
  customerId,
  order = DEFAULT_ORDER,
}: {
  customerId: string;
  order?: number;
}): Promise<AxiosResponse<Tab>> => {
  return axios.post(`${CREATE_TAB}/${customerId}/${order}`);
};

export const removeTabApi = ({ tabId }: { tabId: string }): Promise<AxiosResponse<string>> => {
  return axios.post(`${REMOVE_TAB}/${tabId}`);
};

export const updateTabApi = ({
  customerId,
  tabId,
  order,
}: {
  tabId: string;
  customerId: string;
  order: number;
}): Promise<AxiosResponse<Tab>> => {
  return axios.post(`${UPDATE_TAB}/${tabId}`, {
    customer_id: customerId,
    order,
  });
};

export const updateTabColorApi = ({ customerId, tabId, color }): Promise<AxiosResponse<Tab>> => {
  return axios.post(`${UPDATE_TAB_COLOR}/${tabId}`, {
    customer_id: customerId,
    color,
  });
};

export const updateTabOptionCustomer = ({
  tabId,
  customerId,
  optionCustomerId,
}: {
  tabId: string;
  customerId: string;
  optionCustomerId: string;
}): Promise<AxiosResponse<Tab>> => {
  return axios.post(`${UPDATE_TAB_OPTION_CUSTOMER}/${tabId}`, {
    customer_id: customerId,
    optional_customer_id: optionCustomerId,
  });
};
