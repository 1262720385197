import { Box, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import 'draft-js/dist/Draft.css';
import React from 'react';
import { Control, useFormContext } from 'react-hook-form';
import SearchHighlighting from 'src/components/SearchHighlighting';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import { MAIN_COLOR } from 'src/styles/colors';
import 'src/styles/css/hightlight.css';
import { BetMessageForm, RangeError } from '.';
interface BetMessageBillBetMessageItemProps {
  bet?: any;
  onChangeMessage?: (message: string) => void;
  onChangeMessageDebounce?: (message: string) => void;
  control?: Control<BetMessageForm, any>;
  searchMessage?: string;
  rangeErrors?: RangeError;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    marginTop: '5px',
    paddingBottom: '5px',
  },
  txtMessages: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    resize: 'none',
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: '10px',
    padding: '10px',
    fontSize: '16px',
    '&:focus': {
      outline: 'none !important',
      border: `1px solid ${MAIN_COLOR}`,
      'box-shadow': `0 0 10px ${MAIN_COLOR}`,
    },
    overflowY: 'auto',
    ...breakpointDown(theme, {
      lg: {
        maxHeight: '42vh',
      },
      sm: {
        maxHeight: '35vh',
      },
      xs: {
        maxHeight: '45vh',
      },
    }),
  },
  searchHighlight: {
    background: 'yellow',
  },
  errorHighlight: {
    background: 'red',
  },
}));

const BetMessageBillBetMessageItem: React.FC<BetMessageBillBetMessageItemProps> = ({
  // control,
  searchMessage,
  rangeErrors,
  // onChangeMessage,
  onChangeMessageDebounce,
  // bet,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { getValues, setValue } = useFormContext();
  const value = getValues('message');
  // const debounceMessage = React.useCallback(
  //   _.debounce((newValue) => {
  //     onChangeMessageDebounce && onChangeMessageDebounce(newValue);
  //   }, TIME_INPUT_DEBOUNCE),
  //   [],
  // );

  return (
    <Box
      className={classes.container}
      sx={{
        'div[id=placeholder-3kc8v]': {
          color: 'grey',
          fontStyle: 'italic',
        },
      }}
    >
      {/* <Controller
        name="message"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => {
          return (
            <div className={classes.txtMessages}>
              <SearchHighlighting
                initValueText={value ? value : ''}
                onChangeMessageDebounce={(newValue) => {
                  onChange({ target: { value: newValue } });
                  onChangeMessageDebounce && onChangeMessageDebounce(newValue, bet);
                }}
                search={searchMessage}
                error_part={rangeErrors?.error}
                startPos={rangeErrors?.start}
                endPos={rangeErrors?.end}
              />
              
            </div>
          );
        }}
      /> */}
      <div className={classes.txtMessages}>
        <SearchHighlighting
          initValueText={value ? value : ''}
          onChangeMessageDebounce={(newValue) => {
            // onChange({ target: { value: newValue } });
            setValue('message', newValue);
            onChangeMessageDebounce && onChangeMessageDebounce(newValue);
          }}
          search={searchMessage}
          error_part={rangeErrors?.error}
          startPos={rangeErrors?.start}
          endPos={rangeErrors?.end}
        />
      </div>
    </Box>
  );
};

export default BetMessageBillBetMessageItem;
