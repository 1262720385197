import Button from '@mui/material/Button/Button';
import React from 'react';
import ConfirmModal, { ConfirmModalRef } from 'src/components/ConfirmModal';
import { alertInstance } from 'src/contexts/AlertContext';
import { useLogout } from 'src/hooks/useLogout';
import { postLockUser } from 'src/services/userServices';

interface LockEmergencyProps {
  id: string;
}

const LockEmergency: React.FC<LockEmergencyProps> = ({ id }) => {
  const confirmModalRef = React.useRef<ConfirmModalRef>(null);
  // const alertRef = React.useRef<AlertSnackBarRef>(null)
  const { onLogout } = useLogout();
  const onConfirm = async () => {
    try {
      if (id) {
        confirmModalRef.current.onChangeLoading(true);
        await postLockUser({ isLock: true, id });
        onLogout();
      } else {
        throw new Error('Id user not found!');
      }
    } catch (error: any) {
      alertInstance.handleOpen('error', error?.message ?? 'Something wrong!');
    } finally {
      confirmModalRef.current.onChangeLoading(false);
    }
  };
  return (
    <>
      <Button
        variant="text"
        sx={{
          color: '#DDDDDD',
          textTransform: 'capitalize',
          fontWeight: '400',
        }}
        onClick={async () => {
          const data = await confirmModalRef.current.handleOpenAsync();
          if (data === 'OK') {
            onConfirm();
          }
        }}
      >
        Khóa Khẩn Cấp
      </Button>
      <ConfirmModal ref={confirmModalRef} title="Bạn Có Chắc Khóa Tài Khoản?" />
      {/* <AlertSnackBar ref={alertRef} /> */}
    </>
  );
};

export default LockEmergency;
