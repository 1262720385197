import { Box, Stack, Theme, Typography } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { makeStyles, useTheme } from '@mui/styles';
import moment from 'moment';
import React, { useState } from 'react';
import Modal, { ModalRef } from 'src/components/Modal';
import { DATE_FORMAT_TYPE_V } from 'src/constants';
import { alertInstance } from 'src/contexts/AlertContext';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { getFromToTimestamp } from 'src/hooks/useReportTable';
import { getReportNote, getReportNoteV3, ReportNote } from 'src/services/reportServices';
import { ButtonCustom } from 'src/styles/muiCustoms';
import { getDayOfWeekVI } from 'src/utils/moment';
import { DateOfWeekType } from './DayOfWeekSelect';
import ReportTextInput from './TextInputReport';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: '90%',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: '2% 2%',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    ...breakpointDown(theme, {
      lg: {
        overflowY: 'auto',
        width: '35%',
        height: '90%',
        padding: '1% 1%',
      },
      md: {
        overflowY: 'auto',
        width: '70%',
        height: '60%',
        padding: '2% 2%',
      },
      xs: {
        width: '95%',
        height: '70%',
      },
    }),
  },
}));

interface ReportModalProps {
  // onClose?: () => void;
}

export interface ReportModalRef {
  onOpen: (
    dateOfWeek: DateOfWeekType[] | null,
    name: string,
    customerId: string,
    from: Date,
    to?: Date,
  ) => void;
}

export const normalizeReportMessageToString = (
  data: ReportNote,
  name: string,
  from: Date,
  to?: Date,
): DateMessageReport => {
  let time = `${moment(from).format(DATE_FORMAT_TYPE_V)} ${getDayOfWeekVI(
    moment(from).format('dddd'),
  )}`;
  time += to
    ? ` - ${moment(to).format(DATE_FORMAT_TYPE_V)} ${getDayOfWeekVI(moment(to).format('dddd'))}`
    : '';
  return {
    time,
    name,
    oldMoney: 0,
    M: data.M.Money['2C'] === 0 && data.M.Money['3C'] === 0 ? undefined : data.M,
    S: data.S.Money['2C'] === 0 && data.S.Money['3C'] === 0 ? undefined : data.S,
    N: data.N.Money['2C'] === 0 && data.N.Money['3C'] === 0 ? undefined : data.N,
    // ...data,
  };
};

const getValue = (value: number, title?: string) => {
  if (!value) {
    return '';
  }
  if (!title) {
    return value;
  }
  return `${title}: ${value}`;
};

type DateMessageReport = {
  time: string;
  name: string;
  oldMoney: number;
} & Partial<ReportNote>;

const ReportModal = React.forwardRef<ReportModalRef, ReportModalProps>(({}, ref) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [dataMessages, setDataMessages] = useState<DateMessageReport>();
  const [value, copy] = useCopyToClipboard();
  const modalRef = React.useRef<ModalRef>();
  const [isLoading, setIsLoading] = useState(false);

  React.useImperativeHandle(
    ref,
    () => ({
      onOpen,
    }),
    [],
  );

  const onOpen = async (
    dateOfWeek: DateOfWeekType[] | null,
    name: string,
    customerId: string,
    fromProp: Date,
    toProp?: Date,
  ) => {
    modalRef.current.handleOpen();
    setDataMessages(undefined);
    try {
      const { from, to } = getFromToTimestamp({
        from: fromProp,
        to: toProp,
      });
      setIsLoading(true);
      let data: any;
      if (dateOfWeek) {
        data = await getReportNoteV3({
          customerId,
          from,
          to,
          dateOfWeek,
        });
      } else {
        data = await getReportNote({
          customerId,
          from,
          to,
        });
      }
      if (data?.data) {
        const dataMessageTemp = normalizeReportMessageToString(data.data, name, fromProp, toProp);
        setDataMessages(dataMessageTemp);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const textArea = React.useMemo<string>(() => {
    const sum = dataMessages
      ? (dataMessages.S?.Sum ?? 0) + (dataMessages.M?.Sum ?? 0) + (dataMessages.N?.Sum ?? 0)
      : 0;
    const total = dataMessages ? sum + dataMessages.oldMoney : null;

    const dataSouth = dataMessages?.S
      ? `MN: ${getValue(dataMessages.S.Money['2C'], '2c')} ${getValue(
          dataMessages.S.Money['3C'],
          '3c',
        )}\n` +
        // Trúng
        `Trúng: ${getValue(dataMessages.S.Win['2C'], '2c')} ${getValue(
          dataMessages.S.Win['3C'],
          '3c',
        )} ${getValue(dataMessages.S.Win['4C'], '4c')} ${getValue(
          dataMessages.S.Win['DX'],
          'dx',
        )} ${getValue(dataMessages.S.Win['DT'], 'dt')}\n` +
        // Cái Lời/Lỗ
        `${dataMessages.S.Sum >= 0 ? 'Cái Lời:' : 'Cái lỗ:'} ${getValue(dataMessages.S.Sum)}\n`
      : '';

    const dataMiddle = dataMessages?.M
      ? `MT: ${getValue(dataMessages.M.Money['2C'], '2c')} ${getValue(
          dataMessages.M.Money['3C'],
          '3c',
        )}\n` +
        // Trúng
        `Trúng: ${getValue(dataMessages.M.Win['2C'], '2c')} ${getValue(
          dataMessages.M.Win['3C'],
          '3c',
        )} ${getValue(dataMessages.M.Win['4C'], '4c')} ${getValue(
          dataMessages.M.Win['DX'],
          'dx',
        )} ${getValue(dataMessages.M.Win['DT'], 'dt')}\n` +
        // Cái Lời/Lỗ
        `${dataMessages.M.Sum >= 0 ? 'Cái Lời:' : 'Cái lỗ:'} ${getValue(dataMessages.M.Sum)}\n`
      : '';

    const dataNorth = dataMessages?.N
      ? `MB: ${getValue(dataMessages.N.Money['2C'], '2c')} ${getValue(
          dataMessages.N.Money['3C'],
          '3c',
        )}\n` +
        // Trúng
        `Trúng: ${getValue(dataMessages.N.Win['2C'], '2c')} ${getValue(
          dataMessages.N.Win['3C'],
          '3c',
        )} ${getValue(dataMessages.N.Win['4C'], '4c')} ${getValue(
          dataMessages.N.Win['DX'],
          'dx',
        )} ${getValue(dataMessages.N.Win['DT'], 'dt')}\n` +
        // Cái Lời/Lỗ
        `${dataMessages.N.Sum >= 0 ? 'Cái Lời:' : 'Cái lỗ:'} ${getValue(dataMessages.N.Sum)}\n`
      : '';

    return dataMessages
      ? `${dataMessages.time}\n` +
          `${dataMessages.name}\n` +
          dataSouth +
          dataMiddle +
          dataNorth +
          `Tổng: ${getValue(sum, sum >= 0 ? 'cái lời' : 'cái lỗ')}\n` +
          `Tiền Cũ: ${getValue(dataMessages.oldMoney)}\n` +
          `Total: ${getValue(total, total < 0 ? 'Cái lỗ' : 'Cái lời')}`
      : '';
  }, [dataMessages]);

  const onClickCopy = (textArea: string) => {
    if (textArea) {
      copy(textArea);
      alertInstance.handleOpen('success', 'Sao chép thành công');
    }
  };

  const onUpdate = (value: number) => {
    setDataMessages((pre) => ({
      ...pre,
      oldMoney: value,
    }));
  };

  return (
    <Modal ref={modalRef}>
      <Box className={classes.container}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
            }}
          >
            <Typography variant={'h6'}>
              {dataMessages ? `Báo sổ ${dataMessages.name}` : ''}
            </Typography>
          </Stack>
          <Box mt={{ xl: '20px', lg: '15px', sm: '20px', xs: '15px' }}>
            <ReportTextInput title="Nợ cũ:" onUpdate={onUpdate} />
          </Box>
          {/* TODO: hide */}
          {/* <Box mt={{ xl: '15px', lg: '0px', sm: '15px', xs: '0' }}>
            <ReportTextInput title="Nhân về:" />
          </Box> */}
          {/* <Box mt={{ xl: '15px', lg: '0px', sm: '15px' }}>
            <CheckboxReport title="Ẩn hiện thực thu:" />
          </Box> */}
          {/* <Box
            mt={{
              xl: '15px',
              lg: '10px',
              sm: '15px',
              xs: '10px',
            }}
          >
            <CheckboxReport title="Tách 2c:" />
          </Box> */}
          <TextareaAutosize
            value={!isLoading ? textArea : 'Loading...'}
            // onChange={(e) => setTextArea(e.target.value)}
            aria-label="empty textarea"
            style={{
              width: '100%',
              resize: 'none',
              flex: '1',
              fontSize: '1.25rem',
              padding: '10px',
              marginTop: '15px',
              overflowY: 'auto',
            }}
            readOnly
          />
          <Box
            mt={'15px'}
            sx={{
              display: 'flex',
            }}
          >
            <ButtonCustom
              sx={{
                flex: 1,
                color: 'white',
                mr: '10px',
              }}
              myBackgroundColor={'#34BFA3'}
              disabled={isLoading}
              onClick={() => onClickCopy(textArea)}
            >
              Copy
            </ButtonCustom>
            <ButtonCustom
              sx={{
                color: 'white',
              }}
              myBackgroundColor={'#F4516C'}
              onClick={() => {
                // onClose && onClose();
                modalRef.current.handleClose();
              }}
            >
              Thoát
            </ButtonCustom>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
});

export default ReportModal;
