import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, IconButton, styled, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { forwardRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Modal, { ModalRef } from 'src/components/Modal';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import ComponentToPrint, { PrintResultData } from 'src/pages/PrintResult/ComponentToPrint';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import { getReportGeneral, ReportGeneral } from 'src/services/reportServices';
import { useAppSelector } from 'src/store';
import { getDateFromTimestamp } from 'src/utils/moment';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    height: '99%',
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    padding: '10px 10px 0 10px',
    overflowY: 'auto',
    ...breakpointDown(theme, {
      md: {
        overflowY: 'auto',
      },
    }),
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const BoxContainer = styled(Box)({
  width: '100%',
  background: '#EAEBEE',
  paddingBlock: '20px',
});

const ContentContainer = styled(Box)({
  margin: '0 auto',
  background: 'white',
  // padding: '10px 30px',
  minHeight: '100%',
  borderRadius: '5px',
});

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  alignItems: 'center',
});

const ContentHeaderContainer = styled(Box)({
  // width: '30%',
  display: 'flex',
  justifyContent: 'space-between',
});

const TextHeader = styled(Typography)({
  fontWeight: 'bold',
});

const transferPrintResultData = (data: ReportGeneral): PrintResultData => {
  return {
    B:
      data['N']?.map((item) => ({
        tin: item.name,
        tong2: item.data.t2,
        tong3: item.data.t3,
        trung2: item.data.w2,
        trung3: item.data.w3,
        trung4: item.data.w4,
        trungDa1D: item.data.dt,
        trungX: item.data.dx,
      })) ?? [],
    T:
      data['M']?.map((item) => ({
        tin: item.name,
        tong2: item.data.t2,
        tong3: item.data.t3,
        trung2: item.data.w2,
        trung3: item.data.w3,
        trung4: item.data.w4,
        trungDa1D: item.data.dt,
        trungX: item.data.dx,
      })) ?? [],
    N:
      data['S']?.map((item) => ({
        tin: item.name,
        tong2: item.data.t2,
        tong3: item.data.t3,
        trung2: item.data.w2,
        trung3: item.data.w3,
        trung4: item.data.w4,
        trungDa1D: item.data.dt,
        trungX: item.data.dx,
      })) ?? [],
  };
};

interface PrintResultModalProps {}

export interface PrintResultModalRef {
  handleOpen: (customerId: string, createAt: string) => void;
}

const PrintResultModal = forwardRef<PrintResultModalRef, PrintResultModalProps>(({}, ref) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const modalRef = React.useRef<ModalRef>();
  const componentRef = React.useRef();
  const { agencies } = useAppSelector(selectAgencies);
  const [customerId, setCustomerId] = useState<string>('');
  const [createAt, setCreateAt] = useState<string>('');
  const [isLoading, setIsLoading] = React.useState(false);

  const name = React.useMemo<string>(() => {
    return customerId && agencies.length > 0
      ? agencies.find((item) => item.key.toString() === customerId.toString())?.name
      : '';
  }, [agencies, customerId]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [reportData, setReportData] = React.useState<ReportGeneral>({
    M: [],
    N: [],
    S: [],
  });

  React.useEffect(() => {
    if (customerId && createAt) {
      setIsLoading(true);
      getReportGeneral({ idCustomer: customerId, date: createAt })
        .then((res) => {
          setReportData(res.data);
        })
        .catch((err) => {
          console.error('err123', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [customerId, createAt]);

  const printResultType = React.useMemo<PrintResultData>(
    () => transferPrintResultData(reportData),
    [reportData],
  );

  React.useImperativeHandle(
    ref,
    () => ({
      handleOpen,
    }),
    [],
  );

  const handleOpen = (_customerId: string, _createAt: string) => {
    modalRef.current.handleOpen();
    setCustomerId(_customerId);
    setCreateAt(_createAt);
  };

  const onClickCloseEvent = () => {
    modalRef.current.handleClose();
  };

  return (
    <>
      <Modal ref={modalRef}>
        <Box className={classes.container}>
          {/* Header */}
          <Box className={classes.headerContainer}>
            <IconButton onClick={onClickCloseEvent}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider
            sx={{
              width: '100%',
            }}
          />
          <ContentContainer
            sx={{
              width: {
                md: '70%',
                xs: '100%',
              },
              padding: {
                sm: '10px 30px',
              },
              marginTop: '10px',
            }}
          >
            <HeaderContainer>
              <ContentHeaderContainer
                sx={{
                  width: {
                    md: '30%',
                    sm: '40%',
                    xs: '75%',
                  },
                }}
              >
                <TextHeader>{name}</TextHeader>
                <TextHeader>{getDateFromTimestamp(+createAt, 'DD/MM/YYYY hh:mm:ss')}</TextHeader>
              </ContentHeaderContainer>
              <Button variant="contained" onClick={handlePrint} disabled={isLoading}>
                In
              </Button>
            </HeaderContainer>
            <Box
              sx={{
                overflow: 'auto',
              }}
            >
              {!isLoading ? (
                <ComponentToPrint
                  ref={componentRef}
                  printResultType={printResultType}
                  idCustomer={customerId}
                  date={createAt}
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  Loading...
                </Box>
              )}
            </Box>
          </ContentContainer>
        </Box>
      </Modal>
    </>
  );
});

export default PrintResultModal;
