import { axiosMockAdapterInstance } from "src/api/request";

const db = {
    "user": {
        "id": "5ebac534954b54139806c112",
        "email": "test1@gmail.com",
        "name": "fake name",
        "role": "user"
    },
    "tokens": {
        "access": {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZWJhYzUzNDk1NGI1NDEzOTgwNmMxMTIiLCJpYXQiOjE1ODkyOTg0ODQsImV4cCI6MTU4OTMwMDI4NH0.m1U63blB0MLej_WfB7yC2FTMnCziif9X8yzwDEfJXAg",
            "expires": "2022-03-31T16:18:04.793z"
        },
        "refresh": {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZWJhYzUzNDk1NGI1NDEzOTgwNmMxMTIiLCJpYXQiOjE1ODkyOTg0ODQsImV4cCI6MTU4OTMwMDI4NH0.m1U63blB0MLej_WfB7yC2FTMnCziif9X8yzwDEfJXAg",
            "expires": "2022-03-31T16:18:04.793z"
        }
    }
};

axiosMockAdapterInstance.onPost("/auth/login").reply((config) => {
    const { email, password } = JSON.parse(config.data)

    if (email !== "admin@gmail.com" || password !== "admin") {
        return [401, { message: "Please check your email and password" }];
    }
    return [200, db]
})