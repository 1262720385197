import { ASSET_TOKEN_EXPIRES_STORAGE_KEY, ASSET_TOKEN_SESSION_STORAGE_KEY, REFRESH_TOKEN_EXPIRES_STORAGE_KEY, REFRESH_TOKEN_SESSION_STORAGE_KEY, ROLE_KEY, USER_ID_SESSION_STORAGE_KEY } from "src/constants"

const saveSessionStorageAuth = (role: string, assetToken: string, refreshToken: string, userId?: string) => {
    sessionStorage.setItem(ROLE_KEY, role)
    sessionStorage.setItem(ASSET_TOKEN_SESSION_STORAGE_KEY, assetToken)
    // sessionStorage.setItem(ASSET_TOKEN_EXPIRES_STORAGE_KEY, assetExpires)
    sessionStorage.setItem(REFRESH_TOKEN_SESSION_STORAGE_KEY, refreshToken)
    // sessionStorage.setItem(REFRESH_TOKEN_EXPIRES_STORAGE_KEY, refreshExpires)
    userId && sessionStorage.setItem(USER_ID_SESSION_STORAGE_KEY, userId)
}

const getSessionStorageAuth = (): {
    role: string,
    assetToken: string,
    // assetExpires: string,
    refreshToken: string,
    // refreshExpires: string,
    userId: string,
} => {
    const role = sessionStorage.getItem(ROLE_KEY)
    const assetToken = sessionStorage.getItem(ASSET_TOKEN_SESSION_STORAGE_KEY)
    // const assetExpires = sessionStorage.getItem(ASSET_TOKEN_EXPIRES_STORAGE_KEY)
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_SESSION_STORAGE_KEY)
    // const refreshExpires = sessionStorage.getItem(REFRESH_TOKEN_EXPIRES_STORAGE_KEY)
    const userId = sessionStorage.getItem(USER_ID_SESSION_STORAGE_KEY)
    return {
        role,
        assetToken,
        userId,
        // assetExpires,
        refreshToken,
        // refreshExpires
    }
}

const getSessionAccessToken = () => {
    return sessionStorage.getItem(ASSET_TOKEN_SESSION_STORAGE_KEY)
}

const getSessionRefreshToken = () => {
    return sessionStorage.getItem(REFRESH_TOKEN_SESSION_STORAGE_KEY)
}

const updateSessionAccessToken = (accessToken: string) => {
    sessionStorage.setItem(ASSET_TOKEN_SESSION_STORAGE_KEY, accessToken)
}

const clearSessionStorage = () => {
    sessionStorage.clear()
}

export {
    saveSessionStorageAuth,
    getSessionStorageAuth,
    getSessionAccessToken,
    getSessionRefreshToken,
    updateSessionAccessToken,
    clearSessionStorage
}