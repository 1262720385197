import React from 'react';
import { getAllAgencies, selectAgencies } from 'src/reducer/agenciesSlice';
import {
  AgenciesResponse,
  CreateAgencyParams,
  deleteAgencyApi,
  editAgencyApi,
  postCreateAgencyApi,
} from 'src/services/agenciesServices';
import { useAppDispatch, useAppSelector } from 'src/store';
import { findSubString } from 'src/utils';

export const useCustomer = () => {
  const dispatch = useAppDispatch();
  const { agencies: agenciesData, isFetching } = useAppSelector(selectAgencies);
  const [agencies, setAgencies] = React.useState<AgenciesResponse[]>();
  const [isLoadingCreate, setIsLoadingCreate] = React.useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = React.useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = React.useState(false);
  const [search, setSearch] = React.useState('');
  React.useEffect(() => {
    dispatch(getAllAgencies({}));
  }, []);

  React.useEffect(() => {
    if (agenciesData) {
      if (!search) {
        setAgencies(agenciesData);
      } else {
        const agenciesSearch = agenciesData.filter((item) => findSubString(search, item.name));
        setAgencies(agenciesSearch);
      }
    }
  }, [agenciesData, search]);

  const createCustomer = async (
    params: CreateAgencyParams,
    resolve: (key: string) => void,
    reject: (mess: string) => void,
    funcFinal: () => void,
  ) => {
    setIsLoadingCreate(true);
    try {
      const { data } = await postCreateAgencyApi(params);
      if (data?.key) {
        resolve(data?.key);
      }
      dispatch(getAllAgencies({}));
    } catch (error) {
      const message = error.message ?? 'Something error';
      reject(message);
    } finally {
      setIsLoadingCreate(false);
    }
  };

  const deleteCustomer = async (
    id: string,
    resolve?: () => void,
    reject?: (mess: string) => void,
  ) => {
    setIsLoadingRemove(true);
    try {
      await deleteAgencyApi({ id });
      dispatch(getAllAgencies({}));
      resolve && resolve();
    } catch (error) {
      const mess = error.message ?? 'Something error';
      reject && reject(mess);
    } finally {
      setIsLoadingRemove(false);
    }
  };

  const searchCustomer = (value: string) => {
    setSearch(value);
  };

  const editCustomer = async (
    id: string,
    name: string,
    callback?: () => void,
    reject?: (mess: string) => void,
  ) => {
    setIsLoadingEdit(true);
    try {
      await editAgencyApi({ id, name });
      await dispatch(getAllAgencies({}));
      callback && callback();
    } catch (error) {
      const mess = error.message ?? 'Something error';
      reject && reject(mess);
    } finally {
      setIsLoadingEdit(false);
    }
  };

  return {
    agencies: agencies ?? [],
    // agenciesList,
    createCustomer,
    deleteCustomer,
    searchCustomer,
    editCustomer,
    isLoadingEdit,
    isLoadingCreate,
    isLoadingRemove,
    isFetching,
  };
};
