import { SxProps, Theme } from "@mui/material"
import { useTheme } from '@mui/styles'
import { DESKTOP_BREAKPOINT, LARGE_SCREEN_BREAKPOINT, MediaQueryType, PHONE_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT, TABLET_BREAKPOINT } from "src/styles/breakpoints"

type StylesBreakpointObject = Partial<Record<MediaQueryType, SxProps<Theme>>>

export const breakpointDown = (theme: Theme, { xl, lg, md, sm, xs }: StylesBreakpointObject): any => {
    return {
        // large screens
        ...xl,

        // desktop
        [theme.breakpoints.down(LARGE_SCREEN_BREAKPOINT)]: {
            ...lg
        },

        // small laptop
        [theme.breakpoints.down(DESKTOP_BREAKPOINT)]: {
            ...md
        },

        // tablet
        [theme.breakpoints.down(SMALL_LAPTOP_BREAKPOINT)]: {
            ...sm
        },

        // phone
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
            ...xs
        }
    }
}

export const breakpointUp = (theme: Theme, { xl, lg, md, sm, xs }: StylesBreakpointObject): any => {
    return {
        // phone
        [theme.breakpoints.up(PHONE_BREAKPOINT)]: {
            ...xs
        },

        // tablets
        [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
            ...sm
        },

        // small laptop
        [theme.breakpoints.up(SMALL_LAPTOP_BREAKPOINT)]: {
            ...md
        },

        // desktop
        [theme.breakpoints.up(DESKTOP_BREAKPOINT)]: {
            ...lg
        },

        // large screens
        [theme.breakpoints.up(LARGE_SCREEN_BREAKPOINT)]: {
            ...xl
        }
    }
}

export const breakpointDesktop = (theme: Theme, style: SxProps<Theme>) => ({
    [theme.breakpoints.only(DESKTOP_BREAKPOINT)]: {
        ...style
    }
})

function useBreakpoints(): {
    getBreakpointDown: (param: StylesBreakpointObject) => SxProps<Theme>;
    getBreakpointUp: (param: StylesBreakpointObject) => SxProps<Theme>;
} {
    const theme = useTheme() as Theme

    const getBreakpointDown = (params: StylesBreakpointObject) => {
        return breakpointDown(theme, params)
    }

    const getBreakpointUp = (params: StylesBreakpointObject) => {
        return breakpointUp(theme, params)
    }

    return {
        getBreakpointDown,
        getBreakpointUp
    }
}

export default useBreakpoints