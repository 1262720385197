import { USER } from 'src/api/configs';
import { axiosMockAdapterInstance } from 'src/api/request';

axiosMockAdapterInstance.onGet(USER).reply((config) => {
  const { userId, assetToken } = config.params;
  if (userId && assetToken) {
    const user = {
      name: 'tqlong123',
      email: 'tqlong123@gmail.com',
      role: 'contractor',
      id: userId,
    };
    return [200, user];
  } else if (userId && !assetToken) {
    return [401, { message: 'Please authenticate' }];
  } else if (!userId && assetToken) {
    return [403, { message: 'Forbidden' }];
  } else {
    return [404, { message: 'Not found' }];
  }
});
