import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import { MAIN_COLOR } from 'src/styles/colors';
import { TextFieldCustom } from 'src/styles/muiCustoms';

const centerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 8px',
  height: '45px',
};

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    backgroundColor: MAIN_COLOR,
    color: 'white',
    ...centerStyles,
    ...breakpointDown(theme, {
      lg: {
        height: '30px',
      },
      sm: {
        height: '100%',
      },
      xs: {
        height: '100%',
      },
    }),
  },
  itemContainer: {
    height: '50px',
    ...centerStyles,
    ...breakpointDown(theme, {
      lg: {
        height: '45px',
      },
      sm: {
        height: '100%',
        borderRight: `1px solid ${MAIN_COLOR}`,
      },
      xs: {
        height: '100%',
      },
    }),
    borderBottom: `1px solid ${MAIN_COLOR}`,
  },
}));

type ItemType = 'header' | 'item';

export const titleNorthType = [
  '2D-Đầu',
  '2D-Đuôi',
  '2D-27Lô',
  '3D-Đầu',
  '3D-Đuôi',
  '3D-23Lô',
  '4D-Đuôi',
  '4D-20Lô',
  'Đá',
] as const;

export type NorthConfigType = typeof titleNorthType[number];

export const titleCentralType = [
  '2D-Đầu',
  '2D-Đuôi',
  '2D-7Lô',
  '2D-18Lô',
  '3D-Đầu',
  '3D-Đuôi',
  '3D-7Lô',
  '3D-17Lô',
  '4D-Đuôi',
  '4D-16Lô',
  'Đá',
  'Đá Xiên',
] as const;

export type CentralConfigType = typeof titleCentralType[number];

export const titleSouthType = [
  '2D-Đầu',
  '2D-Đuôi',
  '2D-7Lô',
  '2D-18Lô',
  '3D-Đầu',
  '3D-Đuôi',
  '3D-7Lô',
  '3D-17Lô',
  '4D-Đuôi',
  '4D-16Lô',
  'Đá',
  'Đá Xiên',
] as const;

export type SouthConfigType = typeof titleSouthType[number];

interface ConfigTableValue {
  title: string;
  initValueTop: number;
  initValueBottom: number;
}

export type PercentsConfigTable = ConfigTableValue[];

export interface ConfigTableProps {
  typeConfig?: 'northern' | 'central' | 'south';
  title: string;
  type?: ItemType;
  initValueTop?: number;
  initValueBottom?: number;
  index?: number;
  handleChange?: any;
  highlightTopColor?: string;
}

const ConfigTable: React.FC<ConfigTableProps> = ({
  type = 'item',
  initValueTop,
  initValueBottom,
  title,
  index,
  handleChange,
  typeConfig,
  highlightTopColor = 'white',
}) => {
  const theme = useTheme() as Theme;
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction={{ xl: 'column', lg: 'column', md: 'column', sm: 'row', xs: 'row' }}
      sx={{
        borderRight: `1px solid ${MAIN_COLOR}`,
        ...breakpointDown(theme, {
          sm: {
            borderRight: 0,
          },
        }),
      }}
    >
      <Grid item sm={4} xs={4}>
        <Box className={classes.headerContainer}>
          <Typography>{title}</Typography>
        </Box>
      </Grid>
      <Grid item sm={4} xs={4}>
        <Box
          className={classes.itemContainer}
          sx={
            type === 'header' && {
              borderLeft: `1px solid ${MAIN_COLOR}`,
              ...breakpointDown(theme, {
                sm: {
                  borderLeft: 0,
                  borderTop: `1px solid ${MAIN_COLOR}`,
                },
              }),
            }
          }
        >
          {type === 'header' ? (
            <Typography>Cò</Typography>
          ) : (
            <TextFieldCustom
              type={'number'}
              onChange={handleChange}
              value={initValueTop}
              name={`${typeConfig}.${index}.initValueTop`}
              sx={{
                height: '100%',
                background: highlightTopColor,
              }}
              inputProps={{
                style: {
                  padding: '10px',
                  height: '100%',
                },
                min: 0,
                max: 100,
                step: 'any',
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid item sm={4} xs={4}>
        <Box
          className={classes.itemContainer}
          sx={
            type === 'header' && {
              borderLeft: `1px solid ${MAIN_COLOR}`,
              ...breakpointDown(theme, {
                sm: {
                  borderLeft: 0,
                  borderTop: `1px solid ${MAIN_COLOR}`,
                },
              }),
            }
          }
        >
          {type === 'header' ? (
            <Typography>Trả Thường</Typography>
          ) : (
            <TextFieldCustom
              type={'number'}
              value={initValueBottom}
              name={`${typeConfig}.${index}.initValueBottom`}
              onChange={handleChange}
              sx={{
                height: '100%',
              }}
              inputProps={{
                style: {
                  padding: '10px',
                  height: '100%',
                },
                min: 0,
                step: 'any',
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(
  ConfigTable,
  (pre, next) =>
    pre.initValueTop === next.initValueTop && pre.initValueBottom === next.initValueBottom,
);
