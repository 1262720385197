import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useChangePassword } from 'src/hooks/useChangePassword';
import EditPasswordModal from 'src/views/Customer/EditPasswordModal';

function NavBarAdmin() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const {
    onLogout,
    onCancelModal,
    onChangePassword,
    onFailEditPassword,
    editPasswordRef,
    onSuccessEditPassword,
  } = useChangePassword();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          <MenuItem onClick={onChangePassword}>
            <Typography>Thay đổi mật khẩu</Typography>
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <Typography color={'red'}>Đăng Xuất</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <EditPasswordModal
        ref={editPasswordRef}
        onCancel={onCancelModal}
        onSuccess={onSuccessEditPassword}
        onFail={onFailEditPassword}
      />
    </>
  );
}

export default NavBarAdmin;
