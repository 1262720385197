import { AxiosResponse } from 'axios';
import axios from 'src/api';
import {
  REPORT_DETAIL_V3,
  REPORT_GENERAL,
  REPORT_MESSAGE,
  REPORT_MESSAGE_V3,
} from 'src/api/configs';
import { BetRadioType } from 'src/views/Home/BetMessageBill/BetMessageBillBetItem';
import { DateOfWeekType } from 'src/views/Report/DayOfWeekSelect';
import { RegionSide } from 'src/views/Report/RegionSelect';

export type Direction = 'M' | 'N' | 'S';

export type ReportGeneral = Record<
  Direction,
  {
    name: string;
    data: {
      dt: number;
      dx: number;
      t2: number;
      t3: number;
      w2: number;
      w3: number;
      w4: number;
    };
  }[]
>;

export type ReportGeneralDetail = {
  Message: string;
  dt: number;
  dx: number;
  t2: number;
  t3: number;
  w2: number;
  w3: number;
  w4: number;
};

export const getReportGeneral = ({
  idCustomer,
  date, // timestamp
}: {
  idCustomer: string;
  date: string;
}): Promise<AxiosResponse<ReportGeneral>> => {
  return axios.get(`${REPORT_GENERAL}/${idCustomer}/${date}`);
};

export const getReportGeneralDetail = ({
  idCustomer,
  date,
  order,
  side,
}: {
  idCustomer: string;
  side: Direction;
  date: string;
  order: number;
}): Promise<AxiosResponse<ReportGeneralDetail[]>> => {
  return axios.get(`${REPORT_GENERAL}/${idCustomer}/${side}/${date}/${order}`);
};

export type ReportItem = {
  '2d': number;
  '3d': number;
  cus_id: string;
  da: number;
  name: string;
  num_of_mes: number;
  thu: number;
  total: number;
  trung: number;
  trung_detail: string;
  xac: number;
};

export type ReportCustomerDetail = {
  '2d': number;
  '3d': number;
  '4d': number;
  date: number;
  da: number;
  daxien: number;
  raw_msg: string;
  side: string;
  sotrung: string;
  tientrung: number;
  trung: string;
  thangthua: number;
};

export type ReportNote = Record<
  BetRadioType,
  {
    Money: {
      '2C': number;
      '3C': number;
    };
    Sum: number;
    Win: {
      '2C': number;
      '3C': number;
      '4C': number;
      DT: number;
      DX: number;
    };
  }
>;

export const getReportDetail = ({
  from,
  side,
  to,
  dateOfWeek,
}: {
  side: RegionSide;
  from: number;
  to: number;
  dateOfWeek?: DateOfWeekType[];
}): Promise<AxiosResponse<ReportItem[]>> => {
  if (dateOfWeek && dateOfWeek.length > 0) {
    return axios.get(`${REPORT_DETAIL_V3}/${side}/${from}/${to}/${dateOfWeek.join('')}`);
  } else {
    return axios.get(`${REPORT_DETAIL_V3}/${side}/${from}/${to}`);
  }
};

export const getReportCustomerDetail = ({
  customerId,
  from,
  side,
  to,
  dateOfWeek,
}: {
  customerId: string;
  side: RegionSide;
  from: number;
  to: number;
  dateOfWeek?: DateOfWeekType[];
}): Promise<AxiosResponse<ReportCustomerDetail[]>> => {
  if (dateOfWeek && dateOfWeek.length > 0) {
    return axios.get(
      `${REPORT_DETAIL_V3}/${customerId}/${side}/${from}/${to}/${dateOfWeek.join('')}`,
    );
  }
  return axios.get(`${REPORT_DETAIL_V3}/${customerId}/${side}/${from}/${to}`);
};

export const getReportNote = ({
  customerId,
  from,
  to,
}: {
  customerId: string;
  from: number;
  to: number;
}): Promise<AxiosResponse<ReportNote>> => {
  return axios.get(`${REPORT_MESSAGE}/${customerId}/${from}/${to}`);
};

export const getReportNoteV3 = ({
  customerId,
  from,
  to,
  dateOfWeek,
}: {
  customerId: string;
  from: number;
  to: number;
  dateOfWeek: DateOfWeekType[];
}): Promise<AxiosResponse<ReportNote>> => {
  return axios.get(`${REPORT_MESSAGE_V3}/${customerId}/${from}/${to}/${dateOfWeek?.join('')}`);
};
