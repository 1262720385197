import { signInByEmailPassword } from "src/reducer/authSlice"
import { useAppDispatch } from "src/store"

const useLogin = () => {
    const dispatch = useAppDispatch()
    const loginWithEmailPassword = (username: string, password: string, isAdmin?: boolean) => {
        dispatch(signInByEmailPassword({ username, password, isAdmin }))
    }
    return {
        loginWithEmailPassword
    }
}

export default useLogin