import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckboxCustom } from 'src/styles/muiCustoms';
import React, { useState } from 'react';
import { CONFIG_ENABLE_EXIST_TOOLTIP } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
  },
  formGroup: {
    marginLeft: '20px',
  },
}));

interface ConfigEnableExistProps {
  value?: boolean;
  handleChange?: (value: boolean) => void;
}

const ConfigEnableExist: React.FC<ConfigEnableExistProps> = ({ value, handleChange }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [checks, setChecks] = useState<boolean>(false);
  React.useEffect(() => {
    if (value !== undefined && value !== checks) {
      setChecks(value);
    }
  }, [value]);

  const _onChange = (evt: any) => {
    const checked = evt.target.checked;
    setChecks(checked);
    handleChange && handleChange(checked);
  };

  return (
    <Box className={classes.container}>
      <FormGroup aria-label="position" row className={classes.formGroup}>
        <Tooltip
          title={
            <Typography
              dangerouslySetInnerHTML={{
                __html: CONFIG_ENABLE_EXIST_TOOLTIP,
              }}
            ></Typography>
          }
          arrow
        >
          <FormControlLabel
            value={value}
            control={<CheckboxCustom checked={checks} />}
            label={'Nhận Đá Trùng'}
            labelPlacement="end"
            onChange={_onChange}
          />
        </Tooltip>
      </FormGroup>
    </Box>
  );
};

export default React.memo(
  ConfigEnableExist,
  (preValue, nextValue) => preValue.value === nextValue.value,
);
