import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, Grid, IconButton, styled, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { forwardRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Modal, { ModalRef } from 'src/components/Modal';
import { ERROR_NOT_FOUND } from 'src/constants';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import ComponentToPrintDetail from 'src/pages/PrintResult/ComponentToPrintDetail';
import { Dai, DaiResult } from 'src/pages/PrintResult/types';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import {
  Direction,
  getReportGeneralDetail,
  ReportGeneralDetail,
} from 'src/services/reportServices';
import { useAppSelector } from 'src/store';
import { getDateFromTimestamp } from 'src/utils/moment';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    height: '99%',
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    padding: '10px 10px 0 10px',
    overflowY: 'auto',
    ...breakpointDown(theme, {
      md: {
        overflowY: 'auto',
      },
    }),
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const BoxContainer = styled(Box)({
  width: '100%',
  background: '#EAEBEE',
  paddingBlock: '20px',
});

const ContentContainer = styled(Box)({
  width: '70%',
  margin: '0 auto',
  background: 'white',
  minHeight: '100%',
  borderRadius: '5px',
});

const HeaderContainer = styled(Box)({
  marginBottom: '10px',
});

const ContentHeaderContainer = styled(Grid)({});

const TextHeader = styled(Typography)({
  fontWeight: 'bold',
});

const TextData = styled(Typography)({
  marginLeft: '10px',
});

const HorizontalBox = styled(Grid)({
  alignItems: 'center',
  display: 'flex',
});

interface PrintNavigateStateDetail {
  idCustomer: string;
  tin: string;
  side: Dai;
  date: string;
}

const transferDaiToDirection = (side: Dai): Direction => {
  switch (side) {
    case 'B':
      return 'N';
    case 'T':
      return 'M';
    case 'N':
      return 'S';
  }
};

const getOrderFromTin = (tin: string): number => {
  const temp = tin.split(' ');
  if (temp.length > 1) {
    return Number(temp[1]);
  } else {
    return null;
  }
};

const transferPrintResultDetailData = (data: ReportGeneralDetail[]): DaiResult[] => {
  return (
    data.map((item) => ({
      tin: item.Message,
      tong2: item.t2,
      tong3: item.t3,
      trung2: item.w2,
      trung3: item.w3,
      trung4: item.w4,
      trungDa1D: item.dt,
      trungX: item.dx,
    })) ?? []
  );
};

interface PrintResultDetailModalProps {}

export interface PrintResultDetailModalRef {
  handleOpen: (params: PrintNavigateStateDetail) => void;
}

const PrintResultDetailModal = forwardRef<PrintResultDetailModalRef, PrintResultDetailModalProps>(
  ({}, ref) => {
    const modalRef = React.useRef<ModalRef>();
    const theme = useTheme();
    const classes = useStyles(theme);

    const componentRef = React.useRef();
    const [customerId, setCustomerId] = useState('');
    const [createAt, setCreateAt] = useState('');
    const [side, setSide] = useState<Dai>('B');
    const [tin, setTin] = useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const { agencies } = useAppSelector(selectAgencies);

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const [reportData, setReportData] = React.useState<ReportGeneralDetail[]>([]);

    const name = React.useMemo<string>(() => {
      return agencies.find((item) => item.key.toString() === customerId.toString())?.name ?? '';
    }, [agencies, customerId]);

    React.useEffect(() => {
      if (customerId && createAt && side && tin) {
        const order = getOrderFromTin(tin);
        if (order !== null) {
          setIsLoading(true);
          getReportGeneralDetail({
            idCustomer: customerId,
            date: createAt,
            order,
            side: transferDaiToDirection(side),
          })
            .then((res) => {
              setReportData(res.data);
            })
            .catch((err) => {
              console.error('err123', err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          console.error(ERROR_NOT_FOUND, 'Order id not found!');
        }
      }
    }, [customerId, createAt, side, tin]);

    const results = React.useMemo<DaiResult[]>(
      () => transferPrintResultDetailData(reportData),
      [reportData],
    );

    React.useImperativeHandle(
      ref,
      () => ({
        handleOpen,
      }),
      [],
    );

    const handleOpen = (params: PrintNavigateStateDetail) => {
      modalRef.current.handleOpen();
      setCustomerId(params.idCustomer);
      setSide(params.side);
      setTin(params.tin);
      setCreateAt(params.date);
    };

    const onClickCloseEvent = () => {
      modalRef.current.handleClose();
    };

    return (
      <Modal ref={modalRef}>
        <Box className={classes.container}>
          {/* Header */}
          <Box className={classes.headerContainer}>
            <IconButton onClick={onClickCloseEvent}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <ContentContainer
            sx={{
              width: {
                md: '70%',
                xs: '100%',
              },
              padding: {
                sm: '10px 30px',
              },
              marginTop: '10px',
            }}
          >
            <HeaderContainer>
              <ContentHeaderContainer
                sx={{
                  width: '100%',
                }}
                container
                spacing={'10px'}
              >
                <HorizontalBox item sm={4} xs={12}>
                  <TextHeader>Ngày:</TextHeader>
                  <TextData>{getDateFromTimestamp(+createAt, 'DD/MM/YYYY hh:mm:ss')}</TextData>
                </HorizontalBox>
                <HorizontalBox item sm={3} xs={12}>
                  <TextHeader>Khách:</TextHeader>
                  <TextData>{name}</TextData>
                </HorizontalBox>
                <HorizontalBox item sm={2} xs={6}>
                  <TextHeader>Miền:</TextHeader>
                  <TextData>{side}</TextData>
                </HorizontalBox>
                <HorizontalBox item sm={2} xs={6}>
                  <TextHeader>Tin:</TextHeader>
                  <TextData>{tin}</TextData>
                </HorizontalBox>
                <Grid item sm={1} xs={12}>
                  <Button variant="contained" onClick={handlePrint} disabled={isLoading}>
                    In
                  </Button>
                </Grid>
              </ContentHeaderContainer>
            </HeaderContainer>
            <Box
              sx={{
                overflow: 'auto',
              }}
            >
              {!isLoading ? (
                <ComponentToPrintDetail ref={componentRef} tins={results} />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  Loading...
                </Box>
              )}
            </Box>
          </ContentContainer>
        </Box>
      </Modal>
    );
  },
);

export default PrintResultDetailModal;
