import { Theme } from '@emotion/react'
import { Box, TextField } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import _ from 'lodash'
import { Control, Controller } from 'react-hook-form';
import { BetMessageForm } from '.';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%'
    }
}))

interface BetMessageBillBetItemSearchItemProps {
    control?: Control<BetMessageForm, any>
}

const BetMessageBillBetItemSearchItem: React.FC<BetMessageBillBetItemSearchItemProps> = ({ control }) => {
    const theme = useTheme()
    const classes = useStyles(theme)

    return (
        <Box className={classes.container}>
            <Controller
                name="searchMessage"
                control={control}
                render={({ field: { value, ...rest } }) =>
                    <TextField
                        id="input-with-icon-textfield"
                        label="Tìm Kiếm"
                        sx={{ width: '100%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                        value={value}
                        {...rest}
                    />
                }
            />

        </Box>
    )
}

export default BetMessageBillBetItemSearchItem