import { AxiosResponse } from 'axios';
import axios from 'src/api';
import {
  CHANGE_PASSWORD,
  USER,
  LOGIN_WITH_EMAIL_PASSWORD_API,
  LOG_OUT,
  RESET_PASSWORD,
} from 'src/api/configs';
import { encryptPassword } from 'src/utils/encrypt';

export interface User {
  key: string | number;
  username: string;
  role: Role;
  locked: 'False' | 'True';
  expired: string;
  displayname: string;
}

export type Role = 'admin' | 'user';

export interface UserAuth {
  id?: string;
  expired?: string;
  name?: string;
  refresh_token?: string;
  role?: Role;
  token?: string;
}

export const postEmailAndPassword = (
  username: string,
  password: string,
): Promise<AxiosResponse<UserAuth>> => {
  const enPassword = encryptPassword(password);
  return axios.post(LOGIN_WITH_EMAIL_PASSWORD_API, {
    username,
    password: enPassword,
  });
};

export const getUserApi = (userId: string): Promise<AxiosResponse<User>> => {
  return axios.get(`${USER}/${userId}`);
};

export const logoutApi = () => {
  return axios.post(LOG_OUT);
};

export const changePassword = (password: string, new_password: string) => {
  return axios.post(`${CHANGE_PASSWORD}`, { password, new_password });
};

export const resetPasswordApi = (data: {
  id: string;
  new_password: string;
}): Promise<AxiosResponse<void>> => {
  return axios.post(`${RESET_PASSWORD}`, data);
};
