import { useTheme } from '@emotion/react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Backdrop, { BackdropRef } from 'src/components/Backdrop';
import { DATE_FORMAT_TYPE_IV } from 'src/constants';
import { breakpointUp } from 'src/hooks/useBreakpoints';
import { useChangeTabData } from 'src/hooks/useChangeTabData';
import { useDateTabs } from 'src/hooks/useDateTabs';
import { useNavigateToHome } from 'src/hooks/useNavigateToHome';
import { useRedirectCustomer } from 'src/hooks/useRedirectCustomer';
import { useUserConfigModal } from 'src/hooks/useUserConfigModal';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import { onChangeTabData, TabsItem } from 'src/reducer/tabsSlice';
import { CUSTOMER_PAGE_PATH } from 'src/routes/paths';
import { useAppDispatch, useAppSelector } from 'src/store';
import { MAIN_COLOR } from 'src/styles/colors';
import { getIndexByBet } from 'src/utils';
import { getDateFromTimestamp } from 'src/utils/moment';
import UserConfigModal from 'src/views/Customer/UserConfigModal';
import BetMessageBillBetItem, { BetRadioType } from './BetMessageBillBetItem';
import BetMessageBillBetItemSearchItem from './BetMessageBillBetItemSearchItem';
import BetMessageBillBetMessageItem from './BetMessageBillBetMessageItem';
import BetMessageBillCustomerItem, { CustomerOption } from './BetMessageBillCustomerItem';
import BetMessageBillCustomerOtherItem from './BetMessageBillCustomerOtherItem';
import BetMessageBillDateItem from './BetMessageBillDateItem';
import BetMessageBillFooter from './BetMessageBillFooter';
import BetMessageBillHeader from './BetMessageBillHeader';
import useBetMessageBill from './useBetMessageBill';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: `${MAIN_COLOR} 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px`,
    display: 'flex',
    flexDirection: 'column',
    height: '700px',
    ...breakpointUp(theme, {
      sm: {
        height: '700px',
      },
      lg: {
        height: '100%',
      },
    }),
  },
  dateItem: {
    flex: 1,
    padding: '0 15px',
  },
  customerItem: {
    flex: 1,
    padding: '0 15px',
  },
  betItem: {
    flex: 1,
    padding: '0 15px',
  },
  searchItem: {
    flex: 1,
    padding: '0 15px',
  },
  messageItem: {
    flex: 10,
    padding: '0 15px',
    maxHeight: '45vh',
  },
  actionItem: {
    flex: 1,
    padding: '0 15px',
    margin: '5px 0',
  },
}));

export type BetMessageForm = {
  date: string;
  customer: CustomerOption;
  customer2: CustomerOption;
  bet: BetRadioType;
  searchMessage: string;
  message: string;
};

export type RangeError = {
  error: string;
  start: number;
  end: number;
};

let dateInit = moment().format(DATE_FORMAT_TYPE_IV);

function BetMessageBill() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const backdropRef = React.useRef<BackdropRef>(null);
  const { submitBetMessage, isLoading, disable, betCustomer } = useBetMessageBill();
  const { modalRef, onClickConfig } = useUserConfigModal();
  const [isResetError, setIsResetError] = React.useState<boolean>(false);
  const { agencies, isFetching } = useAppSelector(selectAgencies);
  const dispatch = useAppDispatch();

  const methods = useForm<BetMessageForm>({
    defaultValues: {
      date: dateInit,
      bet: 'S',
      message: '',
    },
  });
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = methods;
  const [rangeErrors, setRangeErrors] = React.useState<RangeError>();

  const searchMessage = watch('searchMessage');
  const date = watch('date');

  const idCustomer = watch('customer.id');
  const { state } = useNavigateToHome();
  const nameCustomer = watch('customer')?.label;
  const message = watch('message');
  const betTemp = React.useRef<BetRadioType | null>(null);
  const customer2Ref = React.useRef<string | number>('');
  const bet = watch('bet');
  React.useEffect(() => {
    betTemp.current = bet;
  }, [bet]);
  const navigate = useNavigate();

  const { isLoading: isLoadinGetTabs } = useDateTabs(date);
  useRedirectCustomer(() => {
    navigate(CUSTOMER_PAGE_PATH);
  });

  React.useEffect(() => {
    if (state?.date) {
      const dateFormat = getDateFromTimestamp(state.date, DATE_FORMAT_TYPE_IV);
      setValue('date', dateFormat);
    }
  }, [state?.date]);

  useEffect(() => {
    if (betCustomer) {
      setValue('bet', betCustomer);
    }
  }, [betCustomer]);

  React.useEffect(() => {
    if (state?.side) {
      setValue('bet', state.side);
    }
  }, [state?.side]);

  const isLoadingBackdrop = isLoading || isLoadinGetTabs || isFetching;

  useEffect(() => {
    if (isLoadingBackdrop) {
      if (!backdropRef.current.isOpen) {
        backdropRef.current.onOpen();
      }
    } else {
      if (backdropRef.current.isOpen) {
        backdropRef.current.onClose();
      }
    }
  }, [isLoadingBackdrop]);

  React.useEffect(() => {
    if (bet && idCustomer) {
      onChangeCustomerData({
        data: {
          bet: bet,
        },
      });
    }
  }, [bet, idCustomer]);

  const { onChangeCustomerData, dateSelected } = useChangeTabData((dataTabSelected: TabsItem) => {
    const {
      data: { idCustomer, bet, message, dataMessage, idCustomer2 },
    } = dataTabSelected;

    customer2Ref.current = idCustomer2;

    if (idCustomer && agencies.length > 0) {
      const name = agencies.find((item) => item.key.toString() === idCustomer.toString())?.name;
      if (name) {
        setValue('customer', {
          id: idCustomer.toString(),
          label: name,
        });
      }
      // FIXME: render multi time
      // if (bet && getValues('bet') !== bet) {
      //   setValue('bet', bet);
      // }
      const index = getIndexByBet(bet);
      if (message && index !== -1) {
        setValue('message', message[index]);
      }
      if (
        message &&
        dataMessage.error_part &&
        index !== -1 &&
        dataMessage.range_error &&
        dataMessage.range_error[index].length > 1 &&
        dataMessage.range_error[index].length > 1
      ) {
        // const arrRangError = getArrString(
        //   message[index],
        //   dataMessage.error_part[index],
        //   dataMessage.range_error[index][0],
        //   dataMessage.range_error[index][1],
        // );
        setRangeErrors({
          error: dataMessage.error_part[index],
          start: dataMessage.range_error[index][0],
          end: dataMessage.range_error[index][1],
        });
      } else {
        setRangeErrors(undefined);
        // setRangeErrors([]);
      }
      isResetError && setIsResetError(false);
    }
  });

  useEffect(() => {
    if (dateInit !== dateSelected) {
      dateInit = dateSelected;
    }
  }, [dateSelected]);

  const onChangeMessageDebounce = (_message: string) => {
    dispatch(
      onChangeTabData({
        side: betTemp.current,
        message: _message,
      }),
    );
    // if (betTemp.current) {
    //   dispatch(
    //     onChangeTabData({
    //       side: betTemp.current,
    //       message: _message,
    //     }),
    //   );
    // }
  };

  const onRemove = async () => {
    setValue('message', '');

    if (betTemp.current) {
      dispatch(
        onChangeTabData({
          side: betTemp.current,
          message: '',
        }),
      );
    }
  };

  const onSubmit = () => {
    // const onSubmit = (dataForm: BetMessageForm) => {
    const values = getValues();
    submitBetMessage({
      id: values.customer.id,
      date: values.date,
      side: values.bet,
      message: values.message,
      optional_customer_id: customer2Ref.current?.toString() || '',
    });
  };

  const _onClickConfig = () => {
    onClickConfig(idCustomer);
  };

  const onChangeMessage = (_message: string) => {
    if (message !== _message) {
      setIsResetError(true);
    }
  };

  // const onChangeValueBet = (value: BetRadioType) => {
  //   if (bet !== value) {
  //     setValue('bet', value);
  //     if (betTemp.current) {
  //       dispatch(
  //         onChangeTabData({
  //           side: betTemp.current,
  //           bet: value,
  //         }),
  //       );
  //     }
  //   }
  // };

  return (
    <>
      <FormProvider {...methods}>
        <form
          // onSubmit={handleSubmit(onSubmit)}
          style={{
            height: '100%',
          }}
        >
          <Box className={classes.container}>
            <BetMessageBillHeader onClickConfig={_onClickConfig} isDisableConfig={!idCustomer} />
            <Box className={classes.dateItem}>
              <BetMessageBillDateItem control={control} />
            </Box>
            <Box className={classes.customerItem}>
              <BetMessageBillCustomerItem control={control} nameCustomer={nameCustomer} />
            </Box>
            <Box className={classes.customerItem}>
              <BetMessageBillCustomerOtherItem />
            </Box>
            <Box className={classes.betItem}>
              <BetMessageBillBetItem
                // onChangeValue={onChangeValueBet}
                // initValue={bet || 'S'}
                control={control}
              />
            </Box>
            <Box className={classes.searchItem}>
              <BetMessageBillBetItemSearchItem control={control} />
            </Box>
            <Box className={classes.messageItem}>
              <BetMessageBillBetMessageItem
                searchMessage={searchMessage}
                control={control}
                onChangeMessage={onChangeMessage}
                onChangeMessageDebounce={onChangeMessageDebounce}
                rangeErrors={rangeErrors}
                // bet={bet}
              />
            </Box>
            <Box className={classes.actionItem}>
              <BetMessageBillFooter
                isLoading={isLoading}
                onRemove={onRemove}
                onSubmit={onSubmit}
                disable={disable}
              />
            </Box>
          </Box>
          <UserConfigModal ref={modalRef} />
        </form>
        {/* <AlertSnackBar ref={alertRef} duration={2000} /> */}
        <Backdrop ref={backdropRef} />
      </FormProvider>
    </>
  );
}

export default BetMessageBill;
