import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getUserApi,
  logoutApi,
  postEmailAndPassword,
  Role,
  UserAuth,
} from 'src/services/authServices';
import { AppState } from 'src/store';
import { clearSessionStorage, saveSessionStorageAuth } from 'src/utils';
import { StatusResponse } from '.';

type AppSelectorAuth = {
  id?: string;
  expired?: string;
  name?: string;
  role?: Role;
  token?: string;
} & StatusResponse;

export const signInByEmailPassword = createAsyncThunk(
  'auth/signIn',
  async (
    {
      username,
      password,
      isAdmin,
    }: {
      username: string;
      password: string;
      isAdmin?: boolean;
    },
    thunkAPI,
  ) => {
    try {
      const { data } = await postEmailAndPassword(username, password);

      const assetToken = data?.token;
      const userId = data?.id;
      const refreshToken = data?.refresh_token;
      const role = data?.role;

      if (assetToken && userId) {
        // saveSessionStorageAuth(role, assetToken, refreshToken, userId);
        // return data;
        if (isAdmin) {
          if (role === 'admin') {
            saveSessionStorageAuth(role, assetToken, refreshToken, userId);
            return data;
          } else {
            return thunkAPI.rejectWithValue({ message: 'Incorrect email or password' });
          }
        } else {
          // if (role !== 'admin') {
          //   saveSessionStorageAuth(role, assetToken, refreshToken, userId);
          //   return data;
          // } else {
          //   return thunkAPI.rejectWithValue({ message: 'Incorrect email or password' });
          // }
          saveSessionStorageAuth(role, assetToken, refreshToken, userId);
          return data;
        }
      } else {
        return thunkAPI.rejectWithValue({ message: 'asset token or user id not found!' });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.response?.data?.error ?? 'Something error!',
      });
    }
  },
);

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (
    {
      role,
      ...params
    }: {
      role: string;
      assetToken: string;
      refreshToken: string;
      userId: string;
    },
    thunkAPI,
  ) => {
    const { userId } = params;
    try {
      const { data } = await getUserApi(userId);
      // const _role = data?.role;
      if (data?.role) {
        return { users: data, ...params };
      } else {
        clearSessionStorage();
        return thunkAPI.rejectWithValue({ message: 'Incorrect email or password' });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.error ?? error.response?.data?.message ?? 'Something error!',
      });
    }
  },
);

export const logOut = createAsyncThunk('auth/logout', async ({}: any, thunkAPI) => {
  try {
    logoutApi();
    return {};
  } catch (error) {
    return thunkAPI.rejectWithValue({
      message: error.response?.data?.message ?? 'Something error!',
    });
  }
});

const initialState: AppSelectorAuth = {
  isFetching: false,
  isError: false,
  errorMessage: '',
  isSuccess: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signInByEmailPassword.pending, (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
    });
    builder.addCase(signInByEmailPassword.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = true;
      state.token = action.payload.token;
      state.name = action.payload.name;
      state.errorMessage = '';
      state.role = action.payload.role;
      state.expired = action.payload.expired;
      state.id = action.payload.id;
    });
    builder.addCase(signInByEmailPassword.rejected, (state, action: any) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload?.message ?? 'Something Error!';
      state.name = null;
      state.token = null;
    });
    builder.addCase(getUser.pending, (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.token = action.payload.assetToken;
      state.name = action.payload.users.displayname;
      state.errorMessage = '';
      state.role = action.payload.users.role as Role;
      state.expired = action.payload.users.expired;
      state.id = action.payload.users.key.toString();
    });
    builder.addCase(getUser.rejected, (state, action: any) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.errorMessage = action.payload.message;
    });

    builder.addCase(logOut.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(logOut.fulfilled, (state, action) => {
      state.name = null;
      state.token = null;
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(logOut.rejected, (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
    });
  },
});

export const selectAuth = (state: AppState) => state.auth;

export default authSlice.reducer;
