import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BetFormat } from "dsk-message";
import { AppState } from "src/store";

interface BetUserMessage {
    id: string,
    name: string,
    date?: string,
    client?: string,
    betting?: number,
    message: string,
    parseMessage: BetFormat[],
    xac: number[]
}

const initialState: { data: BetUserMessage[] } = {
    data: []
}

const betUserMessageSlice = createSlice({
    name: "betUserMessage",
    initialState,
    reducers: {
        addUserMessage: (state, action: PayloadAction<BetUserMessage>) => {
            state.data = [action.payload]
        }
    }
})

export const {
    addUserMessage
} = betUserMessageSlice.actions

export { BetUserMessage }

export const selectBetUserMessage = (state: AppState) => state.betUserMessage

export default betUserMessageSlice.reducer;