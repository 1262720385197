import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import React, { forwardRef, useState } from 'react';
import Modal, { ModalRef } from 'src/components/Modal';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import { onChangeTabName } from 'src/reducer/tabsSlice';
import { useAppDispatch } from 'src/store';
import EditNameCustomerModal, { EditNameRef } from './EditNameCustomerModal';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    height: '99%',
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    padding: '10px 10px 0 10px',
    overflowY: 'auto',
    ...breakpointDown(theme, {
      md: {
        overflowY: 'auto',
      },
    }),
  },
  layoutItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface ConfigModalProps {
  onClickClose?: () => void;
  children: React.ReactNode;
  title: string;
  id: string;
  onSaveCustomer?: (
    id: string,
    name: string,
    resolve?: () => void,
    finallyFunc?: () => void,
  ) => void;
}

const ConfigModal = forwardRef<ModalRef, ConfigModalProps>((props, ref) => {
  const { onClickClose, children, title, id, onSaveCustomer } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const editNameModalRef = React.useRef<EditNameRef>(null);
  const dispatch = useAppDispatch();

  const onClickCloseEvent = () => {
    onClickClose && onClickClose();
  };

  const onCancelModal = () => {
    editNameModalRef.current.handleClose();
  };

  const onSaveModal = async (id: string, name: string) => {
    console.log('🚀 ~ onSaveModal ~ name:', id, name);
    setIsLoadingEdit(true);
    onSaveCustomer &&
      onSaveCustomer(
        id,
        name,
        () => {
          editNameModalRef.current.handleClose();
          dispatch(
            onChangeTabName({
              idCustomer: id,
              name,
            }),
          );
        },
        () => {
          setIsLoadingEdit(false);
        },
      );
  };

  return (
    <>
      <Modal ref={ref}>
        <Box className={classes.container}>
          {/* Header */}
          <Box className={classes.headerContainer}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Typography>Cấu Hình: {title}</Typography>
              <IconButton
                sx={{
                  marginLeft: '5px',
                }}
                onClick={() => {
                  editNameModalRef.current.handleOpen(title, id);
                }}
              >
                <AppRegistrationIcon sx={{ color: '#3F51B5' }} />
              </IconButton>
            </Box>
            <IconButton onClick={onClickCloseEvent}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          {children}
        </Box>
      </Modal>
      <EditNameCustomerModal
        ref={editNameModalRef}
        onCancel={onCancelModal}
        onSave={onSaveModal}
        isLoading={isLoadingEdit}
      />
    </>
  );
});

export default ConfigModal;
