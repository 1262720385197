import { useNavigate } from "react-router-dom"
import { logOut } from "src/reducer/authSlice"
import { useAppDispatch } from "src/store"
import { clearSessionStorage } from "src/utils"

export const useLogout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onLogout = () => {
    try {
      dispatch(logOut({})).then(res => {
      }).catch(err => console.error(err)).finally(() => {
        clearSessionStorage()
        navigate(0)
      })
    } catch (error) {
      //TODO: Handle Error
    }
  }

  return { onLogout }
}