import React from 'react';
import { FilterReportContext } from 'src/contexts/FilterReportContext';
import { DateMenuRange } from 'src/views/Report/DateHistoryMenu';
import { DateOfWeekType } from 'src/views/Report/DayOfWeekSelect';
import { RegionSide } from 'src/views/Report/RegionSelect';

export const useFilterReport = () => {
  const { state: filterReportState, update: setFilterReportState } =
    React.useContext(FilterReportContext);

  const onChangeSearchState = (value: string) => {
    setFilterReportState((pre) => ({
      ...pre,
      search: value,
    }));
  };

  const onChangeSideState = (value: RegionSide) => {
    setFilterReportState((pre) => ({
      ...pre,
      side: value,
    }));
  };

  const onChangeDate = (value: DateMenuRange, idMenu: string) => {
    setFilterReportState((pre) => ({
      ...pre,
      date: value,
      idMenu: {
        id: idMenu,
      },
    }));
  };

  const onChangeDateOfWeek = (value: DateOfWeekType[]) => {
    setFilterReportState((pre) => ({
      ...pre,
      dateOfWeek: value,
    }));
  };

  return {
    filterReportState,

    onChangeSearchState,
    onChangeSideState,
    onChangeDate,
    onChangeDateOfWeek,
  };
};
