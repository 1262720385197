import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from 'src/styles/colors';
import { betBlockHeaderTheme } from 'src/styles/themes';

const useStyles = makeStyles(() => ({
  container: {
    ...betBlockHeaderTheme(),
    justifyContent: 'space-between',
  },
  txtBet: {
    color: 'white',
    fontSize: '1rem',
  },
}));

interface BetMessageBillHeaderProps {
  onClickConfig: () => void;
  isDisableConfig?: boolean;
}

const BetMessageBillHeader: React.FC<BetMessageBillHeaderProps> = ({
  onClickConfig,
  isDisableConfig = false,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography className={classes.txtBet}>ĐẶT CƯỢC</Typography>
      <Button
        variant="contained"
        startIcon={<MiscellaneousServicesIcon />}
        sx={{
          margin: '5px 0',
          borderRadius: '10px',
          backgroundColor: PRIMARY_COLOR,
          padding: '3px 6px',
        }}
        disabled={isDisableConfig}
        onClick={onClickConfig}
      >
        Cấu Hình
      </Button>
    </Box>
  );
};

export default BetMessageBillHeader;
