import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import HomeIcon from '@mui/icons-material/Home';
import LockResetIcon from '@mui/icons-material/LockReset';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import AddExprireDateDrawer, { AddExprireDateDrawerRef } from 'src/components/AddExprireDateDrawer';
import ConfirmModal, { ConfirmModalRef } from 'src/components/ConfirmModal';
import CustomerAccountDrawer, {
  CustomerAccountDrawerRef,
  UserForm,
} from 'src/components/CustomerAccountDrawer';
import { ModalRef } from 'src/components/Modal';
import SwitchToggle, { ChangeSwitchEvent } from 'src/components/SwitchToggle';
import EnhancedTable, { StyledTableCell } from 'src/components/Table';
import { HeadCell } from 'src/components/Table/Header';
import { DATE_FORMAT_TYPE_II, DEFAULT_RESET_PASSWORD } from 'src/constants';
import { alertInstance } from 'src/contexts/AlertContext';
import { Role, resetPasswordApi } from 'src/services/authServices';
import { deleteUser, getUserList, postLockUser } from 'src/services/userServices';
import { encryptPassword } from 'src/utils/encrypt';
import LoadingModal from 'src/views/Customer/LoadingModal';
import Search from './Search';
import HeaderAdmin from '../Layout/Header';

interface AdminDashBoardProps {}

export type AccountData = {
  id: string;
  username: string;
  displayname: string;
  expired: string;
  role: Role;
  extendExpired?: string;
  active?: boolean;
  action?: React.ReactNode;

  extraData?: {
    password: string;
  };
};

const headCells: HeadCell<AccountData>[] = [
  {
    id: 'displayname',
    numeric: false,
    disablePadding: false,
    label: 'Tên Hiển Thị',
    isSort: true,
    align: 'left',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Tên Tài Khoản',
    isSort: true,
  },
  {
    id: 'expired',
    numeric: false,
    disablePadding: false,
    label: 'Ngày Hết Hạn',
    isSort: true,
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Vai trò',
    isSort: false,
    align: 'center',
  },
  {
    id: 'active',
    numeric: false,
    disablePadding: false,
    label: 'Kích Hoạt',
    isSort: false,
    align: 'center',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Hành Động',
    isSort: false,
    align: 'center',
  },
];

// const getSwitchData = (
//   switchData: ChangeSwitchEvent[],
//   itemSwitch: ChangeSwitchEvent,
// ): ChangeSwitchEvent[] => {
//   let switchDataTemp = _.clone(switchData);
//   const existItem = switchDataTemp.find((item) => item.id === itemSwitch.id);
//   if (existItem) {
//     existItem.isChecked = itemSwitch.isChecked;
//   } else {
//     switchDataTemp = [...switchData, itemSwitch];
//   }
//   return switchDataTemp;
// };

export const funcWithDebounce = _.debounce((func) => func(), 1000);

const AdminDashBoard: React.FC<AdminDashBoardProps> = ({}) => {
  const [userList, setUserList] = React.useState<AccountData[]>([]);
  // const [switchData, setSwitchData] = React.useState<ChangeSwitchEvent[]>([]);
  const addCustomerDrawerRef = React.useRef<CustomerAccountDrawerRef>(null);
  const addExpireDateDrawerRef = React.useRef<AddExprireDateDrawerRef>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const confirmModalRef = React.useRef<ConfirmModalRef>(null);
  const resetPassModalRef = React.useRef<ConfirmModalRef>(null);
  const loadingModalRef = React.useRef<ModalRef>(null);

  const userDataRef = React.useRef<AccountData[]>([]);

  const [valueSelected, setValueSelected] = React.useState<'all' | 'active' | 'inactive'>('all');
  const [search, setSearch] = React.useState('');
  const [isRefresh, setIsRefresh] = React.useState(false);

  const onChangeSearch = React.useCallback((value: string) => {
    setSearch(value);
  }, []);

  React.useEffect(() => {
    const data = userDataRef.current;
    if (!data) return;
    if (!search && valueSelected === 'all') {
      setUserList(data);
      return;
    }

    const filterData = data.filter((item) => {
      if (valueSelected === 'all') {
        return true;
      } else if (valueSelected === 'active') {
        return item.active;
      } else {
        return !item.active;
      }
    });

    const filterDataSearch = filterData.filter((item) => {
      const displayname = item.displayname?.trim().toLowerCase();
      const username = item.username?.trim().toLowerCase();
      const searchFormatted = search?.trim().toLowerCase();

      return displayname.includes(searchFormatted) || username.includes(searchFormatted);
    });

    setUserList(filterDataSearch);
  }, [valueSelected, search, isRefresh]);

  const getUserListApi = async () => {
    try {
      setIsLoading(true);
      const { data } = await getUserList();
      if (data && data.length > 0) {
        const dataTransform: AccountData[] = data.map((item) => ({
          id: item.key.toString(),
          displayname: item.displayname,
          username: item.username,
          expired: moment.unix(+item.expired).format(DATE_FORMAT_TYPE_II),
          role: item.role,
          active: item.locked === 'True' ? false : true,
          extraData: {
            password: item.password,
          },
        }));
        setUserList(dataTransform);
        setValueSelected('all');
        setSearch('');
        userDataRef.current = dataTransform;
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateSuccess = () => {
    getUserListApi();
    addCustomerDrawerRef.current.onClose();
  };

  const onUpdateSuccess = () => {
    getUserListApi();
    addExpireDateDrawerRef.current.onClose();
  };

  const onChangeSwitch = async (itemSwitch: ChangeSwitchEvent) => {
    // const userListTemp = _.clone(userList) as AccountData[]
    // const itemUser = userListTemp.findIndex(item => item.id === itemSwitch.id)

    // if (itemUser !== -1) {
    //   userListTemp[itemUser].active = itemSwitch.isChecked
    //   setUserList(userListTemp)
    // }
    try {
      loadingModalRef.current.handleOpen();
      await postLockUser({ id: itemSwitch.id, isLock: !itemSwitch.isChecked });
      const dataTransform = userDataRef.current.map((item) => {
        if (item.id === itemSwitch.id) {
          return {
            ...item,
            active: itemSwitch.isChecked,
          };
        }
        return item;
      });
      userDataRef.current = dataTransform;

      if (valueSelected !== 'all') {
        setIsRefresh((pre) => !pre);
      }
    } catch (error) {
      alertInstance.handleOpen('error', error?.message ?? 'Something error!');
      getUserListApi();
    } finally {
      loadingModalRef.current.handleClose();
    }

    // setSwitchData((pre) => getSwitchData(pre, itemSwitch));
  };

  const onEdit = (dataUserForm: UserForm) => {
    addCustomerDrawerRef.current.onOpen('edit', dataUserForm);
  };

  const onDelete = async (value: AccountData) => {
    const data = await confirmModalRef.current.handleOpenAsync();
    if (data === 'OK') {
      try {
        await deleteUser(value.id);
        alertInstance.handleOpen('success', 'Bạn đã xóa thành công');
        getUserListApi();
      } catch (error) {
        alertInstance.handleOpen('error', error?.message ?? 'Something wrong!');
      }
    }
  };

  const onResetPassword = async (value: AccountData) => {
    const data = await resetPassModalRef.current.handleOpenAsync();
    if (data === 'OK') {
      try {
        const passCrypt = encryptPassword(DEFAULT_RESET_PASSWORD);
        await resetPasswordApi({
          id: value.id,
          new_password: passCrypt,
        });
        alertInstance.handleOpen('success', 'Bạn đã cài lại mật khẩu thành công');
      } catch (error) {
        alertInstance.handleOpen('error', error?.message ?? 'Something wrong!');
      }
    }
  };

  React.useEffect(() => {
    getUserListApi();
  }, []);

  // React.useEffect(() => {
  //   funcWithDebounce(() => {
  //     if (switchData.length > 0) {
  // Promise.allSettled(
  //   switchData.map((item) => postLockUser({ id: item.id, isLock: !item.isChecked })),
  // ).then((values) => {
  //   const idErrors = values.reduce<string[]>((temp, item, index) => {
  //     if (item.status === 'rejected') {
  //       return [...temp, switchData[index].id];
  //     } else {
  //       return temp;
  //     }
  //   }, []);
  //   if (idErrors.length > 0) {
  //     //TODO: handle one of them fail
  //     // getUserListApi()
  //     // const userListTemp = _.clone(userList) as AccountData[]
  //     // const temp = userListTemp.map(item => {
  //     //   const itemError = idErrors.findIndex(idError => idError === item.id)
  //     //   if (itemError !== -1) {
  //     //     return {
  //     //       ...item,
  //     //       active: !item.active
  //     //     }
  //     //   } else {
  //     //     return item
  //     //   }
  //     // })
  //     // if (temp.length > 0) {
  //     //   setUserList(temp)
  //     // }
  //   }
  // });
  //     }
  //   });
  // }, [switchData]);

  return (
    <>
      <HeaderAdmin />
      <Box
        sx={{
          width: '100%',
          minHeight: '4.6875rem',
          marginBottom: { xs: 0, md: '24px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '0.25rem 1rem',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <nav aria-label="main mailbox folders">
                <List
                  sx={{
                    display: 'flex',
                    color: '#6c757d',
                  }}
                  disablePadding
                >
                  <ListItem disablePadding>
                    <ListItemIcon sx={{ minWidth: 'auto' }}>
                      <HomeIcon
                        sx={{
                          width: '1rem',
                          color: '#6c757d',
                        }}
                      />
                    </ListItemIcon>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText
                      sx={{
                        fontSize: '0.875rem',
                        margin: '0 8px',
                      }}
                    >
                      /
                    </ListItemText>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText
                      sx={{
                        fontSize: '0.875rem',
                      }}
                    >
                      Dashboard
                    </ListItemText>
                  </ListItem>
                </List>
              </nav>
            </Box>
            <Typography variant="h6" color="#344767">
              Dashboard
            </Typography>
          </Box>
          <Box>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                addCustomerDrawerRef.current.onOpen('add');
              }}
            >
              Thêm
            </Button>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        spacing={{ xs: 2, md: 5 }}
        alignItems="center"
        marginBottom={{ xs: '20px', md: '0' }}
      >
        <Grid item xs={12} sm={6}>
          <Search onChange={onChangeSearch} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            sx={{
              minWidth: { xs: '100%', sm: '200px' },
            }}
            value={valueSelected}
            onChange={(e) => setValueSelected(e.target.value as 'all' | 'active' | 'inactive')}
            variant="outlined"
          >
            <MenuItem value="all">Tất cả</MenuItem>
            <MenuItem value="active">Đang hoạt động</MenuItem>
            <MenuItem value="inactive">Đã đóng</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: '100%',
          overflow: 'auto',
          maxHeight: '87%',
          height: '100%',
        }}
      >
        <EnhancedTable<AccountData>
          headCells={headCells}
          initOrderBy={'username'}
          bodyCells={userList}
          isPagination={false}
          isLoading={isLoading}
        >
          {(value) => (
            <>
              <StyledTableCell align="center">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar>{value.displayname?.[0].toUpperCase()}</Avatar>
                  <Typography ml={'10px'}>{value.displayname}</Typography>
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography>{value.username}</Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    <Typography mr={'5px'}>{value.expired}</Typography>
                    <IconButton
                      onClick={() => {
                        const date = moment(value.expired, 'DD/MM/yyyy').toDate();
                        addExpireDateDrawerRef.current.onOpen(date, value.id);
                      }}
                    >
                      <EventRepeatIcon color="primary" />
                    </IconButton>
                  </Stack>
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography
                  sx={{
                    background:
                      value.role !== 'admin'
                        ? 'linear-gradient(195deg, #66BB6A, #43A047)'
                        : 'linear-gradient(195deg, #49a3f1, #1A73E8)',
                    padding: '0.55em 0.9em',
                    borderRadius: '0.375rem',
                    lineHeight: 1,
                    textAlign: 'center',
                    verticalAlign: 'baseline',
                    whiteSpace: 'nowrap',
                    color: 'white',
                    position: 'static',
                    fontSize: '0.8rem',
                    textTransform: 'uppercase',
                  }}
                >
                  {value.role}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <SwitchToggle
                  id={value.id}
                  initChecked={value.active}
                  onChangeSwitch={onChangeSwitch}
                />
              </StyledTableCell>
              <StyledTableCell align="center">
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    const date = moment(value.expired, 'DD/MM/yyyy').toDate();
                    onEdit({
                      id: value.id,
                      displayName: value.displayname,
                      username: value.username,
                      password: value.extraData.password,
                      role: value.role,
                      dateExpire: date,
                      isActive: value.active,
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => onDelete(value)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton aria-label="reset-password" onClick={() => onResetPassword(value)}>
                  <LockResetIcon />
                </IconButton>
              </StyledTableCell>
            </>
          )}
        </EnhancedTable>
      </Box>
      <AddExprireDateDrawer ref={addExpireDateDrawerRef} onUpdateSuccess={onUpdateSuccess} />
      <CustomerAccountDrawer
        ref={addCustomerDrawerRef}
        onCreateSuccess={onCreateSuccess}
        userList={userList}
      />
      <ConfirmModal ref={confirmModalRef} title="Bạn Có Chắc Xóa Tài Khoản Này?" />
      <ConfirmModal ref={resetPassModalRef} title="Bạn Có Chắc Cài Lại Mật Khẩu?" />
      <LoadingModal ref={loadingModalRef} />
    </>
  );
};

export default AdminDashBoard;
