import moment from 'moment';
import { DATE_FORMAT_TYPE_IV, PUBLIC_KEY_ENV } from 'src/constants';
import { betRadio, BetRadioType } from 'src/views/Home/BetMessageBill/BetMessageBillBetItem';
import { removeVietnameseTones } from './removeVietnameseTones';

enum NODE_ENV_TYPES {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

const isDevelopment = (env: NODE_ENV_TYPES): boolean => {
  return env === NODE_ENV_TYPES.DEVELOPMENT;
};

function dec2bin(dec) {
  return (dec >>> 0).toString(2);
}

const permutations = (arr) => {
  if (arr.length <= 2) return arr.length === 2 ? [arr, [arr[1], arr[0]]] : arr;
  return arr.reduce(
    (acc, item, i) =>
      acc.concat(
        permutations([...arr.slice(0, i), ...arr.slice(i + 1)]).map((val) => [item, ...val]),
      ),
    [],
  );
};

const getRsaPublicKey = () => {
  return PUBLIC_KEY_ENV.replaceAll('@', '\n');
};

// 13-12-2022
const formatTimestamp = (date: string): number => {
  const strDate = moment(date, DATE_FORMAT_TYPE_IV).format('YYYY-MM-DDT12:00:00.000') + 'Z';
  return moment(strDate).unix();
};

const getIndexByBet = (bet: BetRadioType): number => {
  return betRadio.findIndex((item) => item.key.toString() === bet.toString());
};

const getArrString = (
  dataProp: string,
  textProp: string,
  from: number,
  to: number,
): [number, number][] => {
  const data = removeVietnameseTones(dataProp).toLowerCase().trim();
  const text = removeVietnameseTones(textProp).toLowerCase().trim();

  let arrResult = [];
  let dataTemp = data;
  let searchStr = dataTemp.indexOf(text, 0);
  while (searchStr !== -1) {
    const start = searchStr;
    const end = searchStr + text.length;
    if (start >= from && end <= to) {
      arrResult.push([start, end]);
    }
    searchStr = dataTemp.indexOf(text, searchStr + 1);
  }
  return arrResult;
};

const getKeyByValue = (object: object, value: any): string => {
  return Object.keys(object).find((key) => object[key].toString() === value.toString());
};

const findSubString = (sub: string, data: string): boolean => {
  const subNotVn = removeVietnameseTones(sub);
  const dataNotVn = removeVietnameseTones(data);
  return dataNotVn.toLowerCase().search(subNotVn.toLowerCase()) !== -1;
};

const roundFloat = (value: number): number => {
  return Math.round(value * 10) / 10;
};

export {
  isDevelopment,
  dec2bin,
  permutations,
  getRsaPublicKey,
  formatTimestamp,
  getIndexByBet,
  getArrString,
  getKeyByValue,
  findSubString,
  roundFloat,
};
export { NODE_ENV_TYPES };
export * from './sessionStorage';
