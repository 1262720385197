import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useChangeTabData } from 'src/hooks/useChangeTabData';
import { onChangeTabData, Summary, TabsItem, updateTabsExist } from 'src/reducer/tabsSlice';
import { findMessageByDate, postMessageApi } from 'src/services/messageServices';
import { BetRadioType } from './BetMessageBillBetItem';
import { formatTimestamp } from 'src/utils';
import { useAppSelector } from 'src/store';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import { alertInstance } from 'src/contexts/AlertContext';
import axios from 'axios';

export const formatStatisticalItem = (summary: string[]): Summary[] => {
  if (summary.length > 0) {
    return (
      summary.flatMap((item) => {
        const arrValue = item.split('@');
        if (arrValue.length > 0) {
          if (arrValue.length === 2) {
            const valueLeft = +arrValue[0];
            const valueRight = +arrValue[1];
            return [
              {
                valueLeft,
                valueRight,
              },
            ];
          }
          return [];
        } else {
          return [];
        }
      }) ?? []
    );
  } else {
    return [];
  }
};

const useBetMessageBill = () => {
  const dispatch = useDispatch();
  // const alertRef = React.useRef<AlertSnackBarRef>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const messageApiRef = React.useRef(false);
  const { agencies } = useAppSelector(selectAgencies);
  const [idCustomer, setIdCustomer] = useState('');
  const [betCustomer, setBetCustomer] = useState<BetRadioType | null>(null);
  const { dateSelected } = useChangeTabData((dataTabSelected: TabsItem) => {
    const {
      data: { idCustomer: _idCustomer, bet },
      name,
    } = dataTabSelected;
    if (_idCustomer !== idCustomer) {
      setIdCustomer(_idCustomer.toString());
    }
    if (bet && bet !== betCustomer) {
      setBetCustomer(bet);
    }
  });

  React.useEffect(() => {
    if (messageApiRef.current) {
      messageApiRef.current = false;
    }
  }, [dateSelected]);

  React.useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const getMessagesByDateApi = async (dateParams: number, idCustomer: string) => {
      try {
        const { data } = await findMessageByDate({
          date: dateParams,
          customerId: idCustomer,
          cancelToken: cancelToken.token,
        });
        if (data) {
          dispatch(updateTabsExist({ data }));
        }
      } catch (error) {
        if (axios.isCancel(error)) {
        }
      }
    };
    // if (idCustomerRef.current && dateSelected && !messageApiRef.current) {
    //     getMessagesByDateApi()
    // }
    if (dateSelected && agencies?.length > 0 && idCustomer) {
      const dateTimestamp = formatTimestamp(dateSelected);
      getMessagesByDateApi(dateTimestamp, idCustomer);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [dateSelected, agencies?.length, idCustomer]);

  const submitBetMessage = async ({
    id,
    date,
    message,
    side,
    optional_customer_id,
  }: {
    id: string;
    date: string;
    side: BetRadioType;
    message: string;
    optional_customer_id?: string;
  }) => {
    try {
      // backdropRef.current.onOpen();
      const dateChecked = formatTimestamp(date);
      setIsLoading(true);
      const { data: dataResponse } = await postMessageApi({
        customer_id: id,
        date_checked: dateChecked,
        side: side,
        message,
        optional_customer_id,
      });
      dispatch(
        onChangeTabData({
          dataMessage: dataResponse,
          side,
          message,
        }),
      );
      alertInstance.handleOpen('success', 'Tính tiền thành công');
    } catch (error) {
      const message = error.message ?? 'Something error!';
      alertInstance.handleOpen('error', message);
    } finally {
      setIsLoading(false);
      // backdropRef.current.onClose();
    }
  };

  return {
    betCustomer,
    submitBetMessage,
    isLoading,
    disable,
    // backdropRef,
    // alertRef,
  };
};

export default useBetMessageBill;
