import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { forwardRef, useRef } from 'react';
import { ModalRef } from 'src/components/Modal';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import { MAIN_COLOR } from 'src/styles/colors';
import { ButtonCustom, ButtonLoadingCustom } from 'src/styles/muiCustoms';
import { buttonConfig } from 'src/styles/themes';
import ConfigModal from './ConfigModal';
import ConfigTable, {
  CentralConfigType,
  NorthConfigType,
  PercentsConfigTable,
  SouthConfigType,
} from './ConfigTable';
import DeliveryCustomer from './DeliveryCustomer';
import DirectionsCustomer from './DirectionsCustomer';
import DividePercentCustomer from './DividePercentCustomer';
import StorkCustomer from './StorkCustomer';
import SyntaxCustomer from './SyntaxCustomer';

import { FieldArray, Formik } from 'formik';
import Backdrop from 'src/components/Backdrop';
import {
  centralsConfigTable,
  northsConfigTable,
  orderChannelData,
  southsConfigTable,
  syntaxesTemp,
} from 'src/constants';
import {
  DefaultConfigType,
  middleDefaultConfig,
  northDefaultConfig,
  southDefaultConfig,
} from 'src/constants/configMultiple';
import { alertInstance } from 'src/contexts/AlertContext';
import { DataTableConfigHookReturn } from 'src/hooks/useDataTableConfig';
import { transferDataTableToAgency, useFormConfigTable } from 'src/hooks/useFormConfigTable';
import { selectConfig, updateConfig } from 'src/reducer/configSlice';
import { Agency, editAgencyApi, updateDetailAgency } from 'src/services/agenciesServices';
import { useAppDispatch, useAppSelector } from 'src/store';
import ConfigBCustomer from './ConfigBCustomer';
import ConfigSyntax3c4cCustomer from './ConfigSyntax3c4cCustomer';
import ConfigEnableDouble from './ConfigEnableDouble';
import ConfigEnableExist from './ConfigEnableExist';
import { getAllAgencies } from 'src/reducer/agenciesSlice';

const countXl = 11;

const getChangeByType = <T extends string | number | symbol>(
  isMultiple: boolean,
  defaultConfig: DefaultConfigType<T>,
  percentsConfigData: PercentsConfigTable,
): PercentsConfigTable =>
  percentsConfigData.map((item) => {
    if (defaultConfig[item.title]) {
      return {
        ...item,
        initValueTop: isMultiple
          ? defaultConfig[item.title].multiple
          : defaultConfig[item.title].notMultiple,
      };
    } else {
      return item;
    }
  });

const isHighlightColor = <T extends string | number | symbol>(
  isMultiple: boolean,
  title: string,
  defaultConfig: DefaultConfigType<T>,
): boolean => {
  return defaultConfig[title] ? (isMultiple ? true : false) : false;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    height: '95%',
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    padding: '20px 10px 0 10px',
  },
  gridContainer: {
    marginTop: '10px',
  },
  layoutItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    flexDirection: 'column',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    ...buttonConfig(theme),
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    ...breakpointDown(theme, {
      xs: {
        paddingRight: '5px',
      },
    }),
  },
  footerContainer: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginTop: '90px',
    ...breakpointDown(theme, {
      sm: {
        marginTop: '10px',
        justifyContent: 'center',
        paddingBottom: '10px',
      },
    }),
  },
  buttonCancel: {
    width: '150px',
    marginRight: '10px !important',
  },
  buttonSaveConfig: {
    backgroundColor: MAIN_COLOR,
    width: '150px',
  },
  buttonDelete: {},
}));

interface UserConfigModalProps {}

export type UserConfigModalRef = {
  handleOpen: (id: string) => void;
  handleClose: (callback?: () => void) => void;
};

const HIGHLIGHT_COLOR_CONFIG = '#f0f0a8';

const UserConfigModal = forwardRef<UserConfigModalRef, UserConfigModalProps>((props, ref) => {
  const [id, setId] = React.useState('');
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectConfig);
  // const regulationConfigModalRef = useRef<ModalRef>();
  const temp = useRef([]);
  let typeSubmit: 'btnCopy' | 'btnSave' = 'btnCopy';
  const [isLoadingConfig, setIsLoadingConfig] = React.useState(false);
  const modalRef = useRef<ModalRef>();
  const { backdropRef, initialValues, setInitialValues } = useFormConfigTable(id);

  const countNorthXl = countXl / northsConfigTable.length;
  const countCentralXl = countXl / centralsConfigTable.length;
  const countSouthXl = countXl / southsConfigTable.length;

  React.useImperativeHandle(
    ref,
    () => ({
      handleOpen,
      handleClose,
    }),
    [],
  );

  const handleOpen = (id: string) => {
    modalRef.current.handleOpen();
    setId(id);
  };

  const handleClose = (callback?: () => void) => {
    setId('');
    modalRef.current.handleClose(callback);
  };

  // const onClickRegulationConfig = () => {
  //   regulationConfigModalRef.current.handleOpen();
  // };

  // const onClickCloseConfigModal = () => {
  //   regulationConfigModalRef.current.handleClose();
  // };

  const handleCopyAll = ({ name, key, ...values }: DataTableConfigHookReturn) => {
    dispatch(updateConfig(values));
    alertInstance.handleOpen('success', 'Sao chép cấu hình thành công');
  };

  const handleSaveConfig = async (values: DataTableConfigHookReturn) => {
    try {
      setIsLoadingConfig(true);
      const dataAgency: Agency = transferDataTableToAgency(values);
      await updateDetailAgency(dataAgency);
      alertInstance.handleOpen('success', 'Bạn đã cập nhật thành công');
      handleClose();
    } catch (error) {
      const mess = error.message ?? 'Something error!';
      alertInstance.handleOpen('error', mess, 2000);
    } finally {
      setIsLoadingConfig(false);
    }
  };

  const onPasteConfig = () => {
    if (data) {
      setInitialValues({ name: initialValues.name, key: initialValues.key, ...data });
    }
  };

  const onClickClose = () => {
    handleClose();
  };

  const onClickCancel = () => {
    handleClose();
  };

  const onSaveCustomer = async (
    id: string,
    name: string,
    resolve?: () => void,
    finallyFunc?: () => void,
  ) => {
    try {
      await editAgencyApi({ id, name });
      await dispatch(getAllAgencies({}));
      setInitialValues({
        ...initialValues,
        name,
      });
      resolve && resolve();
      alertInstance.handleOpen('success', 'Chỉnh sửa thành công');
    } catch (error) {
      const mess = error.message ?? 'Something error';
      alertInstance.handleOpen('error', mess);
    } finally {
      finallyFunc && finallyFunc();
    }
  };

  return (
    <>
      <ConfigModal
        ref={modalRef}
        onClickClose={onClickClose}
        title={initialValues?.name}
        id={id}
        onSaveCustomer={onSaveCustomer}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            switch (typeSubmit) {
              case 'btnCopy':
                handleCopyAll(values);
                break;
              default:
                handleSaveConfig(values);
                break;
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => {
            const isMultiple = values.type === '1';
            return (
              <form onSubmit={handleSubmit}>
                {/* Body */}
                <Grid
                  container
                  gridTemplateColumns="repeat(12, 1fr)"
                  className={classes.gridContainer}
                  rowGap={{ xl: '20px', xs: '10px' }}
                >
                  {/* ROW 1 */}
                  <Grid item xl={2} lg={3} md={6} sm={6} xs={12}>
                    <DeliveryCustomer
                      value={values.deliver}
                      handleChange={(value) => setFieldValue('deliver', value)}
                    />
                  </Grid>
                  <Grid item xl={2} lg={3} sm={6} xs={12}>
                    <SyntaxCustomer
                      value={values.syntax}
                      title="Cú Pháp"
                      data={syntaxesTemp}
                      width={200}
                      handleChange={(value) => {
                        setFieldValue('syntax', value);
                      }}
                    />
                  </Grid>
                  <Grid item xl={2} lg={3} sm={3} xs={12}>
                    <ConfigSyntax3c4cCustomer
                      value={values.isSyntax3C4C}
                      handleChange={(value) => setFieldValue('isSyntax3C4C', value)}
                    />
                  </Grid>
                  <Grid item xl={2} lg={3} sm={3} xs={12}>
                    <ConfigBCustomer
                      value={values.isConfigB}
                      handleChange={(value) => setFieldValue('isConfigB', value)}
                    />
                    {/* <FormControlLabel
											value={direction.value}
											control={<CheckboxCustom checked={checks[direction.value]} />}
											label={direction.label}
											labelPlacement="end"
											onChange={_onChange}
										/> */}
                  </Grid>
                  <Grid item xl={2} lg={3} sm={3} xs={12}>
                    <ConfigEnableDouble
                      value={values.enable_da_cap}
                      handleChange={(value) => setFieldValue('enable_da_cap', value)}
                    />
                  </Grid>
                  <Grid item xl={2} lg={3} sm={3} xs={12}>
                    <ConfigEnableExist
                      value={values.enable_da_trung}
                      handleChange={(value) => setFieldValue('enable_da_trung', value)}
                    />
                  </Grid>
                  {/* <Grid item xl={1.5} lg={1.8} sm={3} xs={6}>
										<Box className={classes.containerButton}>
											<ButtonCustom variant='contained' className={classes.button}
												onClick={onClickRegulationConfig}
											>Chữ Quy Định</ButtonCustom>
										</Box>
									</Grid>
									<Grid item xl={1.5} lg={1.8} sm={3} xs={6}>
										<Box className={classes.containerButton}>
											<ButtonCustom variant='contained' className={classes.button}>Quy Định Số</ButtonCustom>
										</Box>
									</Grid> */}
                  <Grid item xl={2} lg={2.5} md={6} sm={6} xs={6}>
                    <Box className={classes.containerButton}>
                      <ButtonCustom
                        variant="contained"
                        className={classes.button}
                        type="submit"
                        name="btnCopy"
                        onClick={() => {
                          typeSubmit = 'btnCopy';
                        }}
                      >
                        Copy Cấu Hình
                      </ButtonCustom>
                    </Box>
                  </Grid>
                  <Grid item xl={2} lg={2.5} sm={6} xs={6}>
                    <Box className={classes.containerButton}>
                      <ButtonCustom
                        variant="contained"
                        disabled={!data}
                        className={classes.button}
                        onClick={onPasteConfig}
                      >
                        Dán Cấu Hình
                      </ButtonCustom>
                    </Box>
                  </Grid>

                  {/* ROW 2 */}
                  <Grid item xl={3} lg={3} sm={6} xs={12}>
                    <StorkCustomer
                      value={values.type}
                      handleChange={(value) => {
                        setFieldValue('type', value);

                        const isMultiple = value === '1';
                        const northernChangeByType = getChangeByType<NorthConfigType>(
                          isMultiple,
                          northDefaultConfig,
                          values.northern,
                        );
                        const centralChangeByType = getChangeByType<CentralConfigType>(
                          isMultiple,
                          middleDefaultConfig,
                          values.central,
                        );
                        const southChangeByType = getChangeByType<SouthConfigType>(
                          isMultiple,
                          southDefaultConfig,
                          values.south,
                        );
                        setFieldValue('northern', northernChangeByType);
                        setFieldValue('central', centralChangeByType);
                        setFieldValue('south', southChangeByType);
                      }}
                    />
                  </Grid>
                  <Grid item xl={3} lg={3.5} sm={6} xs={12}>
                    <DirectionsCustomer
                      value={values.calKI}
                      handleChange={(value) => setFieldValue('calKI', value)}
                    />
                  </Grid>
                  <Grid item xl={4} lg={1.8} sm={4} xs={12}>
                    <DividePercentCustomer
                      title="Chia%:Nam"
                      value={values.percentSouth}
                      handleChange={(value) => setFieldValue('percentSouth', value)}
                    />
                  </Grid>
                  <Grid item xl={4} lg={1.8} sm={4} xs={12}>
                    <DividePercentCustomer
                      title="Chia%:Trung"
                      value={values.percentCentral}
                      handleChange={(value) => setFieldValue('percentCentral', value)}
                    />
                  </Grid>
                  <Grid item xl={4} lg={1.8} sm={4} xs={12}>
                    <DividePercentCustomer
                      title="Chia%:Bắc"
                      value={values.percentNorth}
                      handleChange={(value) => setFieldValue('percentNorth', value)}
                    />
                  </Grid>

                  <Grid item xl={4} lg={1.8} sm={4} xs={12}>
                    <DividePercentCustomer
                      title="Chia Lời:Nam"
                      value={values.sharePercentSouth}
                      handleChange={(value) => setFieldValue('sharePercentSouth', value)}
                    />
                  </Grid>
                  <Grid item xl={4} lg={1.8} sm={4} xs={12}>
                    <DividePercentCustomer
                      title="Chia Lời:Trung"
                      value={values.sharePercentCentral}
                      handleChange={(value) => setFieldValue('sharePercentCentral', value)}
                    />
                  </Grid>
                  <Grid item xl={4} lg={1.8} sm={4} xs={12}>
                    <DividePercentCustomer
                      title="Chia Lời:Bắc"
                      value={values.sharePercentNorth}
                      handleChange={(value) => setFieldValue('sharePercentNorth', value)}
                    />
                  </Grid>

                  {/* ROW 3 */}
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={6}
                    xs={12}
                    order={{ xl: 3, lg: 3, md: 3, sm: 5, xs: 3 }}
                    sx={{ margin: { sm: '0 auto' } }}
                  >
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
                      <ConfigTable title="Miền Bắc" type="header" />
                    </Grid>
                    <FieldArray name="northern">
                      {() =>
                        values.northern?.map((item, index) => (
                          <Grid
                            item
                            xl={countNorthXl}
                            lg={countNorthXl}
                            md={countNorthXl}
                            sm={12}
                            xs={12}
                          >
                            <ConfigTable
                              typeConfig="northern"
                              index={index}
                              title={item.title}
                              initValueTop={item.initValueTop}
                              initValueBottom={item.initValueBottom}
                              handleChange={handleChange}
                              highlightTopColor={
                                isHighlightColor(isMultiple, item.title, northDefaultConfig)
                                  ? HIGHLIGHT_COLOR_CONFIG
                                  : undefined
                              }
                            />
                          </Grid>
                        ))
                      }
                    </FieldArray>
                  </Grid>

                  {/* Row 4 */}
                  <Grid container item xl={12} lg={12} md={12} sm={12} rowGap={'10px'}>
                    <Grid item xs={12}>
                      <Typography>Chọn thứ tự đài</Typography>
                    </Grid>
                    {orderChannelData.map((item, index) => (
                      <Grid key={item.id} item xl={4} md={4} sm={6} xs={12}>
                        {/* <Grid key={item.id} item xl={item.column ?? 3} md={4} sm={6} xs={12}> */}
                        <SyntaxCustomer
                          title={item.channel}
                          data={item.data}
                          isPermutation={true}
                          value={values.dealerOrder?.[index]}
                          isValueId
                          handleChange={(value) => {
                            const data =
                              temp.current.length === 0
                                ? [...values.dealerOrder]
                                : [...temp.current];
                            data[index] = value;
                            temp.current = data;
                            setFieldValue('dealerOrder', temp.current);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>

                  {/* ROW 5 */}
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={6}
                    xs={12}
                    order={{ xl: 4, lg: 4, md: 4, sm: 3, xs: 4 }}
                    sx={{ padding: { sm: '0 10px', xl: 0, lg: 0, md: 0 } }}
                  >
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
                      <ConfigTable title="Miền Trung" type="header" />
                    </Grid>
                    <FieldArray name="central">
                      {() =>
                        values.central?.map((item, index) => (
                          <Grid
                            item
                            xl={countCentralXl}
                            lg={countCentralXl}
                            md={countCentralXl}
                            sm={12}
                            xs={12}
                          >
                            <ConfigTable
                              typeConfig="central"
                              index={index}
                              title={item.title}
                              initValueTop={item.initValueTop}
                              initValueBottom={item.initValueBottom}
                              handleChange={handleChange}
                              highlightTopColor={
                                isHighlightColor(isMultiple, item.title, middleDefaultConfig)
                                  ? HIGHLIGHT_COLOR_CONFIG
                                  : undefined
                              }
                            />
                          </Grid>
                        ))
                      }
                    </FieldArray>
                  </Grid>

                  {/* ROW 6 */}
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={6}
                    xs={12}
                    order={{ xl: 5, lg: 5, md: 5, sm: 4, xs: 5 }}
                    sx={{ padding: { sm: '0 10px', xl: 0, lg: 0, md: 0 } }}
                  >
                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
                      <ConfigTable title="Miền Nam" type="header" />
                    </Grid>
                    <FieldArray name="south">
                      {() =>
                        values.south?.map((item, index) => (
                          <Grid
                            item
                            xl={countSouthXl}
                            lg={countSouthXl}
                            md={countSouthXl}
                            sm={12}
                            xs={12}
                          >
                            <ConfigTable
                              typeConfig="south"
                              index={index}
                              title={item.title}
                              initValueTop={item.initValueTop}
                              initValueBottom={item.initValueBottom}
                              handleChange={handleChange}
                              highlightTopColor={
                                isHighlightColor(isMultiple, item.title, southDefaultConfig)
                                  ? HIGHLIGHT_COLOR_CONFIG
                                  : undefined
                              }
                            />
                          </Grid>
                        ))
                      }
                    </FieldArray>
                  </Grid>
                </Grid>
                <Box className={classes.footerContainer}>
                  <ButtonCustom
                    variant="contained"
                    className={classes.buttonDelete}
                    myBackgroundColor="#FF5252"
                    sx={{ fontWeight: 100 }}
                    onClick={onClickCancel}
                  >
                    Huỷ Bỏ
                  </ButtonCustom>
                  <ButtonLoadingCustom
                    variant="contained"
                    className={classes.buttonDelete}
                    myBackgroundColor="#9978f3"
                    style={{ fontWeight: 100, marginLeft: '20px' }}
                    type="submit"
                    name="btnSave"
                    onClick={() => {
                      typeSubmit = 'btnSave';
                    }}
                    loading={isLoadingConfig}
                  >
                    Lưu Cấu Hình
                  </ButtonLoadingCustom>
                </Box>
              </form>
            );
          }}
        </Formik>
      </ConfigModal>
      {/* <RegulationConfigModal
        ref={regulationConfigModalRef}
        onClickClose={onClickCloseConfigModal}
      /> */}
      {/* <AlertSnackBar ref={alertRef} /> */}
      <Backdrop ref={backdropRef} />
    </>
  );
});

export default UserConfigModal;
