import { combineReducers } from "redux";
import betUserMessageReducer from "./betUserMessageSlice";
import authReducer from "./authSlice";
import agenciesReducer from "./agenciesSlice";
import tabsReducer from "./tabsSlice";
import configReducer from "./configSlice";
import messagesReducer from "./messagesSlice";

export interface StatusResponse {
    isFetching: boolean,
    isSuccess: boolean,
    isError: boolean,
    errorMessage: string,
}

const rootReducer = combineReducers({
    betUserMessage: betUserMessageReducer,
    auth: authReducer,
    agencies: agenciesReducer,
    tabs: tabsReducer,
    config: configReducer,
    messages: messagesReducer
});

export type RootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
