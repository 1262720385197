import { Button, Checkbox, Radio, Select, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import { MAIN_COLOR } from "./colors";
import { styled } from '@mui/system';
import { LoadingButton } from "@mui/lab";

// const TextFieldCustom = withStyles({
//     root: {
//         '& label.Mui-focused': {
//             color: 'white',
//         },
//         '& .MuiInput-underline:after': {
//             borderBottomColor: MAIN_COLOR,
//         },
//         '& .MuiOutlinedInput-root': {
//             height: '100%',
//             '& fieldset': {
//                 borderColor: MAIN_COLOR,
//             },
//             '&:hover fieldset': {
//                 borderColor: MAIN_COLOR,
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: MAIN_COLOR,
//             },
//         },
//     },
// })(TextField);

const TextFieldCustom = styled(TextField)<{ colorCustom?: string, isError?: boolean }>(({ colorCustom, isError }) => (
    {
        '& label.Mui-focused': {
            color: isError ? "red" : colorCustom ?? MAIN_COLOR,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: isError ? "red" : colorCustom ?? MAIN_COLOR,
        },
        '& .MuiOutlinedInput-root': {
            height: '100%',
            '& fieldset': {
                borderColor: isError ? "red" : colorCustom ?? MAIN_COLOR,
            },
            '&:hover fieldset': {
                borderColor: isError ? "red" : colorCustom ?? MAIN_COLOR,
            },
            '&.Mui-focused fieldset': {
                borderColor: isError ? "red" : colorCustom ?? MAIN_COLOR,
            },
        },
    }
))

const RadioCustom = styled(Radio)({
    color: MAIN_COLOR,
    '&.Mui-checked': {
        color: MAIN_COLOR,
    },
});

const CheckboxCustom = styled(Checkbox)({
    color: MAIN_COLOR,
    '&.Mui-checked': {
        color: MAIN_COLOR,
    },
});

const SelectCustom = styled(Select)({
    border: `1px solid ${MAIN_COLOR}`,
})

const ButtonCustom = styled(Button)(({ myBackgroundColor = MAIN_COLOR }: {
    myBackgroundColor?: string
}) => ({
    backgroundColor: myBackgroundColor,
    opacity: '0.8',
    '&:hover': {
        backgroundColor: myBackgroundColor,
        opacity: '1',
    },
    '&:active': {
        backgroundColor: myBackgroundColor,
        opacity: '1',
    }
}));

const ButtonLoadingCustom = styled(LoadingButton)(({ myBackgroundColor = MAIN_COLOR }: {
    myBackgroundColor?: string
}) => ({
    backgroundColor: myBackgroundColor,
    opacity: '0.8',
    '&:hover': {
        backgroundColor: myBackgroundColor,
        opacity: '1',
    },
    '&:active': {
        backgroundColor: myBackgroundColor,
        opacity: '1',
    }
}));


export {
    TextFieldCustom,
    RadioCustom,
    SelectCustom,
    ButtonCustom,
    CheckboxCustom,
    ButtonLoadingCustom
}