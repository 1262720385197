import { PercentsConfigTable } from 'src/views/Customer/ConfigTable';
import { Syntax } from 'src/views/Customer/SyntaxCustomer';

const northsConfigTable: PercentsConfigTable = [
  // {
  //     title: '2D-Đầu',
  //     initValueTop: 75.5,
  //     initValueBottom: 75
  // },
  // {
  //     title: '2D-Đuôi',
  //     initValueTop: 75.5,
  //     initValueBottom: 75
  // },
  // {
  //     title: '2D-27Lô',
  //     initValueTop: 20.4,
  //     initValueBottom: 75
  // },
  // {
  //     title: '3D-Đầu',
  //     initValueTop: 66,
  //     initValueBottom: 650
  // },
  // {
  //     title: '3D-Đuôi',
  //     initValueTop: 66,
  //     initValueBottom: 650
  // },
  // {
  //     title: '3D-23Lô',
  //     initValueTop: 15.2,
  //     initValueBottom: 650
  // },
  // {
  //     title: '4D-Đuôi',
  //     initValueTop: 66,
  //     initValueBottom: 6000
  // },
  // {
  //     title: '4D-20Lô',
  //     initValueTop: 66,
  //     initValueBottom: 6000
  // },
  // {
  //     title: 'Đá',
  //     initValueTop: 20.4,
  //     initValueBottom: 680
  // }
  {
    title: '2D-Đầu',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '2D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '2D-27Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-Đầu',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-23Lô',
    initValueTop: 15.2,
    initValueBottom: 0,
  },
  {
    title: '4D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '4D-20Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: 'Đá',
    initValueTop: 0,
    initValueBottom: 0,
  },
];

const centralsConfigTable: PercentsConfigTable = [
  // {
  //     title: '2D-Đầu',
  //     initValueTop: 75.5,
  //     initValueBottom: 75
  // },
  // {
  //     title: '2D-Đuôi',
  //     initValueTop: 75.5,
  //     initValueBottom: 75
  // },
  // {
  //     title: '2D-7Lô',
  //     initValueTop: 75.5,
  //     initValueBottom: 75
  // },
  // {
  //     title: '2D-18Lô',
  //     initValueTop: 13.6,
  //     initValueBottom: 75
  // },
  // {
  //     title: '3D-Đầu',
  //     initValueTop: 66,
  //     initValueBottom: 650
  // },
  // {
  //     title: '3D-Đuôi',
  //     initValueTop: 66,
  //     initValueBottom: 650
  // },
  // {
  //     title: '3D-7Lô',
  //     initValueTop: 66,
  //     initValueBottom: 650
  // },
  // {
  //     title: '3D-17Lô',
  //     initValueTop: 11.2,
  //     initValueBottom: 650
  // },
  // {
  //     title: '4D-Đuôi',
  //     initValueTop: 66,
  //     initValueBottom: 6000
  // },
  // {
  //     title: '4D-16Lô',
  //     initValueTop: 66,
  //     initValueBottom: 6000
  // },
  // {
  //     title: 'Đá',
  //     initValueTop: 13.6,
  //     initValueBottom: 750
  // },
  // {
  //     title: 'Đá Xiên',
  //     initValueTop: 13.6,
  //     initValueBottom: 550
  // }
  {
    title: '2D-Đầu',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '2D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '2D-7Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '2D-18Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-Đầu',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-7Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-17Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '4D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '4D-16Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: 'Đá',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: 'Đá Xiên',
    initValueTop: 0,
    initValueBottom: 0,
  },
];

const southsConfigTable: PercentsConfigTable = [
  // {
  //     title: '2D-Đầu',
  //     initValueTop: 75.5,
  //     initValueBottom: 75
  // },
  // {
  //     title: '2D-Đuôi',
  //     initValueTop: 75.5,
  //     initValueBottom: 75
  // },
  // {
  //     title: '2D-7Lô',
  //     initValueTop: 75.5,
  //     initValueBottom: 75
  // },
  // {
  //     title: '2D-18Lô',
  //     initValueTop: 13.6,
  //     initValueBottom: 75
  // },
  // {
  //     title: '3D-Đầu',
  //     initValueTop: 66,
  //     initValueBottom: 650
  // },
  // {
  //     title: '3D-Đuôi',
  //     initValueTop: 66,
  //     initValueBottom: 650
  // },
  // {
  //     title: '3D-7Lô',
  //     initValueTop: 66,
  //     initValueBottom: 650
  // },
  // {
  //     title: '3D-17Lô',
  //     initValueTop: 11.2,
  //     initValueBottom: 650
  // },
  // {
  //     title: '4D-Đuôi',
  //     initValueTop: 66,
  //     initValueBottom: 6000
  // },
  // {
  //     title: '4D-16Lô',
  //     initValueTop: 66,
  //     initValueBottom: 6000
  // },
  // {
  //     title: 'Đá',
  //     initValueTop: 13.6,
  //     initValueBottom: 750
  // },
  // {
  //     title: 'Đá Xiên',
  //     initValueTop: 13.6,
  //     initValueBottom: 550
  // }
  {
    title: '2D-Đầu',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '2D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '2D-7Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '2D-18Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-Đầu',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-7Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '3D-17Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '4D-Đuôi',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: '4D-16Lô',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: 'Đá',
    initValueTop: 0,
    initValueBottom: 0,
  },
  {
    title: 'Đá Xiên',
    initValueTop: 0,
    initValueBottom: 0,
  },
];

const syntaxes: Syntax[] = [
  {
    id: '1',
    value: 'Đài',
  },
  {
    id: '2',
    value: 'Số',
  },
  {
    id: '3',
    value: 'Kiểu',
  },
];

export const syntaxesTemp: Syntax[] = [
  {
    id: '123',
    value: 'Đài - Số - Kiểu',
  },
  {
	id: '231',
	value: 'Số - Kiểu - Đài',
  },
  {
	id: '132',
	value: 'Đài - Kiểu - Số',
  },
  {
	id: '321',
	value: 'Kiểu - Số - Đài',
  },
];

export { northsConfigTable, centralsConfigTable, southsConfigTable, syntaxes };
