import { Box, Button, TextField, Theme, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import React from 'react'
import { BLUE_LIGHT_COLOR } from 'src/styles/colors'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import useLogin from 'src/views/Login/hooks/useLogin';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'src/store';
import { selectAuth } from 'src/reducer/authSlice';
import { REDIRECT_TO } from 'src/constants/searchParams';
import { ADMIN_PAGE_PATH } from 'src/routes/paths';
import TextInput from 'src/views/Login/TextInput';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F8F9FD'
    },
    boxContainer: {
        width: '450px',
        height: '550px',
        backgroundColor: 'white',
        borderRadius: 5,
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0 50px'
    },
    iconContainer: {
        backgroundColor: BLUE_LIGHT_COLOR,
        borderRadius: '50%',
        width: '100px',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px',
        marginBottom: '5px'
    },
    personIcon: {
        color: 'white',
        fontSize: '50px !important'
    },
    signInTxt: {
        fontSize: '2rem !important',
    },
    inpLogin: {
        width: '100%',
        marginTop: '20px !important',
    },
    btnLogin: {
        width: '100%'
    },
    txtError: {
        color: theme.palette.error.main,
    }
}))

function AdminLogin() {
    const theme = useTheme()
    const classes = useStyles(theme)
    const [searchParams] = useSearchParams();
    const { errorMessage, token, name, role } = useAppSelector(selectAuth)
    const { loginWithEmailPassword } = useLogin()
    const [userName, setUserName] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')

    const onClickLogin = () => {
        loginWithEmailPassword(userName, password, true)
    }

    //TODO: flash screen
    if (token) {
        if (role === "admin") {
            const redirectToParams = searchParams.get(REDIRECT_TO)
            return <Navigate to={redirectToParams ? `/${redirectToParams}` : ADMIN_PAGE_PATH} />
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.boxContainer}>
                <Box className={classes.iconContainer}>
                    <PersonOutlineIcon className={classes.personIcon} />
                </Box>
                <Typography className={classes.signInTxt}>Sign In</Typography>
                {(errorMessage) && <Typography className={classes.txtError}>{errorMessage}</Typography>}
                <TextInput
                    label="Tên Đăng Nhập"
                    type={'text'}
                    value={userName}
                    onChange={value => setUserName(value)}
                    className={classes.inpLogin}
                    variant={'default'}
                />
                <TextInput
                    label="Mật Khẩu"
                    type={'mk'}
                    value={password}
                    onChange={value => setPassword(value)}
                    variant={'default'}
                    className={classes.inpLogin}
                />
                <Button className={classes.inpLogin} sx={{ backgroundColor: BLUE_LIGHT_COLOR, padding: '15px 0' }} variant="contained"
                    onClick={onClickLogin}
                >Login</Button>
            </Box>
        </Box>
    )
}

export default AdminLogin