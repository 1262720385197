import { Box, FormControl, Theme, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { alertInstance } from 'src/contexts/AlertContext';
import { useChangeTabData } from 'src/hooks/useChangeTabData';
import { useCustomer } from 'src/hooks/useCustomer';
import { useEffectNavigateHandle } from 'src/hooks/useEffectNavigateHandle';
import { TabsItem, changeTabIdSelected } from 'src/reducer/tabsSlice';
import { updateTabApi } from 'src/services/tabsServices';
import { useAppDispatch } from 'src/store';
import { TextFieldCustom } from 'src/styles/muiCustoms';
import { BetMessageForm } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

interface BetMessageBillCustomerItemProps {
  control?: Control<BetMessageForm, any>;
  nameCustomer: string;
}

export type CustomerOption = { id: string; label: string };

const BetMessageBillCustomerItem: React.FC<BetMessageBillCustomerItemProps> = ({
  control,
  nameCustomer: nameCustomerProp,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const classes = useStyles(theme);
  const { agencies } = useCustomer();

  const [value, setValue] = React.useState('');
  // const [customersData, setCustomersData] = useState<CustomerOption[]>([]);
  // const { state } = useNavigateToHome();
  const { onChangeCustomerData, dateTabData } = useChangeTabData((dataTabSelected: TabsItem) => {});

  const agenciesOptions = React.useMemo<CustomerOption[]>(() => {
    return (
      agencies?.map((item) => ({
        id: item.key.toString(),
        label: item.name,
      })) ?? []
    );
  }, [agencies]);

  // React.useEffect(() => {
  //   setCustomersData(agenciesOptions);
  // }, [agenciesOptions]);

  React.useEffect(() => {
    if (nameCustomerProp !== value) {
      setValue(nameCustomerProp);
    }
  }, [nameCustomerProp]);

  const handleChange = (value: CustomerOption) => {
    if (!dateTabData) return;
    const idTab = dateTabData?.tabs?.find(
      (item) => item.data.idCustomer.toString() === value.id.toString(),
    )?.id;

    if (idTab) {
      dispatch(changeTabIdSelected(idTab));
      const item = document.querySelector(`[data-id="${idTab}"]`);
      if (item) {
        item.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      const customerId = value.id;
      const tabIdSelect = dateTabData.selected;
      const orderTabSelect = dateTabData.posTabs.findIndex((item) => item === tabIdSelect);
      const name = agencies.find((agency) => agency.key.toString() === value.id)?.name;

      updateTabApi({
        customerId,
        tabId: tabIdSelect,
        order: orderTabSelect,
      })
        .then((res) => {
          onChangeCustomerData({
            name,
            data: {
              idCustomer: value.id,
            },
          });
          alertInstance.handleOpen('success', 'You have changed success');
        })
        .catch((err) => {
          alertInstance.handleOpen('error', err?.message ?? 'Something error!');
        });
    }
  };
  useEffectNavigateHandle((state) => {
    handleChange({ id: state.id.toString(), label: state.label });
  });

  return (
    <Box className={classes.container}>
      <Typography sx={{ marginTop: '10px' }}>Khách</Typography>
      <FormControl sx={{ marginTop: '10px', width: '100%' }} size={'small'} variant={'standard'}>
        <Controller
          name="customer"
          control={control}
          rules={{ required: true }}
          render={({ field: { value: _value, onChange: onChangeForm } }) => (
            <Autocomplete
              // id="customerId"
              sx={{ marginLeft: '12px' }}
              inputValue={value ?? ''}
              onInputChange={(event, newInputValue) => {
                setValue(newInputValue);
              }}
              value={value as any}
              onChange={(e, newValue: any) => {
                if (!newValue) return;
                handleChange(newValue);
                onChangeForm(newValue);
              }}
              options={agenciesOptions}
              renderInput={(params) => {
                return (
                  <TextFieldCustom
                    onBlur={() => {
                      setValue(_value.label);
                    }}
                    {...params}
                    variant="standard"
                  />
                );
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default BetMessageBillCustomerItem;
