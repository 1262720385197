import React from 'react';
import './styles.css';
import MenuItems from './MenuItems';
import useIntersectionObserver from './useIntersectionObserver';
import useItemsChanged from './useItemsChanged';
import createApi, { publicApiType } from './createApi';
import ItemsMap from './ItemsMap';
import { observerOptions as defaultObserverOptions } from './settings';
import * as constants from './constants';
import useOnInitCb from './useOnInitCb';
import useOnUpdate from './useOnUpdate';
import { VisibilityContext } from './context';
import type { ItemType, Refs } from './types';

export interface Props {
  /**
    Every child should has unique `itemId` prop
   */
  children: ItemType | ItemType[];
  /**
    Duration of transition
   */
  transitionDuration?: number;
  /**
    Ease function for transition

    Example -  t => t*(2-t)

    Full list at https://gist.github.com/gre/1650294#file-easing-js
   */
  transitionEase?: (t: number) => number;
  /**
    Transition behavior can be 'smooth', 'auto' or custom function

    Example:

    (instructions) => {
      const [{ el, left }] = instructions;
      const styler = Styler(el);

      animate({
        from: el.scrollLeft,
        to: left,
        type: 'spring',
        onUpdate: (left) => styler.set('scrollLeft', left),
      });
    }
   */
  transitionBehavior?: string | Function;
  /**
   Callback that fire once on init
   */
  onInit?: (api: publicApiType) => void;
  /**
   Callback that fire every time when visibility of items change
   */
  onUpdate?: (api: publicApiType) => void;
  /**
   Handler for mouse wheel
   */
  onWheel?: (api: publicApiType, ev: React.WheelEvent) => void;
  /**
    Options for intersection observer
   */
  options?: Partial<typeof defaultObserverOptions>;
  /**
    For add custom className for item
   */
  itemClassName?: string;
  /**
    For add custom className for item separator
   */
  separatorClassName?: string;
  /**
    For add custom className for wrapper
   */
  wrapperClassName?: string;
  /**
    Ref object for access VisibilityContextApi outside of context

    e.g. apiRef.current.scrollToItem(...)
   */
  apiRef?: React.MutableRefObject<publicApiType>;
  RTL?: boolean;
  /**
    Disable scrollIntoView polyfill
   */
  noPolyfill?: boolean;
}

function ScrollMenu({
  children,
  transitionDuration = 500,
  transitionEase,
  transitionBehavior,
  onInit = (): void => void 0,
  onUpdate = (): void => void 0,
  onWheel = (): void => void 0,
  options = defaultObserverOptions,
  itemClassName = '',
  separatorClassName = '',
  wrapperClassName = '',
  apiRef = { current: {} as publicApiType },
  RTL,
  noPolyfill,
}: Props): JSX.Element {
  const scrollContainerRef = React.useRef(null);
  const [menuItemsRefs] = React.useState<Refs>({});

  const observerOptions = React.useMemo(
    () => ({
      ...defaultObserverOptions,
      ...options,
      root: scrollContainerRef.current,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, scrollContainerRef.current],
  );

  const items = React.useRef(new ItemsMap()).current;

  // NOTE: hack for detect when items added/removed dynamicaly
  const itemsChanged = useItemsChanged(children, items);

  const { visibleElementsWithSeparators } = useIntersectionObserver({
    items,
    itemsChanged,
    options: observerOptions,
    refs: menuItemsRefs,
  });
  const mounted = !!visibleElementsWithSeparators.length;

  const api = React.useMemo(
    () =>
      createApi(
        items,
        visibleElementsWithSeparators,
        scrollContainerRef,
        {
          duration: transitionDuration,
          ease: transitionEase,
          behavior: transitionBehavior!,
        },
        RTL,
        noPolyfill,
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, visibleElementsWithSeparators, itemsChanged, RTL, noPolyfill],
  );

  const getContext = React.useCallback(
    () => ({
      ...api,
      initComplete: mounted,
      items,
      visibleElementsWithSeparators,
      scrollContainer: scrollContainerRef,
    }),
    [api, mounted, items, visibleElementsWithSeparators, scrollContainerRef],
  );

  const [context, setContext] = React.useState<publicApiType>(getContext);

  const onInitCbFired = useOnInitCb({
    cb: () => onInit(context),
    condition: mounted,
  });

  useOnUpdate({
    cb: () => onUpdate(context),
    condition: onInitCbFired,
    hash: JSON.stringify(
      visibleElementsWithSeparators
        .concat(String(context?.isFirstItemVisible))
        .concat(String(context?.isLastItemVisible)),
    ),
  });

  React.useEffect(() => setContext(getContext()), [getContext]);

  apiRef.current = context;

  const onWheelHandler = React.useCallback(
    (event: React.WheelEvent) => onWheel(context, event),
    [onWheel, context],
  );

  const wrapperClass: string = React.useMemo(
    () => `${constants.wrapperClassName} ${wrapperClassName}`,
    [wrapperClassName],
  );

  return (
    <div className={wrapperClass} onWheel={onWheelHandler}>
      <VisibilityContext.Provider value={context}>
        <div className={constants.innerWrapperClassName}>
          <MenuItems
            refs={menuItemsRefs}
            itemClassName={itemClassName}
            separatorClassName={separatorClassName}
          >
            {children}
          </MenuItems>
        </div>
      </VisibilityContext.Provider>
    </div>
  );
}

export { constants, ScrollMenu, VisibilityContext };
