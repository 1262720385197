import React from 'react';
import Modal, { ModalRef } from '../Modal';
import { Box, Button, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { MAIN_COLOR } from 'src/styles/colors';
import { breakpointDown } from 'src/hooks/useBreakpoints';

interface ConfirmModalProps {
  title: string;
}

export interface ConfirmModalRef {
  handleOpen: () => void;
  handleClose: () => void;
  onChangeLoading: (isLoading: boolean) => void;
  handleOpenAsync: () => Promise<ModalResponseType>;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    backgroundColor: 'white',
    borderRadius: '20px',
    overflow: 'hidden',
    padding: '20px',
    ...breakpointDown(theme, {
      sm: {
        width: '60%',
      },
      xs: {
        width: '90%',
      },
    }),
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBlock: '50px',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type ModalResponseType = 'OK' | 'CANCEL';

const ConfirmModal = React.forwardRef<ConfirmModalRef, ConfirmModalProps>(({ title }, ref) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const modalRef = React.useRef<ModalRef>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [promiseEvent, setPromiseEvent] = React.useState<{
    resolve: (value: ModalResponseType) => void;
    reject: () => void;
  }>();

  const handleOpen = () => {
    modalRef.current.handleOpen();
  };

  const handleOpenAsync = async () => {
    return new Promise<ModalResponseType>((resolve, reject) => {
      handleOpen();
      setPromiseEvent({
        resolve,
        reject,
      });
    });
  };

  const onClickAgree = () => {
    if (promiseEvent) {
      const { resolve } = promiseEvent;
      resolve('OK');
    }
    handleClose();
  };

  const onClickDegree = () => {
    if (promiseEvent) {
      const { resolve } = promiseEvent;
      resolve('CANCEL');
    }
    handleClose();
  };

  const handleClose = () => modalRef.current.handleClose();
  const onChangeLoading = (isLoadingNew: boolean) => {
    if (isLoading !== isLoadingNew) {
      setIsLoading(isLoadingNew);
    }
  };

  React.useImperativeHandle(
    ref,
    () => ({
      handleOpen,
      handleClose,
      onChangeLoading,
      handleOpenAsync,
    }),
    [],
  );

  return (
    <Modal ref={modalRef}>
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box className={classes.footerContainer}>
          <Button
            variant="contained"
            sx={{
              background: '#bcbdbe',
              padding: '10px 40px',
              marginRight: '10px',
              ':hover': {
                background: '#abacae',
              },
            }}
            onClick={onClickDegree}
          >
            Hủy
          </Button>
          <LoadingButton
            variant="contained"
            sx={{
              background: MAIN_COLOR,
              opacity: '0.8',
              padding: '10px 40px',
              ':hover': {
                background: MAIN_COLOR,
                opacity: '1',
              },
            }}
            onClick={onClickAgree}
            loading={isLoading}
          >
            Xác Nhận
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
});

export default ConfirmModal;
