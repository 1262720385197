import { Theme } from '@emotion/react';
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
// import { useChangeTabData } from 'src/hooks/useChangeTabData';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { RadioCustom } from 'src/styles/muiCustoms';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

export type BetRadioType = 'N' | 'M' | 'S';
type BetRadioTypeVi = 'B' | 'T' | 'N';

export const betRadio: {
  key: BetRadioType;
  value: BetRadioTypeVi;
  name: string;
}[] = [
  {
    key: 'N',
    value: 'B',
    name: 'Bắc',
  },
  {
    key: 'M',
    value: 'T',
    name: 'Trung',
  },
  {
    key: 'S',
    value: 'N',
    name: 'Nam',
  },
];

const betRadioReverse = _.clone(betRadio).reverse();

function BetMessageBillBetItem({
  control,
}: // onChangeValue,
// initValue,
{
  control: any;
  // onChangeValue: (value: BetRadioType) => void;
  // initValue: BetRadioType;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  // const [value, setValue] = useState<BetRadioType>('S');

  // const debouncedChangeValue = useCallback(
  //   _.debounce((value) => {
  //     onChangeValue && onChangeValue(value);
  //   }, 500),
  //   [onChangeValue],
  // );

  // useEffect(() => {
  //   if (initValue !== value) {
  //     setValue(initValue);
  //   }
  // }, [initValue]);

  return (
    <Box className={classes.container}>
      <Typography>Miền</Typography>
      {/* <RadioGroup
        row
        aria-labelledby="bet-group-label"
        name="bet-group"
        value={value}
        onChange={(e) => {
          setValue(e.target.value as BetRadioType);
          // debouncedChangeValue(e.target.value as BetRadioType);
          onChangeValue && onChangeValue(value);
        }}
      >
        {betRadioReverse.map((bet) => (
          <FormControlLabel
            value={bet.key}
            control={<RadioCustom />}
            label={bet.value}
            key={bet.key}
          />
        ))}
      </RadioGroup> */}
      <Controller
        name="bet"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <RadioGroup row aria-labelledby="bet-group-label" name="bet-group" {...field}>
            {betRadioReverse.map((bet) => (
              <FormControlLabel
                value={bet.key}
                control={<RadioCustom />}
                label={bet.value}
                key={bet.key}
              />
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  );
}

export default BetMessageBillBetItem;
