export type MediaQueryType = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const MEDIA_QUERY: Record<MediaQueryType, number> = {
    xs: 300, // phone
    sm: 600, // tablets
    md: 900, // small laptop
    lg: 1200, // desktop
    xl: 1536 // large screens
}

export const PHONE_BREAKPOINT: MediaQueryType = 'xs'
export const TABLET_BREAKPOINT: MediaQueryType = 'sm'
export const SMALL_LAPTOP_BREAKPOINT: MediaQueryType = 'md'
export const DESKTOP_BREAKPOINT: MediaQueryType = 'lg'
export const LARGE_SCREEN_BREAKPOINT: MediaQueryType = 'xl'