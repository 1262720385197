import { Suspense } from 'react';
import { Route, Routes as RouterDom } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import LoadingScreen from 'src/components/LoadingScreen';
import ProtectedRouter from 'src/components/ProtectedRouter';
import routerConfig, { RouterConfig } from './configs';

const renderRoutes = (routerConfig: Array<RouterConfig>): JSX.Element | JSX.Element[] | null => {
  return routerConfig
    ? routerConfig.map((route, i) => {
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            element={
              route.isPrivate ? (
                <ProtectedRouter redirectPath={route.redirectPath}>
                  <Component />
                </ProtectedRouter>
              ) : (
                <Component />
              )
            }
          >
            {route.routes?.length > 0 && renderRoutes(route.routes)}
          </Route>
        );
      })
    : null;
};

function Routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <RouterDom>{renderRoutes(routerConfig)}</RouterDom>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;
