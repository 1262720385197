import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { HeadCell } from 'src/components/Table/Header';
import TableVirtualized from 'src/components/Table/TableVirtualized';
import { useChangeTabData } from 'src/hooks/useChangeTabData';
import { TabsItem } from 'src/reducer/tabsSlice';
import { MessagesData } from 'src/services/messageServices';
import { getIndexByBet } from 'src/utils';

enum WIN_TYPE {
  WIN,
  NOT_WIN,
}

export interface BetTableData {
  id: string;
  message: string;
  channel: string;
  number: string;
  betType: string;
  money: number;
  corpse: number;
  win: number;
  winNumber: string;
  isHighlight?: boolean;
}

// const isWin = (
//   _isWin: WIN_TYPE,
// ): {
//   text: string;
//   color: string;
// } => {
//   return _isWin === WIN_TYPE.WIN
//     ? {
//         text: 'Trúng',
//         color: '#22bb33',
//       }
//     : _isWin === WIN_TYPE.NOT_WIN
//     ? {
//         text: 'Không Trúng',
//         color: 'red',
//       }
//     : {
//         text: 'Chưa có kết quả',
//         color: 'gray',
//       };
// };

const headCells: HeadCell<BetTableData>[] = [
  {
    id: 'message',
    numeric: false,
    disablePadding: true,
    label: 'Tin',
    isSort: true,
    width: 80,
  },
  {
    id: 'channel',
    numeric: false,
    disablePadding: false,
    label: 'Đài',
    isSort: true,
    width: 80,
  },
  {
    id: 'number',
    numeric: false,
    disablePadding: false,
    label: 'Số',
    isSort: true,
    width: 200,
  },
  {
    id: 'betType',
    numeric: false,
    disablePadding: false,
    label: 'Kiểu Đánh',
    isSort: true,
    width: 150,
  },
  {
    id: 'money',
    numeric: true,
    disablePadding: false,
    label: 'Tiền',
    isSort: true,
    width: 80,
  },
  {
    id: 'corpse',
    numeric: false,
    disablePadding: false,
    label: 'Xác',
    isSort: true,
    width: 80,
  },
  {
    id: 'win',
    numeric: false,
    disablePadding: false,
    label: 'Trúng',
    isSort: true,
    width: 80,
  },
  {
    id: 'winNumber',
    numeric: false,
    disablePadding: false,
    label: 'Số Trúng',
    isSort: true,
    width: 200,
  },
];

export const unitPrice: Record<'n' | 't', { title: string; value: number }> = {
  n: {
    title: 'nghìn',
    value: 1000,
  },
  t: {
    title: 'triệu',
    value: 1000000,
  },
};

const checkHighlightRow = (item: MessagesData): boolean => {
  if (item.numbers.length === 0) return false;
  return item.numbers.some((item) => !/^\d+$/.test(item.toString()));
};

const transferParseMessageToBetTable = (data: MessagesData[]): BetTableData[] => {
  return data?.length > 0
    ? data?.map((item, index) => {
        return {
          key: index.toString(),
          message: item.order,
          id: index.toString(),
          channel: item.channels.join('; '),
          number: item.numbers.join('; '),
          betType: item.type,
          money: item.money,
          corpse: item.real_money,
          win: item.win_money,
          winNumber: item.win_numbers.join('; '),
          isHighlight: checkHighlightRow(item),
        };
      })
    : [];
};

export default function BetTable() {
  // const theme = useTheme<Theme>();
  const [dataRow, setDataRow] = React.useState<BetTableData[]>([]);
  useChangeTabData((dataTabSelected: TabsItem) => {
    const {
      data: { dataMessage, bet },
    } = dataTabSelected;
    if (bet && dataMessage?.messages.length > 2) {
      const dataMessageTemp = dataMessage?.messages[getIndexByBet(bet)];
      if (dataMessageTemp?.length > 0) {
        const dataRows = transferParseMessageToBetTable(dataMessageTemp);
        setDataRow(dataRows);
      } else {
        setDataRow([]);
      }
    } else {
      // if (dataRow.length > 0) {
      setDataRow([]);
      // }
    }
  });
  return (
    <TableVirtualized<BetTableData>
      initOrderBy={'message'}
      bodyCells={dataRow}
      headCells={headCells}
    />
  );
}
