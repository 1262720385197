import { lazy } from 'react';
import AdminContainerLayout from 'src/layouts/AdminContainer';
import ContainerLayout from 'src/layouts/Container';
import HomePage from 'src/pages';
import AdminDashBoard from 'src/pages/Admin/Dashboard';
import AdminLoginPage from 'src/pages/Admin/Login';
import LoginPage from 'src/pages/Login';
import PrintResultPage from 'src/pages/PrintResult';
import PrintResultDetailPage from 'src/pages/PrintResultDetail';
import Test from 'src/pages/Test';
import {
  LOGIN_ADMIN_PAGE_PATH,
  CUSTOMER_PAGE_PATH,
  HOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  REPORT_PAGE_PATH,
  ADMIN_PAGE_PATH,
  PRINT_RESULT_PAGE_PATH,
  PRINT_DETAIL_RESULT_PAGE_PATH,
  USER_MANUAL_PAGE_PATH,
} from './paths';

interface RouterConfig {
  path: string;
  component: any;
  guard?: JSX.Element;
  layout?: JSX.Element;
  routes?: Array<Pick<RouterConfig, 'path' | 'component'>>; // use Outlet component,
  isPrivate?: boolean;
  redirectPath?: string;
}

const routerConfig: Array<RouterConfig> = [
  {
    path: LOGIN_PAGE_PATH,
    component: () => <LoginPage />,
  },
  {
    path: HOME_PAGE_PATH,
    component: () => <ContainerLayout />,
    routes: [
      {
        path: HOME_PAGE_PATH,
        component: () => <HomePage />,
      },
      {
        path: ':id',
        component: () => <HomePage />,
      },
      {
        path: CUSTOMER_PAGE_PATH,
        component: lazy(() => import('src/pages/Customer')),
      },
      {
        path: REPORT_PAGE_PATH,
        component: lazy(() => import('src/pages/Report')),
      },
      {
        path: USER_MANUAL_PAGE_PATH,
        component: lazy(() => import('src/pages/UserManual')),
      },
    ],
    isPrivate: true,
    redirectPath: LOGIN_PAGE_PATH,
  },
  {
    path: LOGIN_ADMIN_PAGE_PATH,
    component: () => <AdminLoginPage />,
  },
  {
    path: ADMIN_PAGE_PATH,
    component: () => <AdminContainerLayout />,
    isPrivate: true,
    redirectPath: LOGIN_ADMIN_PAGE_PATH,
    routes: [
      {
        path: ADMIN_PAGE_PATH,
        component: () => <AdminDashBoard />,
      },
    ],
  },
  {
    path: PRINT_RESULT_PAGE_PATH,
    isPrivate: true,
    component: () => <PrintResultPage />,
  },
  {
    path: PRINT_DETAIL_RESULT_PAGE_PATH,
    isPrivate: true,
    component: () => <PrintResultDetailPage />,
  },
  {
    path: '/test',
    component: () => <Test />,
  },
];

export default routerConfig;
export { RouterConfig };
