import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { PRIMARY_COLOR } from 'src/styles/colors';
import NavBarAdmin from '../Navbar';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const HeaderAdmin = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: PRIMARY_COLOR,
        display: { xs: 'flex', md: 'none' },
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          userSelect: 'all',
        }}
      >
        <Toolbar disableGutters sx={{ minHeight: { sm: '50px', xs: '40px' } }}>
          <NavBarAdmin />
          <Box
            sx={{
              textAlign: 'center',
              opacity: 1,
              background: 'transparent',
              color: '#344767',
            }}
          >
            <Link
              to={'#'}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AdminPanelSettingsIcon
                sx={{
                  color: 'white',
                  width: '2rem',
                  height: '2rem',
                }}
              />
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '0.875rem',
                  ml: '5px',
                }}
              >
                Admin Dashboard
              </Typography>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderAdmin;
