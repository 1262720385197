import moment from 'moment';
import React, { useState } from 'react';
import { DATE_FORMAT_TYPE_IV } from 'src/constants';
import { getReportDetail, ReportItem } from 'src/services/reportServices';
import { findSubString, formatTimestamp } from 'src/utils';
import { DateTimestamp } from 'src/utils/moment';
import { ReportData } from 'src/views/Report/ReportTable';
import { useFilterReport } from './useFilterReport';
import { alertInstance } from 'src/contexts/AlertContext';
import { deleteAllMessage } from 'src/services/messageServices';
import { useAppSelector } from 'src/store';
import { selectAuth } from 'src/reducer/authSlice';

const normalizeToReportData = (data: ReportItem[]): ReportData[] => {
  return (
    data.map((item, index) => ({
      stt: index + 1,
      idCustomer: item.cus_id,
      user: item.name,
      messCount: item.num_of_mes,
      type2D: item['2d'],
      type3D4D: item['3d'],
      kick: item.da,
      rootMoney: item.xac,
      realMoney: item.thu,
      winMoney: item.trung,
      moneyResult: item.total,
      messResult: item.trung_detail,
    })) ?? []
  );
};

export const getFromToTimestamp = (date: DateTimestamp) => {
  const fromDate = moment(date.from).format(DATE_FORMAT_TYPE_IV);
  const toDate = date.to ? moment(date.to).format(DATE_FORMAT_TYPE_IV) : fromDate;
  const fromTimestamp = formatTimestamp(fromDate);
  const toTimestamp = formatTimestamp(toDate);
  return {
    from: fromTimestamp,
    to: toTimestamp,
  };
};

export const useReportTable = () => {
  const { filterReportState } = useFilterReport();
  const { date, search, side, dateOfWeek } = filterReportState;
  const [reportData, setReportData] = React.useState<ReportData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useAppSelector(selectAuth);
  const [refresh, setRefresh] = useState(false);

  React.useEffect(() => {
    const getReportDetailServices = async () => {
      const { from, to } = getFromToTimestamp(date);

      try {
        setIsLoading(true);
        const { data } = await getReportDetail({
          from,
          to,
          side,
          dateOfWeek,
        });
        if (data) {
          const reportDataNormalized = normalizeToReportData(data);
          setReportData(reportDataNormalized);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getReportDetailServices();
  }, [date, side, refresh, dateOfWeek]);

  const filterData = React.useMemo(() => {
    if (search) {
      return reportData.filter((item) => findSubString(search, item.user));
    } else {
      return reportData;
    }
  }, [reportData, search]);

  const refreshReportData = () => {
    setRefresh((pre) => !pre);
  };

  const removeAllMessage = async () => {
    const side = filterReportState.side;
    const fromDate = filterReportState.date.from;
    const toDate = filterReportState.date.to ?? filterReportState.date.from;
    const { from, to } = getFromToTimestamp({
      from: fromDate,
      to: toDate,
    });

    try {
      if (id && side && fromDate && toDate) {
        await deleteAllMessage({
          userId: id,
          side,
          fromDate: from,
          toDate: to,
        });
        refreshReportData();
      } else {
        throw new Error('Invalid data');
      }
    } catch (error) {
      alertInstance.handleOpen('error', error?.message ?? 'Something wrong!');
    }
  };

  return {
    reportData: filterData,
    filterReportState,
    date,
    isLoading,
    removeAllMessage,
    refreshReportData,
  };
};
