import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { alertInstance } from 'src/contexts/AlertContext';
import { AccountData } from 'src/pages/Admin/Dashboard';
import { Role } from 'src/services/authServices';
import { poseUpdateUser, postCreateUser } from 'src/services/userServices';
import { encryptPassword } from 'src/utils/encrypt';
interface CustomerAccountDrawerProps {
  onCreateSuccess?: () => void;
  userList: AccountData[];
}

type CustomerAccountBehavior = 'add' | 'edit';

export interface CustomerAccountDrawerRef {
  onOpen: (behavior: CustomerAccountBehavior, params?: UserForm) => void;
  onClose: (callback?: () => void) => void;
}

const roles: {
  id: Role;
  value: string;
}[] = [
  {
    id: 'user',
    value: 'User',
  },
  {
    id: 'admin',
    value: 'Admin',
  },
];

export type UserForm = {
  id?: string;
  displayName: string;
  username: string;
  password: string;
  role: Role;
  dateExpire: Date;
  isActive?: boolean;
  userRef?: AccountData['id'];
};

export const CustomerAccountDrawer = forwardRef<
  CustomerAccountDrawerRef,
  CustomerAccountDrawerProps
>(({ onCreateSuccess, userList }, ref) => {
  const [behavior, setBehavior] = React.useState<CustomerAccountBehavior>('add');
  const isEdit = behavior === 'edit';
  const isShowUserRef = !isEdit;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
  } = useForm<UserForm>({
    defaultValues: {
      role: 'user',
      dateExpire: moment().add(1, 'days').toDate(),
      isActive: true,
    },
  });
  // const alertRef = React.useRef<AlertSnackBarRef>(null)
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOpen = (behavior: CustomerAccountBehavior, params?: UserForm) => {
    setOpen(true);
    setBehavior(behavior);
    if (params && behavior === 'edit') {
      setValue('id', params.id);
      setValue('displayName', params.displayName);
      setValue('username', params.username);
      setValue('password', params.password);
      setValue('role', params.role);
      setValue('dateExpire', params.dateExpire);
      setValue('isActive', params.isActive);
    } else {
      reset();
    }
  };
  const handleClose = (callback?: () => void) => {
    setOpen(false);
    callback && callback();
  };

  const onSubmit = (dataForm: UserForm) => {
    const postCreateUserApi = async () => {
      try {
        setIsLoading(true);
        const encryptPass = encryptPassword(dataForm.password);
        if (encryptPassword) {
          await postCreateUser({
            displayname: dataForm.displayName,
            password: encryptPass as string,
            role: dataForm.role,
            username: dataForm.username,
            expired: moment(dataForm?.dateExpire?.toString()).unix(),
            cloneid: dataForm.userRef,
          });
          reset();
          onCreateSuccess && onCreateSuccess();
        } else {
          throw new Error('Encrypt Password Fail!');
        }
      } catch (error) {
        alertInstance.handleOpen('error', error?.message ?? 'Something wrong!');
      } finally {
        setIsLoading(false);
      }
    };
    const postEditUserApi = async () => {
      try {
        setIsLoading(true);
        await poseUpdateUser(dataForm.id, {
          displayname: dataForm.displayName,
          role: dataForm.role,
          expired: moment(dataForm?.dateExpire?.toString()).unix(),
          locked: dataForm.isActive ? 'False' : 'True',
        });
        onCreateSuccess && onCreateSuccess();
      } catch (error) {
        alertInstance.handleOpen('error', error?.message ?? 'Something wrong!');
      } finally {
        setIsLoading(false);
      }
    };
    if (isEdit) {
      postEditUserApi();
    } else {
      postCreateUserApi();
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      onOpen: handleOpen,
      onClose: handleClose,
    }),
    [],
  );

  return (
    <>
      <Drawer anchor={'right'} open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              width: '360px',
              padding: '20px',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItem: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h6" textTransform={'capitalize'}>
                {isEdit ? 'Sửa' : 'Thêm'} tài khoản
              </Typography>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider
              sx={{
                height: '0.0625rem',
                margin: '1rem 0',
                borderColor: 'rgba(0, 0, 0, 0.08)',
                borderStyle: 'solid',
                opacity: 0.25,
                borderBottom: 'none',
                backgroundImage:
                  'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0))!important',
              }}
            />

            <Grid
              container
              rowSpacing={'15px'}
              sx={{
                width: '100%',
              }}
            >
              <Grid item xs={12}>
                <Controller
                  name="displayName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      label="Tên Hiển Thị"
                      variant="standard"
                      sx={{
                        width: '100%',
                      }}
                      error={errors.displayName?.type === 'required'}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      id="username"
                      label="Tên Tài Khoản"
                      variant="standard"
                      sx={{
                        width: '100%',
                      }}
                      disabled={isEdit}
                      error={errors.username?.type === 'required'}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      id="password"
                      label="Mật Khẩu"
                      variant="standard"
                      sx={{
                        width: '100%',
                      }}
                      disabled={isEdit}
                      error={errors.password?.type === 'required'}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-standard-label">Vai Trò</InputLabel>
                <Controller
                  name="role"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select {...field} label="Vai Trò" sx={{ width: '100%' }} variant="standard">
                      {roles.map((item) => (
                        <MenuItem value={item.id}>{item.value}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>

              {isShowUserRef && (
                <Grid item xs={12}>
                  <InputLabel id="demo-simple-select-standard-label">Tài khoản mẫu</InputLabel>
                  <Controller
                    name="userRef"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Tài khoản mẫu"
                        sx={{ width: '100%' }}
                        variant="standard"
                        placeholder="Không bắt buộc"
                      >
                        {userList.map((item) => (
                          <MenuItem value={item.id}>{item.displayname}</MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns as any}>
                  <Controller
                    name="dateExpire"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <DesktopDatePicker
                        label="Ngày Hết Hạn"
                        inputFormat="dd/MM/yyyy"
                        minDate={!isEdit && moment().add(1, 'days').toDate()}
                        // value={value}
                        // onChange={handleChangeDate}
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            sx={{
                              width: '100%',
                            }}
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-standard-label">Kích Hoạt</InputLabel>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field: { value, ...rest } }) => <Switch checked={value} {...rest} />}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: '100%', position: 'absolute', bottom: 0, padding: '10px' }}>
            <LoadingButton loading={isLoading} fullWidth variant="contained" type="submit">
              Lưu
            </LoadingButton>
          </Box>
        </form>
      </Drawer>
    </>
  );
});
export default CustomerAccountDrawer;
