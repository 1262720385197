import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles, useTheme } from '@mui/styles';
import moment from 'moment';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AlertSnackBarRef } from 'src/components/Alert';
import LoadingScreen from 'src/components/LoadingScreen';
import { DATE_FORMAT_TYPE_III } from 'src/constants';
import { alertInstance } from 'src/contexts/AlertContext';
import { breakpointUp } from 'src/hooks/useBreakpoints';
import { selectAuth } from 'src/reducer/authSlice';
import { useAppSelector } from 'src/store';
import Header from '../Header';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#DDDDDD',
    height: '100%',
    flex: '1 1 auto',
    padding: '2px',
    ...breakpointUp(theme, {
      sm: {
        padding: '14px 2px 2px',
      },
      md: {
        padding: '14px 2px 2px',
        overflow: 'hidden',
      },
    }),
  },
  innerContainer: {
    backgroundColor: 'white',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    ...breakpointUp(theme, {
      lg: {
        height: '100%',
      },
    }),
  },
}));

const MAX_EXPIRED_DATE_NOTIFICATION = 5;

function ContainerLayout() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { expired } = useAppSelector(selectAuth);

  // const alertRef = React.useRef<AlertSnackBarRef>(null)

  React.useEffect(() => {
    if (expired) {
      const expiredDate = moment.unix(+expired).format(DATE_FORMAT_TYPE_III);
      const today = moment().format(DATE_FORMAT_TYPE_III);
      var date1 = moment(today);
      var date2 = moment(expiredDate);
      var diff = date2.diff(date1, 'days');
      if (diff && diff > 0 && diff < MAX_EXPIRED_DATE_NOTIFICATION) {
        alertInstance.handleOpen('warning', `Hạn Sử Dụng Còn Lại: ${diff} ngày`);
      }
    }
  }, [expired]);

  return (
    <>
      <Header />
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
      {/* <AlertSnackBar ref={alertRef} duration={5000} /> */}
    </>
  );
}

export default ContainerLayout;
