import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { useChangeTabData } from 'src/hooks/useChangeTabData';
import { TabsItem } from 'src/reducer/tabsSlice';
import { betBlockHeaderTheme } from 'src/styles/themes';
import { betRadio, BetRadioType } from '../BetMessageBill/BetMessageBillBetItem';

const useStyles = makeStyles(() => ({
  container: {
    ...betBlockHeaderTheme(),
  },
  txtBet: {
    color: 'white',
    fontSize: '1rem',
  },
  headerContainer: {
    flex: 1,
  },
  valueLeftContainer: {
    flex: 2.5,
    display: 'flex',
    justifyContent: 'center',
  },
  valueRightContainer: {
    flex: 1.75,
    display: 'flex',
    justifyContent: 'center',
  },
}));

function BetMessageBillHeader() {
  const classes = useStyles();
  const [side, setSide] = React.useState<BetRadioType>('S');
  useChangeTabData((dataTabSelected: TabsItem) => {
    setSide(dataTabSelected.data.bet);
  });

  const sideName = useMemo(() => {
    return betRadio.find((item) => item.key.toString() === side.toString())?.name ?? '';
  }, [side]);

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <Typography className={classes.txtBet}>{sideName}</Typography>
      </Box>
      <Box className={classes.valueLeftContainer}>
        <Typography className={classes.txtBet}>Xác</Typography>
      </Box>
      <Box className={classes.valueRightContainer}>
        <Typography className={classes.txtBet}>Trúng</Typography>
      </Box>
    </Box>
  );
}

export default BetMessageBillHeader;
