import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';
import PrintResultDetailModal, {
  PrintResultDetailModalRef,
} from 'src/views/Home/BetStatistical/PrintResultDetailModal';
import { Dai, DaiResult, HeaderDaiResult } from './types';

const Table = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  'th, td': {
    border: '1px solid #dddddd',
    width: '300px',
    fontSize: '16px',
    padding: '10px',
  },
  th: {
    textAlign: 'center',
    padding: '5px 0',
  },
  td: {
    textAlign: 'right',
    paddingTop: '10px',
    minWidth: '80px',
  },
  'td:first-child': {
    textAlign: 'left',
  },
  '.daiColumn': {
    fontWeight: 'bold',
  },
});

export const getTotalByColum = (data, header: Exclude<HeaderDaiResult, 'tin'>): number => {
  return data.reduce((pre, item) => {
    return pre + item[header];
  }, 0);
};

const dais: Dai[] = ['B', 'T', 'N'];

const headerResult: { key: HeaderDaiResult; value: string }[] = [
  { key: 'tin', value: 'Tin' },
  { key: 'tong2', value: 'Tổng 2' },
  { key: 'tong3', value: 'Tổng 3' },
  { key: 'trung2', value: 'Trúng 2' },
  { key: 'trung3', value: 'Trúng 3' },
  { key: 'trung4', value: 'Trúng 4' },
  { key: 'trungDa1D', value: 'Trúng đá 1d' },
  { key: 'trungX', value: 'Trúng X' },
];

export type PrintResultData = Record<Dai, DaiResult[]>;

const ComponentToPrint = React.forwardRef(
  (
    {
      printResultType = {
        B: [],
        N: [],
        T: [],
      },
      idCustomer,
      date,
    }: {
      printResultType: PrintResultData;
      idCustomer: string;
      date: string;
    },
    ref,
  ) => {
    const printResultDetailRef = React.useRef<PrintResultDetailModalRef>();
    return (
      <>
        <Box ref={ref}>
          <Table>
            <thead>
              <tr>
                {headerResult.map((item) => (
                  <th>{item.value}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dais.map((dai) => {
                return (
                  <>
                    <tr className="daiColumn">
                      <td>{dai}</td>
                      <td>{getTotalByColum(printResultType[dai], 'tong2')}</td>
                      <td>{getTotalByColum(printResultType[dai], 'tong3')}</td>
                      <td>{getTotalByColum(printResultType[dai], 'trung2')}</td>
                      <td>{getTotalByColum(printResultType[dai], 'trung3')}</td>
                      <td>{getTotalByColum(printResultType[dai], 'trung4')}</td>
                      <td>{getTotalByColum(printResultType[dai], 'trungDa1D')}</td>
                      <td>{getTotalByColum(printResultType[dai], 'trungX')}</td>
                    </tr>
                    {printResultType[dai].map((result, index) => (
                      <tr>
                        <td>
                          <Box
                            onClick={() => {
                              printResultDetailRef.current.handleOpen({
                                date,
                                idCustomer,
                                side: dai,
                                tin: result.tin,
                              });
                            }}
                            sx={{
                              color: 'blue',
                              cursor: 'pointer',
                            }}
                          >
                            {result.tin}
                          </Box>
                        </td>
                        <td>{result.tong2}</td>
                        <td>{result.tong3}</td>
                        <td>{result.trung2}</td>
                        <td>{result.trung3}</td>
                        <td>{result.trung4}</td>
                        <td>{result.trungDa1D}</td>
                        <td>{result.trungX}</td>
                      </tr>
                    ))}
                  </>
                );
              })}
            </tbody>
          </Table>
        </Box>
        <PrintResultDetailModal ref={printResultDetailRef} />
      </>
    );
  },
);

export default ComponentToPrint;
