import { Theme } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { SxProps } from '@mui/system';
import React from 'react';
import { MAIN_COLOR } from 'src/styles/colors';
import TextInput from './TextInput';

const containerItem = {
  border: `1px solid ${MAIN_COLOR}`,
  padding: '2px',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '35px',
};

const useStyles = makeStyles((theme: Theme) => ({
  valueContainer: {
    ...containerItem,
    borderRadius: '5px',
    paddingRight: '5px',
    justifyContent: 'flex-end',
  },
  valueLeftContainer: {
    ...containerItem,
    borderRadius: '5px 0 0 5px',
    overflow: 'hidden',
    padding: 0,
  },
  valueRightContainer: {
    ...containerItem,
    borderRadius: '0 5px 5px 0',
    borderLeft: '0',
    overflow: 'hidden',
    padding: 0,
  },
  txtValue: {
    fontWeight: 'bold !important',
    fontSize: '19px !important',
  },
}));

interface BetStatisticalItemProps {
  title: string;
  valueLeft?: string;
  valueRight?: string;
  column?: '1' | '2';
  isHighLight?: 0 | 1 | 2;
  isHighLightRight?: boolean;
  colorHighLightRight?: string;
  isBoldTitle?: boolean;

  titleStyle?: SxProps<Theme>;
  inputLeftStyle?: SxProps<Theme>;
  inputRightStyle?: SxProps<Theme>;
}

const BetStatisticalItem: React.FC<BetStatisticalItemProps> = ({
  title,
  valueLeft,
  valueRight,
  column = '2',
  isHighLight = 0,
  isHighLightRight = false,
  colorHighLightRight,
  isBoldTitle = false,

  titleStyle,
  inputLeftStyle,
  inputRightStyle,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const rightInputRef = React.useRef<HTMLDivElement>();

  function onClickInputLeft(): void {
    rightInputRef.current.focus();
  }

  return (
    <>
      <Grid item xs={2.25}>
        <Typography
          sx={{
            color: isHighLight === 1 ? 'green' : isHighLight === 2 ? 'red' : 'inherit',
            fontWeight: isBoldTitle ? 'bold' : 'inherit',
            ...titleStyle,
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={5.75}>
        <Box
          className={classes.valueLeftContainer}
          sx={{
            borderRight: column === '2' ? null : 0,
          }}
        >
          {/* <Typography
            className={classes.txtValue}
            sx={{
              color: column === '2' ? null : 'white',
              textIndent: valueLeft !== undefined ? 0 : -9999999,
            }}
          >
            {valueLeft}
          </Typography> */}
          <TextInput
            value={valueLeft}
            isHide={column !== '2'}
            inputStyle={inputLeftStyle}
            onClick={onClickInputLeft}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          className={classes.valueRightContainer}
          sx={{
            background: isHighLightRight ? 'rgba(153, 120, 243, 0.4)' : 'white',
            color: colorHighLightRight ?? 'black',
          }}
        >
          {/* <Typography
            className={classes.txtValue}
            sx={{
              textIndent: valueRight !== undefined ? 0 : -9999999,
            }}
          >
            {valueRight}
          </Typography> */}
          <TextInput value={valueRight} inputStyle={inputRightStyle} ref={rightInputRef} />
        </Box>
      </Grid>
    </>
  );
};

export default BetStatisticalItem;
