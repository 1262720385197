import React from 'react';
import { ErrorBoundary as ErrorBoundaryLib } from 'react-error-boundary';

import ErrorFallback from './ErrorFallback';

interface Props {
  children: React.ReactNode;
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here
  };

  return (
    <ErrorBoundaryLib
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={myErrorHandler}
    >
      {children}
    </ErrorBoundaryLib>
  );
};

export default ErrorBoundary;
