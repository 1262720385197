import React from 'react';
import { BackdropRef } from 'src/components/Backdrop';
import { centralsConfigTable, northsConfigTable, southsConfigTable } from 'src/constants';
import { Agency, getDetailAgency } from 'src/services/agenciesServices';
import { getKeyByValue } from 'src/utils';
import { titleCentralType, titleNorthType, titleSouthType } from 'src/views/Customer/ConfigTable';
import {
  DataTableConfigHookReturn,
  mapCalKiWithResponseApi,
  mapPercentWithResponseApi,
  restoreCalKi,
  restorePercent,
} from './useDataTableConfig';

const initConfigValue = {
  northern: northsConfigTable,
  central: centralsConfigTable,
  south: southsConfigTable,
};

const syntaxSymbol = {
  D: 1,
  S: 2,
  K: 3,
};

/**
 * get id from data
 * @param data 'DSK' | 'DKS' | ...
 * @returns '123' | '132' | ...
 */
const normalizationSyntax = (data: string): string => {
  return data.length > 0
    ? data.split('').reduce((temp, item) => (temp += syntaxSymbol[item]), '')
    : '';
};

const denormalizationSyntax = (data: string): string => {
  if (data.length > 0) {
    const arrChar = data.split('');
    return arrChar.reduce((temp, item) => (temp += getKeyByValue(syntaxSymbol, item)), '');
  } else {
    return '';
  }
};

const transferDataTableConfig = (data: Agency): DataTableConfigHookReturn => {
  return {
    name: data.name,
    deliver: data.delivery === 1 ? 'deliver' : 'take',
    syntax: normalizationSyntax(data.syntax),
    type: data.brokers_multiplied === 1 ? '1' : '0',
    calKI: mapCalKiWithResponseApi(data.half_ki),
    percentNorth: data.divide?.length > 0 ? data.divide[0] : 0,
    percentCentral: data.divide?.length > 1 ? data.divide[1] : 0,
    percentSouth: data.divide?.length > 2 ? data.divide[2] : 0,

    sharePercentNorth: data.win_divide?.length > 0 ? data.win_divide[0] : 0,
    sharePercentCentral: data.win_divide?.length > 1 ? data.win_divide[1] : 0,
    sharePercentSouth: data.win_divide?.length > 2 ? data.win_divide[2] : 0,

    northern: mapPercentWithResponseApi(data.north, titleNorthType),
    central: mapPercentWithResponseApi(data.mid, titleCentralType),
    south: mapPercentWithResponseApi(data.south, titleSouthType),
    dealerOrder: data.south_channels.map((item) => item.toString()) ?? [],
    isConfigB: data.config_b_as_duoi,
    createAt: data.create_at,
    key: data.key.toString(),
    mid_channels: data.mid_channels,
    user_key: data.user_key,
    warning: data.warning,
    isSyntax3C4C: data.enable_duoi_3c_4c,
    enable_da_cap: data.enable_da_cap,
    enable_da_trung: data.enable_da_trung,
  };
};

export const transferDataTableToAgency = (data: DataTableConfigHookReturn): Agency => {
  return {
    brokers_multiplied: +data.type,
    config_b_as_duoi: data.isConfigB,
    create_at: data.createAt,
    delivery: data.deliver === 'deliver' ? 1 : 0,
    divide: [data.percentNorth, data.percentCentral, data.percentSouth],
    win_divide: [data.sharePercentNorth, data.sharePercentCentral, data.sharePercentSouth],
    half_ki: restoreCalKi(data.calKI),
    key: data.key.toString(),
    mid: restorePercent(data.central),
    mid_channels: data.mid_channels,
    name: data.name,
    north: restorePercent(data.northern),
    south: restorePercent(data.south),
    south_channels: data.dealerOrder.map((item) => +item),
    syntax: denormalizationSyntax(data.syntax),
    user_key: data.user_key,
    warning: data.warning,
    enable_duoi_3c_4c: data.isSyntax3C4C,
    enable_da_cap: data.enable_da_cap,
    enable_da_trung: data.enable_da_trung,
  };
};

export const useFormConfigTable = (id: string) => {
  const backdropRef = React.useRef<BackdropRef>(null);
  const [initialValues, setInitialValues] =
    React.useState<DataTableConfigHookReturn>(initConfigValue);

  // const data = useDataTableConfig(id)
  React.useEffect(() => {
    const getDetailAgencyApi = async () => {
      if (id) {
        try {
          backdropRef.current.onOpen();
          const { data } = await getDetailAgency({ id });
          const valueTemp = transferDataTableConfig(data);
          setInitialValues(valueTemp);
        } catch (error) {
        } finally {
          backdropRef.current.onClose();
        }
      }
    };
    getDetailAgencyApi();
  }, [id]);

  return {
    backdropRef,
    initialValues,
    setInitialValues,
  };
};
