import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles, useTheme } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { Order } from './index';

export interface HeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
  isSort: boolean;
  width?: number;
  align?: TableCellProps['align'];
  isHiddenOnMobile?: boolean;
  isSum?: boolean;
  sumLabel?: string;
  isWinStyle?: boolean;
}

interface EnhancedTableHeaderProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: keyof T;
  headCells: HeadCell<T>[];
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: '800 !important',
    backgroundColor: '#F2F2F2 !important',
    fontFamily: 'Roboto sans-serif !important',
    maxWidth: '300px',
    wordBreak: 'break-word',
    verticalAlign: 'middle',
    outline: '0',
    padding: '0 !important',
  },
  hidden: {
    display: 'none',
  },
}));

function EnhancedTableHead<T>(props: EnhancedTableHeaderProps<T>) {
  const theme = useTheme() as Theme;
  const classes = useStyles(theme);
  const { getBreakpointDown } = useBreakpoints();
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ height: '50px !important' }}>
      <TableRow>
        {headCells.map((headCell) =>
          headCell.isSort ? (
            <TableCell
              key={headCell.id as string}
              align={headCell.align ?? 'center'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.header}
              sx={{
                ...getBreakpointDown({
                  xs: {
                    display: headCell.isHiddenOnMobile ? 'none' : null,
                  },
                }),
                height: '50px !important',
                minWidth: '100px',
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={classes.header}
                sx={{
                  userSelect: 'auto',
                }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id as string}
              align={headCell.align ?? 'center'}
              className={classes.header}
              sx={{
                ...getBreakpointDown({
                  xs: {
                    display: headCell.isHiddenOnMobile ? 'none' : null,
                  },
                }),
                height: '50px !important',
                minWidth: '100px',
              }}
            >
              {headCell.label}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
