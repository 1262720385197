const LINE_BREAK = '<br >';
const SPACE_TAB = '&ensp;';

export const CONFIG_SYNTAX_3C_4C_TOOLTIP = 'Cú pháp ĐUÔI 3c, 4c';
export const CONFIG_B_TOOLTIP = 'Cấu hình cú pháp "ab" = "đầu đui"';
export const CONFIG_ENABLE_DOUBLE_TOOLTIP =
  'Tính năng nhận dạng đá cặp khi số lượng số đá là chẵn:' +
  LINE_BREAK +
  ' Ví dụ: dc 32 41 41 72 da 1n' +
  LINE_BREAK +
  SPACE_TAB +
  'Khi bật: 32 41 đá thẳng 1ng; 41 72 đá thẳng 1ng' +
  LINE_BREAK +
  SPACE_TAB +
  ' Khi tắt: 32 41 41 72 đá vòng 1ng';
export const CONFIG_ENABLE_EXIST_TOOLTIP =
  'Tính năng nhận dạng đá trùng khi số đá có các cặp số giống nhau:' +
  LINE_BREAK +
  ' Ví dụ: dc 11 22 11 da 1ng' +
  LINE_BREAK +
  SPACE_TAB +
  'Khi bật: 11 22 11 đá vòng 1ng' +
  LINE_BREAK +
  SPACE_TAB +
  'Khi tắt: 11 22 đá thẳng 1 ng';
