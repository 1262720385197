import { LoadingButton } from '@mui/lab';
import { Box, Button, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useState } from 'react';
import { buttonPrimary } from 'src/styles/themes';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btnFooter: {
    backgroundColor: 'red',
  },
}));

interface BetMessageBillFooterProps {
  onRemove: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
  disable?: boolean;
}

const BetMessageBillFooter: React.FC<BetMessageBillFooterProps> = ({
  onRemove,
  isLoading = false,
  disable = false,
  onSubmit,
}) => {
  const theme = useTheme() as Theme;
  const classes = useStyles(theme);
  const [isLoadingDebounce, setIsLoadingDebounce] = useState(false);
  return (
    <Box className={classes.container}>
      <Button
        sx={{ ...buttonPrimary(theme), backgroundColor: 'gray', marginRight: '10px' }}
        variant="contained"
        onClick={() => onRemove()}
        // disabled={disable}
      >
        Xóa
      </Button>
      <LoadingButton
        variant="contained"
        loading={isLoadingDebounce || isLoading}
        sx={buttonPrimary(theme)}
        // type={'submit'}
        onClick={() => {
          setIsLoadingDebounce(true);
          setTimeout(() => {
            onSubmit();
            setIsLoadingDebounce(false);
          }, 500);
        }}
        // disabled={disable}
      >
        Tính Tiền
      </LoadingButton>
    </Box>
  );
};

export default BetMessageBillFooter;
