import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DATE_FORMAT_TYPE_IV } from 'src/constants';
import { FRONT_SIZE_NORMAL } from 'src/styles/fonts';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { BetMessageForm } from '.';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  datePicker: {
    width: '100%',
    padding: '7px 10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginRight: '-10px',
    fontSize: FRONT_SIZE_NORMAL,
    cursor: 'pointer',
    userSelect: 'none',
  },
  inputDate: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginLeft: '20px',
  },
}));

function BetMessageBillDateItem({ control }: { control?: Control<BetMessageForm, any> }) {
  const classes = useStyles();
  const datePickerRef = React.useRef();

  return (
    <Box className={classes.container}>
      <Typography>Ngày</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns as any}>
        <Controller
          name="date"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, ...rest } }) => {
            return (
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                label="Ngày Đặt Cược"
                value={moment(value, DATE_FORMAT_TYPE_IV).toDate()}
                onChange={(evt) => {
                  const date = moment(evt).format(DATE_FORMAT_TYPE_IV);
                  onChange(date);
                }}
                ref={datePickerRef}
                maxDate={Date.now()}
                {...rest}
                renderInput={({ inputRef, inputProps, InputProps, ref }) => (
                  <Box className={classes.inputDate}>
                    <input
                      className={classes.datePicker}
                      ref={inputRef}
                      {...inputProps}
                      readOnly
                      onClick={() => {
                        (InputProps?.endAdornment as any)?.props?.children?.props?.onClick();
                      }}
                    />
                    {InputProps?.endAdornment}
                  </Box>
                )}
              />
            );
          }}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default BetMessageBillDateItem;
