import React from 'react';
import { alertInstance } from 'src/contexts/AlertContext';
import { EditPasswordRef } from 'src/views/Customer/EditPasswordModal';
import { useLogout } from './useLogout';

export const useChangePassword = () => {
  const { onLogout } = useLogout();
  const editPasswordRef = React.useRef<EditPasswordRef>(null);

  const onChangePassword = () => {
    editPasswordRef.current.handleOpen();
  };

  const onCancelModal = () => {
    editPasswordRef.current.handleClose();
  };

  const onSuccessEditPassword = () => {
    alertInstance.handleOpen('success', 'You have changed your password');
    editPasswordRef.current.handleClose();
    onLogout();
  };

  const onFailEditPassword = (message: string) => {
    alertInstance.handleOpen('error', message ?? 'Something error!');
  };

  return {
    editPasswordRef,

    onLogout,
    onChangePassword,
    onCancelModal,
    onFailEditPassword,
    onSuccessEditPassword,
  };
};
