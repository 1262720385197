import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_PAGE_PATH } from 'src/routes/paths';
import { BetRadioType } from 'src/views/Home/BetMessageBill/BetMessageBillBetItem';

export type NavigateToHomeState = {
  id: number;
  label: string;
  date: number;
  side: BetRadioType;
};

export const useNavigateToHome = () => {
  const router = useNavigate();
  const params = useLocation();

  const navigateWithState = (state: NavigateToHomeState) => {
    router(HOME_PAGE_PATH, {
      state,
    });
  };

  return {
    navigateWithState,
    state: params?.state as NavigateToHomeState | undefined,
  };
};
