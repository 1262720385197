import {
  CentralConfigType,
  NorthConfigType,
  SouthConfigType,
} from 'src/views/Customer/ConfigTable';

type MultipleValues = {
  multiple: number;
  notMultiple: number;
};

export type DefaultConfigType<T extends string | number | symbol> = Partial<
  Record<T, MultipleValues>
>;

export const northDefaultConfig: DefaultConfigType<NorthConfigType> = {
  '2D-27Lô': {
    multiple: 20.4,
    notMultiple: 75.5,
  },
  '3D-23Lô': {
    multiple: 15.2,
    notMultiple: 66,
  },
  Đá: {
    multiple: 20.4,
    notMultiple: 75.5,
  },
};

export const middleDefaultConfig: DefaultConfigType<CentralConfigType> = {
  '2D-18Lô': {
    multiple: 13.6,
    notMultiple: 75.5,
  },
  '3D-17Lô': {
    multiple: 11.2,
    notMultiple: 66,
  },
  Đá: {
    multiple: 13.6,
    notMultiple: 75.5,
  },
  'Đá Xiên': {
    multiple: 13.6,
    notMultiple: 75.5,
  },
};

export const southDefaultConfig: DefaultConfigType<SouthConfigType> = {
  '2D-18Lô': {
    multiple: 13.6,
    notMultiple: 75.5,
  },
  '3D-17Lô': {
    multiple: 11.2,
    notMultiple: 66,
  },
  Đá: {
    multiple: 13.6,
    notMultiple: 75.5,
  },
  'Đá Xiên': {
    multiple: 13.6,
    notMultiple: 75.5,
  },
};
