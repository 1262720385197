import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { breakpointUp } from 'src/hooks/useBreakpoints';
import {
  CUSTOMER_PAGE_PATH,
  HOME_PAGE_PATH,
  REPORT_PAGE_PATH,
  USER_MANUAL_PAGE_PATH,
} from 'src/routes/paths';

const pages = [
  { title: 'Trang Chủ', href: HOME_PAGE_PATH, value: 0 },
  { title: 'Khách Hàng', href: CUSTOMER_PAGE_PATH, value: 1 },
  { title: 'Báo Cáo', href: REPORT_PAGE_PATH, value: 2 },
  { title: 'Hướng Dẫn Sử Dụng', href: USER_MANUAL_PAGE_PATH, value: 3 },
];

const NavBarTabs = styled(Tabs)({
  '& .MuiTabs-scroller': {
    position: 'absolute',
    height: '97px',
  },

  '& .MuiTabs-indicator': {
    clipPath: 'polygon(51% 77%, 30% 68%, 70% 68%)',
    height: '150px',
    backgroundColor: '#4C5E97',
  },
});

const NavBarTab = styled((props: any) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  ...breakpointUp(theme, {
    sm: {
      minWidth: 0,
    },
  }),
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#DDDDDD',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#fff',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#fff',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const getValueFromPathname = (pathname: string): number => {
  return pages.find((page) => page.href === pathname)?.value ?? 0;
};

function NavBar({ LeftComponents }: { LeftComponents?: JSX.Element }) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = React.useState<number>(() => {
    const { pathname } = location;
    return getValueFromPathname(pathname);
  });

  const handleChange = (event, newValue) => {
    // setValue(newValue);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigateRouter = (href: string | null) => {
    navigate(href);
  };

  const onClickNavItemMobile = (href: string | null, value: number) => {
    setValue(value);
    navigateRouter(href);
    handleCloseNavMenu();
  };

  React.useEffect(() => {
    if (location?.pathname) {
      const valueTemp = getValueFromPathname(location?.pathname);
      if (value !== valueTemp) {
        setValue(valueTemp);
      }
    }
  }, [location?.pathname]);

  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {pages.map((page) => (
            <MenuItem key={page.title} onClick={() => onClickNavItemMobile(page.href, page.value)}>
              <Typography textAlign="center">{page.title}</Typography>
            </MenuItem>
          ))}
        </Menu>
        {LeftComponents}
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
        <NavBarTabs value={value} onChange={handleChange} aria-label="ant example">
          {pages.map((page) => (
            <NavBarTab label={page.title} onClick={() => navigateRouter(page.href)}></NavBarTab>
          ))}
          {LeftComponents}
        </NavBarTabs>
      </Box>
    </>
  );
}

export default NavBar;
