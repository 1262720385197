import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";


// Material Dashboard 2 React context
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";

function SideNavCollapse({ icon, name, path, active, ...rest }) {
  return (
    <NavLink to={path}>
      <ListItem sx={{

      }}>
        <Box sx={{
          display: 'flex', alignItems: 'center', width: '100%',
          ":hover": {
            background: 'rgba(255, 255, 255, 0.2)'
          },
          padding: '0.5rem 0.625rem',
          borderRadius: '0.375rem'
        }}>
          <ListItemIcon sx={{ color: 'white' }}>
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={name}
            sx={{ color: 'white' }}
          />
        </Box>
      </ListItem>
    </NavLink>
  );
}

export default SideNavCollapse;
