import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import 'react-virtualized/styles.css';
import { HeadCell } from './Header';

import { useIsMobile } from 'src/hooks/useIsMobile';
import { Order, getComparator, stableSort } from 'src/utils/sort';
import { MaterialReactTable, useMaterialReactTable } from '../MuiReactTable';
import './styles.css';

interface EnhancedTableProps<T> {
  initOrderBy: keyof T;
  headCells: HeadCell<T>[];
  bodyCells: Array<T>;
  initOrder?: Order;
}

// export const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: '#F2F2F2',
//     color: theme.palette.common.black,
//     fontWeight: 'bold',
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     border: '1px solid #E5E5E5',
//     maxWidth: '300px',
//     wordBreak: 'break-word',
//     paddingBlock: '5px',
//   },
// }));

const customCellRenderer = ({ cellData, columnIndex, key, rowIndex, style }) => {
  const cellStyle = {
    color: 'black',
    fontSize: 14,
    wordBreak: 'break-word',
    textAlign: 'center',
    textWrap: 'wrap',
    textOverflow: 'ellipsis',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Box key={key} style={{ ...style, ...cellStyle, display: 'flex', alignItems: 'center' }}>
      {cellData}
    </Box>
  );
};

export default function TableVirtualized<T>({
  initOrderBy,
  headCells,
  bodyCells,
  initOrder = 'asc',
}: EnhancedTableProps<T>) {
  const [order, setOrder] = React.useState<Order>(initOrder);
  const [orderBy, setOrderBy] = React.useState<keyof T>(initOrderBy);
  const isMobile = useIsMobile();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dataRows = React.useMemo(
    () => stableSort(bodyCells, getComparator(order, orderBy) as any),
    [bodyCells, order, orderBy],
  );

  // const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
  //   handleRequestSort(event, property);
  // };

  // const rowRenderer = useCallback(
  //   ({
  //     className,
  //     columns,
  //     index,
  //     isScrolling,
  //     key,
  //     onRowClick,
  //     onRowDoubleClick,
  //     onRowRightClick,
  //     onRowMouseOver,
  //     onRowMouseOut,
  //     rowData,
  //     style,
  //   }) => {
  //     const row = dataRows[index] as BetTableData;
  //     const isHighlight = row?.isHighlight;
  //     return (
  //       <Box
  //         className={className}
  //         key={key}
  //         role="row"
  //         style={{ ...style }}
  //         sx={{
  //           ...(isHighlight
  //             ? {
  //                 backgroundColor: '#ff4359',
  //                 '> div > div': {
  //                   color: 'white !important',
  //                 },
  //               }
  //             : {}),
  //         }}
  //       >
  //         {columns}
  //       </Box>
  //     );
  //   },
  //   [dataRows],
  // );
  const columns = useMemo(
    () =>
      headCells.map<any>((headerCell) => {
        return {
          accessorKey: headerCell.id, //simple recommended way to define a column
          header: headerCell.label,
          size: headerCell.width ?? 200,
          // muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
          Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
        };
      }),
    // [
    //   {
    //     accessorKey: 'message', //simple recommended way to define a column
    //     header: 'Tin',
    //     muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
    //     Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
    //   },
    //   // {
    //   //   accessorFn: (row) => row.age, //alternate way
    //   //   id: 'age', //id required if you use accessorFn instead of accessorKey
    //   //   header: 'Age',
    //   //   Header: () => <i>Age</i>, //optional custom header render
    //   // },
    // ],
    [headCells],
  );
  const table = useMaterialReactTable({
    columns,
    data: bodyCells, //10,000 rows
    // defaultDisplayColumn: { enableResizing: false, enableSorting: false, enableHiding: false,  },
    enableBottomToolbar: false,
    enableColumnResizing: false,
    enableColumnVirtualization: true,
    enableGlobalFilterModes: false,
    enablePagination: false,
    enableColumnPinning: false,
    enableRowNumbers: false,
    enableRowVirtualization: true,
    muiTableContainerProps: { sx: { maxHeight: '100%' } },
    enableColumnActions: false,
    enableTopToolbar: false,
    // muiTableBodyProps: {
    //   sx: {
    //     //stripe the rows, make odd rows a darker color
    //     '& td': {
    //       backgroundColor: 'red',
    //     },
    //   },
    // },
    // onSortingChange: setSorting,
    // state: { isLoading, sorting },
    // rowVirtualizerInstanceRef, //optional
    // rowVirtualizerOptions: { overscan: 5 }, //optionally customize the row virtualizer
    // columnVirtualizerOptions: { overscan: 2 }, //optionally customize the column virtualizer
  } as any);

  // const table = useMaterialReactTable({
  //   columns,
  //   data: bodyCells,
  //   enableColumnOrdering: true, //enable some features
  //   enableRowSelection: true,
  //   enablePagination: false, //disable a default feature
  //   // onRowSelectionChange: setRowSelection, //hoist internal state to your own state (optional)
  //   // state: { rowSelection }, //manage your own state, pass it back to the table (optional)
  // });
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        '> div': {
          height: '100% !important',
          width: '100% !important',
        },
      }}
    >
      <MaterialReactTable table={table} />
      {/* <div style={{ height: '80vh', width: '90%' }}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              gridStyle={{ outline: 'none' }}
              width={width}
              height={height}
              headerHeight={20}
              rowHeight={70}
              rowCount={dataRows.length}
              rowGetter={({ index }) => dataRows[index]}
            >
              <Column width={200} label="Number" dataKey="number" />
              <Column width={200} label="Name" dataKey="name" />
            </Table>
          )}
        </AutoSizer>
      </div> */}
      {/* <AutoSizer>
        {({ height, width }) => (
          <Table
            gridStyle={{ outline: 'none', width: isMobile ? '1000px' : '100%' }}
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            rowCount={dataRows.length}
            rowGetter={({ index }) => dataRows[index]}
            headerClassName="custom-header"
            rowClassName="custom-grid"
            rowRenderer={rowRenderer}
          >
            {headCells.map((headerCell) => (
              <Column
                label={headerCell.label}
                dataKey={headerCell.id}
                width={headerCell.width ?? 200}
                cellRenderer={customCellRenderer}
                headerRenderer={({ label, dataKey }) => (
                  <TableCell
                    key={dataKey}
                    sortDirection={orderBy === headerCell.id ? order : false}
                    sx={{
                      minWidth: '100px',
                      textAlign: 'center',
                      borderBottom: 0,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto,sans-serif !important',
                      userSelect: 'none',
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === headerCell.id}
                      direction={orderBy === headerCell.id ? order : 'asc'}
                      onClick={createSortHandler(headerCell.id)}
                      sx={{
                        userSelect: 'auto',
                      }}
                    >
                      {label}
                      {orderBy === headerCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                )}
              />
            ))}
          </Table>
        )}
      </AutoSizer> */}
    </Box>
  );
}
