import { AxiosResponse } from 'axios';
import axios from 'src/api';
import {
  CREATE_USERS_BY_ADMIN,
  CREATE_USERS_BY_ADMIN_V2,
  EXPIRED_DATE_USERS_BY_ADMIN,
  GET_USERS_BY_ADMIN,
  LOCK_USERS_BY_ADMIN,
  UPDATE_USERS_BY_ADMIN,
  USER,
} from 'src/api/configs';
import { Role } from './authServices';

export interface UsersAccount {
  displayname: string;
  expired: string;
  key: string | number;
  locked: string;
  password: string;
  role: Role;
  username: string;
}

export interface LockUserResponse {
  displayname: string;
  expired: string;
  key: string | number;
  locked: string;
  password: string;
  role: Role;
  username: string;
}

export const getUserList = (): Promise<AxiosResponse<UsersAccount[]>> => {
  return axios.get(`${GET_USERS_BY_ADMIN}`);
};

export const postCreateUser = (data: {
  displayname: string;
  username: string;
  password: string;
  role: Role;
  expired: number;
  cloneid?: string;
}): Promise<AxiosResponse<UsersAccount[]>> => {
  return axios.post(`${data.cloneid ? CREATE_USERS_BY_ADMIN_V2 : CREATE_USERS_BY_ADMIN}`, data);
};

export const postLockUser = (data: {
  isLock: boolean;
  id: string;
}): Promise<AxiosResponse<LockUserResponse>> => {
  return axios.post(`${LOCK_USERS_BY_ADMIN}/${data.id}`, {
    lock: data.isLock ? 'True' : 'False',
  });
};

export const postExpiredDateUser = (data: {
  subscribe: number;
  id: string;
}): Promise<AxiosResponse<any>> => {
  return axios.post(`${EXPIRED_DATE_USERS_BY_ADMIN}`, data);
};

export const poseUpdateUser = (
  id: string,
  data: {
    displayname: string;
    role: Role;
    expired: number; // timestamp
    locked: 'True' | 'False';
  },
): Promise<AxiosResponse<any>> => {
  return axios.post(`${UPDATE_USERS_BY_ADMIN}/${id}`, data);
};

export const deleteUser = (id: string): Promise<void> => {
  return axios.delete(`${USER}/delete/${id}`);
};
