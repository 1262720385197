import { Box, Theme, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckboxCustom } from 'src/styles/muiCustoms';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
    },
    formGroup: {
        marginLeft: '20px'
    }
}))

interface Direction {
    value: DirectionID,
    label: string
}

export type DirectionID = 'North' | 'Central' | 'South'

const directions: Direction[] = [
    {
        value: 'North',
        label: 'Bắc'
    },
    {
        value: 'Central',
        label: 'Trung'
    },
    {
        value: 'South',
        label: 'Nam'
    },
]

export type CheckCalKI = Record<DirectionID, boolean>

interface DirectionsCustomerProps {
    value?: CheckCalKI
    handleChange?: (value: CheckCalKI) => void
}

const DirectionsCustomer: React.FC<DirectionsCustomerProps> = ({ value, handleChange }) => {
    const theme = useTheme()
    const classes = useStyles(theme)
    const [checks, setChecks] = useState<CheckCalKI>(value ?? {
        'North': true,
        'Central': false,
        'South': false
    })

    React.useEffect(() => {
        if (value) {
            setChecks(value)
        }
    }, [value])

    const _onChange = (evt: any) => {
        const checked = evt.target.value as DirectionID
        const checkCopy = { ...checks, [checked]: !checks[checked] }
        setChecks(checkCopy)
        handleChange && handleChange(checkCopy)
    }

    return (
        <Box className={classes.container}>
            <Typography>Tính KI Rưỡi</Typography>
            <FormGroup aria-label="position" row className={classes.formGroup}>
                {
                    directions.map((direction, index) => (
                        <FormControlLabel
                            value={direction.value}
                            control={<CheckboxCustom checked={checks[direction.value]} />}
                            label={direction.label}
                            labelPlacement="end"
                            onChange={_onChange}
                        />
                    ))
                }
            </FormGroup>
        </Box>
    )
}

export default React.memo(DirectionsCustomer, (preValue, nextValue) => preValue.value === nextValue.value)