import { Box, Button, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import { breakpointDesktop } from 'src/hooks/useBreakpoints';
import { selectTabs, TabsItem } from 'src/reducer/tabsSlice';
import { useAppSelector } from 'src/store';
import { buttonPrimary } from 'src/styles/themes';
import ReportModal, { ReportModalRef } from 'src/views/Report/ReportModal';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
    ...breakpointDesktop(theme, {
      marginTop: '3px',
    }),
  },
}));

function BetStatisticalFooter({
  onClickSummaryReport,
  isDisablePrint,
  onClickPrint,
}: {
  onClickSummaryReport?: () => void;
  onClickPrint?: () => void;
  isDisablePrint?: boolean;
}) {
  const theme = useTheme() as Theme;
  const classes = useStyles(theme);
  const reportModalRef = React.useRef<ReportModalRef>();
  const { dateSelected, data } = useAppSelector(selectTabs);

  const tabSelected = React.useMemo<TabsItem | null>(() => {
    const selected = data[dateSelected]?.selected;
    if (data && dateSelected) {
      return data[dateSelected]?.tabs?.find((item) => item.id === selected) || null;
    } else {
      return null;
    }
  }, [data, dateSelected]);

  const name = tabSelected?.name;
  const customerId = tabSelected?.data?.idCustomer?.toString();
  const date = dateSelected ? moment(dateSelected, 'DD-MM-YYYY').toDate() : null;

  const onClickReport = () => {
    if (name && customerId && date) {
      reportModalRef.current.onOpen(null, name, customerId, date);
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <Button
          variant="contained"
          sx={{ ...buttonPrimary(theme), marginLeft: '5px' }}
          onClick={() => onClickSummaryReport && onClickSummaryReport()}
        >
          Chụp Tổng
        </Button>
        <Button
          variant="contained"
          sx={{ ...buttonPrimary(theme), marginLeft: '5px' }}
          onClick={onClickReport}
        >
          Báo Sổ
        </Button>
      </Box>
      <Box className={classes.container}>
        <Button
          variant="contained"
          sx={buttonPrimary(theme)}
          onClick={() => {
            onClickPrint && onClickPrint();
          }}
          disabled={isDisablePrint}
        >
          In
        </Button>
      </Box>
      <ReportModal ref={reportModalRef} />
    </>
  );
}

export default BetStatisticalFooter;
