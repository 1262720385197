import { useRef } from "react"
import { UserConfigModalRef } from "src/views/Customer/UserConfigModal"

export const useUserConfigModal = () => {
    const modalRef = useRef<UserConfigModalRef>()

    const onClickConfig = (value: string) => {
        modalRef.current.handleOpen(value)
    }

    return {
        modalRef,
        onClickConfig
    }
}