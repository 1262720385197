import { TextField } from '@mui/material';
import _ from 'lodash';
import React, { useCallback } from 'react';

const Search: React.FC<any> = ({ onChange }) => {
  const [search, setSearch] = React.useState('');

  const debouncedSetSearch = useCallback(
    _.debounce((value) => {
      onChange && onChange(value);
    }, 500),
    [onChange],
  );

  return (
    <TextField
      sx={{
        minWidth: { xs: '100%', md: '500px' },
        width: '100%',
      }}
      label="Search"
      variant="outlined"
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
        debouncedSetSearch(e.target.value);
      }}
    />
  );
};

export default Search;
