import { REFRESH_TOKEN } from "src/api/configs";
import { axiosMockAdapterInstance } from "src/api/request";

axiosMockAdapterInstance.onPost(REFRESH_TOKEN).reply((config) => {
    const { refreshToken } = JSON.parse(config.data)
    if (refreshToken) {
        const newRefreshToken = {
            "access": {
                "token": "new-token-eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZWJhYzUzNDk1NGI1NDEzOTgwNmMxMTIiLCJpYXQiOjE1ODkyOTg0ODQsImV4cCI6MTU4OTMwMDI4NH0.m1U63blB0MLej_WfB7yC2FTMnCziif9X8yzwDEfJXAg",
                "expires": "2022-03-31T16:18:04.793z"
            },
            "refresh": {
                "token": "new-refresh-eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZWJhYzUzNDk1NGI1NDEzOTgwNmMxMTIiLCJpYXQiOjE1ODkyOTg0ODQsImV4cCI6MTU4OTMwMDI4NH0.m1U63blB0MLej_WfB7yC2FTMnCziif9X8yzwDEfJXAg",
                "expires": "2022-03-31T16:18:04.793z"
            }
        }
        return [200, newRefreshToken]
    } else {
        return [401, { message: "Please authenticate" }]
    }
})

axiosMockAdapterInstance.onGet("/auth/refresh-token-call").reply((config) => {
    const assetToken = config.headers['x-access-token'] as string
    const tem = assetToken.split('-')[0]
    if (tem === 'new') {
        return [201, { message: "success" }]
    }
    return [401, { message: 'Your token expires!' }]
})