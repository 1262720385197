import React from 'react';
import { NavigateToHomeState, useNavigateToHome } from './useNavigateToHome';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import { useAppSelector } from 'src/store';
import { selectTabs } from 'src/reducer/tabsSlice';

export const useEffectNavigateHandle = (
  handleWhenTabExist = (state: NavigateToHomeState) => {},
  handleWhenTabNotExist = (state: NavigateToHomeState) => {},
) => {
  const { state } = useNavigateToHome();
  const { dateSelected, data } = useAppSelector(selectTabs);

  const { agencies } = useAppSelector(selectAgencies);
  React.useEffect(() => {
    if (agencies?.length > 0 && state?.id && state?.label) {
      if (data[dateSelected].tabs.length > 0) {
        const isExistTab = data[dateSelected].tabs.findIndex(
          (item) => item.data.idCustomer.toString() === state.id.toString(),
        );
        if (isExistTab === -1) {
          handleWhenTabNotExist && handleWhenTabNotExist(state);
        } else {
          handleWhenTabExist && handleWhenTabExist(state);
        }
      } else {
        handleWhenTabNotExist && handleWhenTabNotExist(state);
      }
    }
  }, [agencies?.length, state?.id, state?.label]);
};
