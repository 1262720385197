import { Box, Grid, SxProps, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { copyBlobToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import React from 'react';
import { alertInstance } from 'src/contexts/AlertContext';
import { breakpointUp } from 'src/hooks/useBreakpoints';
import { useChangeTabData } from 'src/hooks/useChangeTabData';
import { Summary, TabsItem } from 'src/reducer/tabsSlice';
import { ResultData } from 'src/services/messageServices';
import { betBlockTheme } from 'src/styles/themes';
import { getIndexByBet } from 'src/utils';
import BetStatisticalFooter from './BetStatisticalFooter';
import BetMessageBillHeader from './BetStatisticalHeader';
import BetStatisticalItem from './BetStatisticalItem';
import PrintResultModal, { PrintResultModalRef } from './PrintResultModal';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    ...betBlockTheme(theme),
    marginTop: '10px',
    ...breakpointUp(theme, {
      sm: {
        marginTop: '0',
      },
      lg: {
        marginTop: '0',
        height: '100%',
      },
    }),
  },
  containerItem: {
    padding: '0 10px',
    alignItems: 'center',
  },
}));

type BetStatistical =
  | 'ĐĐ'
  | 'Lô'
  | 'ĐT'
  | 'ĐX'
  | '3C'
  | '4C'
  | 'XC'
  | '+2C'
  | '+3C'
  | 'Lời/Lỗ'
  | 'Total';

const betStatisticals: {
  title: BetStatistical;
  style?: SxProps<Theme>;
}[] = [
  {
    title: 'ĐĐ',
  },
  {
    title: 'Lô',
  },
  {
    title: 'ĐT',
  },
  {
    title: 'ĐX',
  },
  {
    title: '3C',
  },
  {
    title: '4C',
  },
  {
    title: 'XC',
  },
  {
    title: '+2C',
  },
  {
    title: '+3C',
  },
  {
    title: 'Lời/Lỗ',
  },
  {
    title: 'Total',
  },
];

const isHighLightColumn = (value: BetStatistical) => {
  return value === 'ĐT' || value === 'ĐX' || value === '+2C' || value === '+3C';
};

const getValueLeftRight = (data: [number, number] | number): Summary => {
  if (typeof data !== 'number' && data?.length === 2) {
    return {
      valueLeft: data[0],
      valueRight: data[1],
    };
  } else if (typeof data === 'number') {
    return {
      valueRight: data,
    };
  } else {
    return {
      valueLeft: null,
      valueRight: null,
    };
  }
};

const transferResultsToSummary = (results: ResultData): Summary[] => {
  return [
    getValueLeftRight(results.dauduoi),
    getValueLeftRight(results.lo),
    getValueLeftRight(results.dt),
    getValueLeftRight(results.dx),
    getValueLeftRight(results.c3),
    getValueLeftRight(results.c4),
    getValueLeftRight(results.xc),
    getValueLeftRight(results.plus2c),
    getValueLeftRight(results.plus3c),
    getValueLeftRight(results.loilo),
  ];
};

export interface PrintNavigateState {
  customerId: string;
  createAt: string;
}

const renderValueExceptZero = (title: BetStatistical, value?: number | null): string => {
  if (title !== 'Lời/Lỗ' && title !== 'Total') {
    if (!value) {
      return '';
    }
  }
  return value ? value.toString() : '0';
};

const renderTitle = (item: BetStatistical, value?: number | null): string => {
  if (item === 'Lời/Lỗ') {
    return value >= 0 ? 'Lời' : 'Lỗ';
  }
  return item;
};

function BetStatistical() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [summary, setSummary] = React.useState<Summary[]>([]);
  const printRef = React.useRef();
  const printResultRef = React.useRef<PrintResultModalRef>();
  // const alertRef = React.useRef<AlertSnackBarRef>(null)

  const customerID = React.useRef(null);
  const createAt = React.useRef(null);

  useChangeTabData((dataTabSelected: TabsItem) => {
    const {
      data: { dataMessage, bet },
    } = dataTabSelected;
    if (dataMessage?.result?.length > 2) {
      const index = getIndexByBet(bet);
      const results = dataMessage?.result[index];
      if (results) {
        const resultsTransfer = transferResultsToSummary(results);
        const total = dataMessage.state?.total ?? 0;
        //TODO: total check
        // const total = dataMessage?.result[index].
        setSummary([...resultsTransfer, { valueRight: total }]);
      } else {
        if (summary.length > 0) {
          setSummary([]);
        }
      }
    } else {
      if (summary.length > 0) {
        setSummary([]);
      }
    }

    if (dataMessage?.state?.customer_id) {
      customerID.current = dataMessage?.state?.customer_id;
    } else {
      customerID.current = null;
    }

    if (dataMessage?.state?.create_at) {
      createAt.current = dataMessage?.state?.create_at;
    } else {
      createAt.current = null;
    }
  });

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    canvas.toBlob(
      (blob) => {
        copyBlobToClipboard(blob);
        alertInstance.handleOpen('success', 'Copied Successfully!');
      },
      'image/png',
      1,
    );
  };

  const onClickSummaryReport = () => {
    handleDownloadImage();
  };

  const onClickPrint = () => {
    if (customerID.current && createAt.current) {
      printResultRef.current.handleOpen(customerID.current, createAt.current);
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <Box ref={printRef}>
          <BetMessageBillHeader />
          <Grid container rowGap={'5px'} className={classes.containerItem}>
            {betStatisticals.map((item, index) => (
              <BetStatisticalItem
                key={index.toString()}
                title={renderTitle(item.title, summary[index]?.valueRight)}
                column={index > 8 ? '1' : '2'}
                valueLeft={renderValueExceptZero(item.title, summary[index]?.valueLeft)}
                valueRight={renderValueExceptZero(item.title, summary[index]?.valueRight)}
                titleStyle={
                  item.title === '+2C' || item.title === '+3C'
                    ? {
                        fontWeight: 'bold',
                        color: '#1d4f76',
                      }
                    : item.title === 'Lời/Lỗ' || item.title === 'Total'
                    ? summary[index]?.valueRight < 0
                      ? {
                          color: 'red',
                        }
                      : {
                          color: 'green',
                        }
                    : {}
                }
                inputRightStyle={
                  item.title === 'ĐT' || item.title === 'ĐX'
                    ? summary[index]?.valueRight > 0
                      ? {
                          background: '#dbebf4',
                        }
                      : {}
                    : item.title === '+2C' || item.title === '+3C'
                    ? {
                        background: '#dbebf4',
                        fontWeight: 'bold',
                      }
                    : item.title === 'Lời/Lỗ' || item.title === 'Total'
                    ? {
                        fontWeight: 'bold',
                        color: summary[index]?.valueRight < 0 ? 'red' : 'green',
                      }
                    : {}
                }
                inputLeftStyle={
                  item.title === '+2C' || item.title === '+3C'
                    ? {
                        background: '#dbebf4',
                        fontWeight: 'bold',
                      }
                    : {}
                }
              />
            ))}
          </Grid>
        </Box>
        <BetStatisticalFooter
          onClickSummaryReport={onClickSummaryReport}
          isDisablePrint={
            summary.length === 0 || (summary.length > 0 && summary[0].valueLeft === null)
          }
          onClickPrint={onClickPrint}
        />
      </Box>
      <PrintResultModal ref={printResultRef} />
    </>
  );
}

export default BetStatistical;
