import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  Active,
  Announcements,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  KeyboardCoordinateGetter,
  KeyboardSensor,
  MeasuringConfiguration,
  Modifiers,
  MouseSensor,
  PointerActivationConstraint,
  ScreenReaderInstructions,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  NewIndexGetter,
  SortableContext,
  SortingStrategy,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import './styles.css';
import { ScrollMenu, VisibilityContext } from 'src/pages/Test/ScrollBar';
import { FooterItem } from '.';
import { Item, List } from './components';
import { createRange } from './utilities';
import BasicPopover from './components/Item/BasicPopover';
import { ColorTab } from './components/Item/type';

export interface Props {
  activationConstraint?: PointerActivationConstraint;
  animateLayoutChanges?: AnimateLayoutChanges;
  adjustScale?: boolean;
  collisionDetection?: CollisionDetection;
  coordinateGetter?: KeyboardCoordinateGetter;
  Container?: any; // To-do: Fix me
  dropAnimation?: DropAnimation | null;
  getNewIndex?: NewIndexGetter;
  handle?: boolean;
  itemCount?: number;
  measuring?: MeasuringConfiguration;
  modifiers?: Modifiers;
  renderItem?: any;
  removable?: boolean;
  reorderItems?: typeof arrayMove;
  strategy?: SortingStrategy;
  style?: React.CSSProperties;
  useDragOverlay?: boolean;
  getItemStyles?(args: {
    id: UniqueIdentifier;
    index: number;
    isSorting: boolean;
    isDragOverlay: boolean;
    overIndex: number;
    isDragging: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: {
    active: Pick<Active, 'id'> | null;
    index: number;
    isDragging: boolean;
    id: UniqueIdentifier;
  }): React.CSSProperties;
  isDisabled?(id: UniqueIdentifier): boolean;
  onChangeId?: (id: UniqueIdentifier) => void;
  onChangePosition?: (position: UniqueIdentifier[]) => void;
  onRemoveTab?: (id: UniqueIdentifier) => void;
  onChangeColor?: (itemSelected: FooterItem, color: ColorTab) => void;
  tabsItem: FooterItem[];
  tabsPosition: string[];
  tabsSelected: string;
  indexLoading?: number | null;
}
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent, show?: boolean): void {
  if (show) return;
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY > 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY < 0) {
    apiObj.scrollPrev();
  }
}

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

const screenReaderInstructions: ScreenReaderInstructions = {
  draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `,
};

export function Sortable({
  activationConstraint,
  animateLayoutChanges,
  adjustScale = false,
  Container = List,
  collisionDetection = closestCenter,
  coordinateGetter = sortableKeyboardCoordinates,
  dropAnimation = dropAnimationConfig,
  getItemStyles = () => ({}),
  getNewIndex,
  handle = false,
  // itemCount = 16,
  isDisabled = () => false,
  measuring,
  modifiers,
  removable,
  renderItem,
  reorderItems = arrayMove,
  strategy = rectSortingStrategy,
  style,
  useDragOverlay = true,
  wrapperStyle = () => ({}),
  onChangeId,
  onChangePosition,
  onChangeColor,
  onRemoveTab,
  tabsItem: itemsData,
  tabsPosition,
  tabsSelected,
  indexLoading = null,
}: Props) {
  // const [itemsData, setItemsData] = useState<FooterItem[]>([]);
  const [items, setItems] = useState<UniqueIdentifier[]>(() =>
    createRange<UniqueIdentifier>(0, (index) => index + 1),
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(1);
  const mounted = useRef<boolean>(false);

  // React.useEffect(() => {
  //   setItemsData(tabsItem);
  // }, [tabsItem]);

  React.useEffect(() => {
    setItems(tabsPosition);
  }, [tabsPosition]);

  React.useEffect(() => {
    setActiveId(tabsSelected);
  }, [tabsSelected]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      // Disable smooth scrolling in Cypress automated tests
      scrollBehavior: 'Cypress' in window ? 'auto' : undefined,
      coordinateGetter,
    }),
  );
  const isFirstAnnouncement = useRef(true);
  const getIndex = (id: UniqueIdentifier) => items.indexOf(id);
  const getPosition = (id: UniqueIdentifier) => getIndex(id) + 1;
  const activeIndex = activeId ? getIndex(activeId) : -1;

  const handleRemove = removable
    ? (id: UniqueIdentifier) => {
        onRemoveTab && onRemoveTab(id);
      }
    : undefined;
  const announcements: Announcements = {
    onDragStart({ active: { id } }) {
      return `Picked up sortable item ${String(
        id,
      )}. Sortable item ${id} is in position ${getPosition(id)} of ${items.length}`;
    },
    onDragOver({ active, over }) {
      if (isFirstAnnouncement.current === true) {
        isFirstAnnouncement.current = false;
        return;
      }

      if (over) {
        return `Sortable item ${active.id} was moved into position ${getPosition(over.id)} of ${
          items.length
        }`;
      }

      return;
    },
    onDragEnd({ active, over }) {
      if (over) {
        return `Sortable item ${active.id} was dropped at position ${getPosition(over.id)} of ${
          items.length
        }`;
      }

      return;
    },
    onDragCancel({ active: { id } }) {
      return `Sorting was cancelled. Sortable item ${id} was dropped and returned to position ${getPosition(
        id,
      )} of ${items.length}.`;
    },
  };

  useEffect(() => {
    if (!activeId) {
      isFirstAnnouncement.current = true;
    }
  }, [activeId]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  const onClickItem = (id: UniqueIdentifier) => {
    onChangeActiveId(id);
  };

  const onChangeActiveId = (id: UniqueIdentifier) => {
    setActiveId(id);
    onChangeId && onChangeId(id);
  };

  const temp = useRef(false);

  const onShow = (show: boolean) => {
    temp.current = show;
  };

  return (
    <DndContext
      accessibility={{
        announcements,
        screenReaderInstructions,
      }}
      sensors={sensors}
      collisionDetection={collisionDetection}
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }
        onChangeActiveId(active.id);
      }}
      onDragEnd={({ over }) => {
        // setActiveId(null);
        if (over) {
          const overIndex = getIndex(over.id);
          if (activeIndex !== overIndex) {
            setItems((items) => {
              const orderItems = reorderItems(items, activeIndex, overIndex);
              onChangePosition && onChangePosition(orderItems);
              return orderItems;
            });
          }
        }
      }}
      // onDragCancel={() => setActiveId(null)}
      measuring={measuring}
      modifiers={modifiers}
    >
      <SortableContext items={items} strategy={strategy}>
        <div
          className="scrollbar-container"
          style={{
            width: '100%',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            // minHeight: '100vh',
          }}
        >
          <ScrollMenu onWheel={(a, b) => onWheel(a, b, temp.current)}>
            {items.map((value, index) => {
              const itemSelected = itemsData.find((items) => items.id === value);
              return (
                <>
                  <SortableItem
                    onClickItem={onClickItem}
                    key={value}
                    id={value}
                    valueTitle={itemSelected?.name ?? ''}
                    backgroundTabColor={itemSelected?.color}
                    handle={handle}
                    index={index}
                    style={getItemStyles}
                    wrapperStyle={wrapperStyle}
                    disabled={isDisabled(value)}
                    renderItem={renderItem}
                    onRemove={handleRemove}
                    animateLayoutChanges={animateLayoutChanges}
                    useDragOverlay={useDragOverlay}
                    getNewIndex={getNewIndex}
                    isActive={activeId === value}
                    isLoading={index === indexLoading}
                  />
                  <BasicPopover
                    index={index}
                    onShow={onShow}
                    onChangeColor={(color) => onChangeColor(itemSelected, color)}
                  />
                </>
              );
            })}
          </ScrollMenu>
        </div>
      </SortableContext>
      {useDragOverlay
        ? createPortal(
            <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
              {activeId ? (
                <Item
                  value={items[activeIndex]}
                  handle={handle}
                  valueTitle={itemsData.find((items) => +items.id === activeId)?.name ?? ''}
                  renderItem={renderItem}
                  wrapperStyle={wrapperStyle({
                    active: { id: activeId },
                    index: activeIndex,
                    isDragging: true,
                    id: items[activeIndex],
                  })}
                  isActive={true}
                  style={getItemStyles({
                    id: items[activeIndex],
                    index: activeIndex,
                    isSorting: activeId !== null,
                    isDragging: true,
                    overIndex: -1,
                    isDragOverlay: true,
                  })}
                  dragOverlay
                />
              ) : null}
            </DragOverlay>,
            document.body,
          )
        : null}
    </DndContext>
  );
}

interface SortableItemProps {
  animateLayoutChanges?: AnimateLayoutChanges;
  disabled?: boolean;
  getNewIndex?: NewIndexGetter;
  id: UniqueIdentifier;
  index: number;
  handle: boolean;
  useDragOverlay?: boolean;
  onRemove?(id: UniqueIdentifier): void;
  style(values: any): React.CSSProperties;
  renderItem?(args: any): React.ReactElement;
  wrapperStyle: Props['wrapperStyle'];
  isActive: boolean;
  valueTitle: string;
  onClickItem?: (id: UniqueIdentifier) => void;
  isLoading?: boolean;
  backgroundTabColor?: ColorTab;
}

export function SortableItem({
  disabled,
  animateLayoutChanges,
  getNewIndex,
  handle,
  id,
  index,
  onRemove,
  style,
  renderItem,
  useDragOverlay,
  wrapperStyle,
  isActive,
  valueTitle,
  onClickItem,
  isLoading,
  backgroundTabColor,
}: SortableItemProps) {
  const {
    active,
    attributes,
    isDragging,
    isSorting,
    listeners,
    overIndex,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled,
    getNewIndex,
  });

  return (
    <Item
      backgroundTabColor={backgroundTabColor}
      onClickItem={onClickItem ? () => onClickItem(id) : undefined}
      ref={setNodeRef}
      value={id}
      valueTitle={valueTitle}
      disabled={disabled}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={
        handle
          ? {
              ref: setActivatorNodeRef,
            }
          : undefined
      }
      renderItem={renderItem}
      index={index}
      style={style({
        index,
        id,
        isDragging,
        isSorting,
        overIndex,
      })}
      isActive={isActive}
      onRemove={onRemove ? () => onRemove(id) : undefined}
      transform={transform}
      transition={transition}
      wrapperStyle={wrapperStyle?.({ index, isDragging, active, id })}
      listeners={listeners}
      data-index={index}
      data-id={id}
      dragOverlay={!useDragOverlay && isDragging}
      isLoading={isLoading}
      {...attributes}
    />
  );
}
