import { AxiosResponse } from 'axios';
import axios from 'src/api';
import { AGENCIES, CUSTOMER_CREATE, CUSTOMER_LIST } from 'src/api/configs';

export type SynTaxResponse = 'DSK' | 'SKD';

export interface Agency {
  brokers_multiplied: number;
  config_b_as_duoi: boolean;
  enable_duoi_3c_4c: boolean;
  enable_da_cap: boolean;
  enable_da_trung: boolean;
  create_at: string;
  delivery: number;
  divide: number[];
  win_divide: number[];
  half_ki: number;
  key: string | number;
  mid: number[];
  mid_channels: number[];
  name: string;
  north: number[];
  south: number[];
  south_channels: number[];
  syntax: string;
  user_key: string;
  warning: number;
}
// export interface Agency {
//     "dealerOrder": string[],
//     "name": string,
//     "contractor": string,
//     "deOrRe": boolean,
//     "type": boolean,
//     "syntax": SynTaxResponse,
//     "calKI": number,
//     "divide": string,
//     "northern": string,
//     "central": string,
//     "south": string,
//     "id": string
// }

export type Agencies = Array<Agency>;

export interface AgenciesResponse {
  create_at: string;
  key: string | number;
  name: string;
  warning: string;
}

export const getAllAgenciesApi = (): Promise<AxiosResponse<AgenciesResponse>> => {
  return axios.get(CUSTOMER_LIST);
};

export type CreateAgencyParams = { name: string };

export type AgencyDefault = Omit<Agency, 'id' | 'name' | 'contractor'>;

export const postCreateAgencyApi = ({ name }: CreateAgencyParams): Promise<AxiosResponse<any>> => {
  return axios.post(`${CUSTOMER_CREATE}`, {
    name,
  });
};

export const deleteAgencyApi = ({ id }: { id: string }) => {
  return axios.delete(`${AGENCIES}/delete/${id}`);
};

export const editAgencyApi = ({ id, name }: { id: string; name: string }) => {
  return axios.post(`${AGENCIES}/rename/${id}`, {
    name,
  });
};

export const getDetailAgency = ({ id }: { id: string }): Promise<AxiosResponse<Agency>> => {
  return axios.get(`${AGENCIES}/${id}`);
};

export const updateDetailAgency = (data: Agency): Promise<AxiosResponse<void>> => {
  return axios.post(`${AGENCIES}/update`, data);
};
