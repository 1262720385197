import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Editor, Text, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { TIME_INPUT_DEBOUNCE } from 'src/constants';
import './styles.css';
type SearchHighlightingProps = {
  search?: string;
  onChangeMessageDebounce?: (message: string) => void;
  initValueText?: string;
  error_part?: string;
  startPos?: number;
  endPos?: number;
};

let temp = false;

const SearchHighlighting: React.FC<SearchHighlightingProps> = ({
  error_part,
  startPos,
  endPos,
  search,
  onChangeMessageDebounce,
  initValueText,
}) => {
  const valueRef = React.useRef(initValueText);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  // const isEditing = useRef(false);
  const initValueTextRef = useRef(initValueText);
  const isInitUpdateRef = useRef(false);
  const [value, setValue] = useState('');

  const debouncedSetValue = useCallback(_.debounce(setValue, 200), []);

  useEffect(() => {
    debouncedSetValue(initValueText);
  }, [initValueText]);

  let tempRef = 0;

  useEffect(() => {
    initValueTextRef.current = value;
    if (!temp) {
      replaceEditorValue(value);
    } else {
      temp = false;
    }
  }, [value]);

  const decorate = useCallback(
    ([node, path]) => {
      if (path?.[0] === 0) {
        tempRef = 0;
      }
      const ranges = [];
      if (search && Text.isText(node) && node.text) {
        const { text } = node;

        const searchNormalize = search?.normalize('NFC');
        const textNormalize = text?.normalize('NFC');

        let index = 0;
        while ((index = textNormalize.indexOf(searchNormalize, index)) > -1) {
          ranges.push({
            anchor: { path, offset: index },
            focus: { path, offset: index + searchNormalize?.length },
            highlight: true,
          });
          index += searchNormalize?.length;
        }
        // const parts = text?.normalize('NFD').split(search?.normalize('NFD'));
        // let offset = 0;

        // parts.forEach((part, i) => {
        //   if (i !== 0) {
        //     ranges.push({
        //       anchor: { path, offset: offset - search?.normalize('NFD').length },
        //       focus: { path, offset: offset },
        //       highlight: true,
        //     });
        //   }

        //   offset = offset + part.length + search.length;
        // });
      }

      if (error_part && Text.isText(node)) {
        const errorPart = error_part.toLowerCase().split('\n');
        const positionRows = getPositionForEachLine(initValueTextRef.current);
        const { start, end } = positionRows[path?.[0]] ?? {};
        if (start <= endPos && end >= startPos) {
          const errorText = errorPart[tempRef];
          if (errorText) {
            const test = findPositions(node.text, errorText);
            test.map((item) => {
              const a = item.start + start;
              if (a >= startPos) {
                ranges.push({
                  anchor: { path, offset: item.start },
                  focus: { path, offset: item.end },
                  errorHighLight: true,
                });
              }
            });
          }
          tempRef = tempRef + 1;
        }
      }
      return ranges;
    },
    [search, error_part, startPos, endPos],
  );

  const debounceMessage = React.useCallback(
    _.debounce((newValue) => {
      onChangeMessageDebounce && onChangeMessageDebounce(newValue);
    }, TIME_INPUT_DEBOUNCE),
    [],
  );

  const replaceEditorValue = useCallback(
    (value: string) => {
      try {
        isInitUpdateRef.current = true;
        // Save the current cursor position
        const { selection } = editor;
        const currentSelection = value !== '' && selection ? { ...selection } : null;

        Transforms.delete(editor, {
          at: {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
          },
        });

        Transforms.removeNodes(editor, {
          at: [0],
        });
        value?.split('\n').forEach((item) => {
          Transforms.insertNodes(editor, [
            {
              type: 'paragraph',
              children: [{ text: item }],
            } as any,
          ]);
        });

        // Restore the cursor position only if it's still valid
        if (value !== '' && currentSelection) {
          const newPath = [0];
          const newPosition = Editor.hasPath(editor, newPath)
            ? Editor.start(editor, newPath)
            : null;

          if (newPosition) {
            const leafPath = [0, 0];
            if (Editor.hasPath(editor, leafPath)) {
              Transforms.select(editor, {
                anchor: { path: leafPath, offset: 0 },
                focus: { path: leafPath, offset: 0 },
              });
            }
          }
        }
      } catch (error) {
        console.error('Error replacing editor value:', error);
      }
    },
    [editor],
  );

  return (
    <Slate
      editor={editor}
      initialValue={[
        {
          type: 'paragraph',
          children: [{ text: value }],
        } as any,
      ]}
      onValueChange={(value: any) => {
        const searchValue = getSearchValue(value);
        if (!isInitUpdateRef.current) {
          temp = true;
          debounceMessage(searchValue);
        } else {
          temp = false;
          onChangeMessageDebounce && onChangeMessageDebounce(searchValue);
        }
        isInitUpdateRef.current = false;

        // isEditing.current = true;
        valueRef.current = searchValue;
        // setTimeout(() => {
        //   isEditing.current = false;
        // }, 500);
      }}
    >
      <Editable
        spellCheck={false}
        className="editable"
        decorate={decorate}
        renderLeaf={(props) => <Leaf {...props} />}
      />
    </Slate>
  );
};

const getPositionForEachLine = (input: string): { start: number; end: number }[] => {
  const rows = input.split('\n');
  let position = 0;
  const output = [];

  for (const row of rows) {
    const start = position;
    const end = start + row.length;
    output.push({ start, end });

    position = end + 1;
  }
  return output;
};

// input: "16+58 b 25 b 916 b 10 n xc 30 n dc"
// text: "16+58 b 25 b "
function findPositions(input, text) {
  const positions = [];
  // Escape special characters in the text for the regex
  const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedText, 'g');

  for (const match of input.matchAll(regex)) {
    positions.push({ start: match.index, end: match.index + text.length });
  }

  return positions;
}

const Leaf = ({ attributes, children, leaf }) => {
  return (
    <span
      {...attributes}
      {...(leaf.highlight && { 'data-cy': 'search-highlighted' })}
      style={{
        fontWeight: leaf.bold ? 'bold' : 'normal',
        backgroundColor: leaf.errorHighLight ? 'red' : leaf.highlight ? 'yellow' : 'inherit',
      }}
    >
      <i style={{ userSelect: 'none' }} contentEditable={false}></i>
      {children}
    </span>
  );
};

const getSearchValue = (value: { children: { text }[]; type: string }[]): string => {
  if (!value || !value.length) {
    return '';
  }
  return value.map((item) => item.children.map((item) => item.text).join(' ')).join('\n');
};

export default SearchHighlighting;
