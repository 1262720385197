import React, { useState } from 'react';
import { ButtonCustom, TextFieldCustom } from 'src/styles/muiCustoms';
import { Box, Stack, Grid, Typography, useTheme } from '@mui/material';
import { breakpointDown } from 'src/hooks/useBreakpoints';

interface ReportTextInputProps {
  title: string;
  onUpdate?: (value: number) => void;
}

const ReportTextInput: React.FC<ReportTextInputProps> = ({ title, onUpdate }) => {
  const theme = useTheme();
  const [value, setValue] = useState<string>('');
  return (
    <Stack
      direction={'row'}
      sx={{
        height: '100%',
        ...breakpointDown(theme, {
          lg: {
            height: '70%',
          },
          sm: {
            height: '100%',
          },
          xs: {
            height: '70%',
          },
        }),
      }}
    >
      <Stack
        sx={{
          width: '30%',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px 0 0 5px',
          background: '#F4F5F8',
        }}
      >
        <Typography>{title}</Typography>
      </Stack>
      <TextFieldCustom
        fullWidth
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '0px',
          },
        }}
        type={'number'}
        value={value}
        onChange={(evt) => setValue(evt.target.value)}
      />
      <ButtonCustom
        sx={{
          width: '30%',
          borderRadius: '0 5px 5px 0',
          color: 'white',
          fontSize: { xl: '1rem', xs: '0.75rem' },
        }}
        disabled={!value}
        onClick={() => {
          onUpdate && onUpdate(+value);
        }}
      >
        Cập nhật!
      </ButtonCustom>
    </Stack>
  );
};

export default ReportTextInput;
