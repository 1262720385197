import { Box, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import useBreakpoints, { breakpointUp } from 'src/hooks/useBreakpoints';
import BetFooter from './BetFooter';
import BetMessageBill from './BetMessageBill';
import BetStatistical from './BetStatistical';
import BetTable from './BetTable';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: 'rgba(0, 0, 0, 1) 0px 3px 3px 0px',
    margin: '0 !important',
    ...breakpointUp(theme, {
      lg: {
        height: '90.5%',
      },
      xl: {
        height: '92%',
      },
    }),
    paddingBottom: '5px',
  },
  containerItem: {
    padding: '0 5px',
    height: '100%',
  },
  containerItemFooter: {
    height: '8%',
    ...breakpointUp(theme, {
      lg: {
        height: '9.5%',
      },
      xl: {
        height: '8%',
      },
    }),
    backgroundColor: 'rgba(221, 221, 221, 0.8)',
  },
}));

const Home: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { getBreakpointDown } = useBreakpoints();

  return (
    <>
      <Grid container className={classes.container} gridTemplateColumns="repeat(12, 1fr)">
        <Grid item xl={3} lg={2.75} sm={6} xs={12} order={1} className={classes.containerItem}>
          <BetMessageBill />
        </Grid>
        <Grid
          item
          xl={7}
          lg={7}
          sm={12}
          xs={12}
          order={{ sm: 3, xl: 2, lg: 2, xs: 3 }}
          sx={getBreakpointDown({
            sm: {
              paddingBottom: '65px',
            },
            xs: {
              paddingBottom: '80px',
            },
          })}
          className={classes.containerItem}
        >
          <BetTable />
        </Grid>
        <Grid
          item
          xl={2}
          lg={2.25}
          sm={6}
          xs={12}
          order={{ sm: 2, xl: 3, lg: 3, xs: 2 }}
          className={classes.containerItem}
        >
          <BetStatistical />
        </Grid>
      </Grid>
      <Box className={classes.containerItemFooter}>
        <BetFooter />
      </Box>
    </>
  );
};

export default Home;
