import moment from 'moment';
import { DATE_FORMAT_TYPE_II } from 'src/constants';

export const getDateNowFormated = (date: Date): string => {
  return moment(date).format(DATE_FORMAT_TYPE_II);
};

export const getStartEndTimeStamp = (
  date: string,
): {
  startDateTimeStamp: number;
  endDateTimeStamp: number;
} => {
  const startDate = moment(date, DATE_FORMAT_TYPE_II).toDate();
  const endDate = new Date(startDate.getTime() + 23 * 3600 * 1000 + 59 * 60000);

  const startDateTimeStamp = moment(startDate).valueOf();
  const endDateTimeStamp = moment(endDate).valueOf();

  return {
    startDateTimeStamp,
    endDateTimeStamp,
  };
};

export const getDateFormat = (date: string): string => {
  return moment(date, DATE_FORMAT_TYPE_II).format('MM/DD/YYYY');
};

export const getDateFromTimestamp = (timestamp: number, format = 'DD/MM/YYYY'): string => {
  return moment.unix(timestamp).format(format);
};

export type DateTimestamp = {
  from: Date;
  to?: Date;
};

export const getFromToToday = (): DateTimestamp => {
  return {
    from: moment(Date.now()).toDate(),
  };
};

export const getDayOfWeekVI = (
  dayOfWeek: string,
): 'T2' | 'T3' | 'T4' | 'T5' | 'T6' | 'T7' | 'CN' => {
  switch (dayOfWeek) {
    case 'Monday':
      return 'T2';
    case 'Tuesday':
      return 'T3';
    case 'Wednesday':
      return 'T4';
    case 'Thursday':
      return 'T5';
    case 'Friday':
      return 'T6';
    case 'Saturday':
      return 'T7';
    case 'Sunday':
      return 'CN';
    default:
      break;
  }
};
