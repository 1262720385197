import React, { useState } from 'react';
import { FooterItem } from 'src/components/HorizontalItem';
import { alertInstance } from 'src/contexts/AlertContext';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import {
  addTabs,
  changeTabIdSelected,
  changeTabPosition,
  onChangeTabColorSelected,
  removeTab,
  selectTabs,
} from 'src/reducer/tabsSlice';
import {
  createTabApi,
  removeTabApi,
  updateTabApi,
  updateTabColorApi,
} from 'src/services/tabsServices';
import { useAppDispatch, useAppSelector } from 'src/store';
import { ID_TAB_DEFAULT, tabDataDefault } from './useDateTabs';
import { useEffectNavigateHandle } from './useEffectNavigateHandle';
import { ColorTab } from 'src/components/HorizontalItem/components/Item/type';

export const useTabsHandle = () => {
  const { dateSelected, data } = useAppSelector(selectTabs);

  useEffectNavigateHandle(null, (state) => {
    const { tabs } = data[dateSelected];
    createTabRequest({
      key: state.id.toString(),
      order: tabs.length,
    });
  });

  const { agencies } = useAppSelector(selectAgencies);
  const dispatch = useAppDispatch();

  const [tabsItem, setTabsItem] = React.useState<FooterItem[]>([]);
  const [tabsPosition, setTabsPosition] = React.useState<string[]>([]);
  const [tabsSelected, setTabsSelected] = React.useState<string>(ID_TAB_DEFAULT.toString());

  const [isLoadingAdd, setIsLoadingAdd] = useState<boolean>(false);
  const [indexRemoveLoading, setIndexRemoveLoading] = React.useState<number | null>(null);
  const [idCustomer, setIdCustomer] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (dateSelected) {
      const { tabs, posTabs, selected } = data[dateSelected] ?? {};
      if (tabs && tabs.length > 0) {
        const itemsData: FooterItem[] =
          tabs?.map((tab) => ({
            id: tab.id,
            name: tab.name,
            color: tab.color,
          })) ?? [];
        const idCustomerSelect = tabs
          .find((tab) => tab.id === selected)
          ?.data.idCustomer?.toString();
        setTabsItem(itemsData);
        setTabsPosition(posTabs);
        if (selected !== tabsSelected) {
          setTabsSelected(selected);
        }
        if (idCustomer !== idCustomerSelect) {
          setIdCustomer(idCustomerSelect);
        }
      }
    }
  }, [dateSelected, data]);

  const createTabRequest = async ({ key, order }: { key: string; order?: number }) => {
    try {
      setIsLoadingAdd(true);
      const { data } = await createTabApi({
        customerId: key,
        order,
      });
      const tabsTemp: any = {
        id: data.key.toString(),
        name: data.customer_name ?? '',
        data: {
          idCustomer: data.customer_id,
          ...tabDataDefault,
        },
      };
      dispatch(addTabs(tabsTemp));
    } catch (error) {
    } finally {
      setIsLoadingAdd(false);
    }
  };

  const addTabsWithDateSelected = () => {
    if (dateSelected) {
      const { tabs } = data[dateSelected];
      const idCustomers = tabs.map((item) => item.data.idCustomer);
      const idAgencies = agencies.find((item) => {
        const index = idCustomers.findIndex(
          (idCustomer) => idCustomer.toString() === item.key.toString(),
        );
        if (index === -1) {
          return true;
        } else {
          return false;
        }
      });
      if (idAgencies) {
        const { name, key } = idAgencies;
        createTabRequest({
          key: key.toString(),
          order: 0,
        });
      }
    }
  };

  const onChangeTabId = (id: string) => {
    dispatch(changeTabIdSelected(id));
  };

  const onChangeTabColor = (id: string, color: ColorTab) => {
    updateTabColorApi({
      customerId: idCustomer,
      tabId: id,
      color: JSON.stringify(color),
    })
      .then(() => {
        dispatch(
          onChangeTabColorSelected({
            id,
            color,
          }),
        );
        alertInstance.handleOpen('success', 'You have changed success');
      })
      .catch((err) => {
        alertInstance.handleOpen('error', err?.message ?? 'Something error');
      });
  };

  const onChangeTabPosition = (position: string[]) => {
    const order = position.findIndex((pos) => pos === tabsSelected);
    updateTabApi({
      customerId: idCustomer,
      tabId: tabsSelected,
      order: order,
    })
      .then((res) => {
        dispatch(changeTabPosition(position));
        alertInstance.handleOpen('success', 'You have changed success');
      })
      .catch((err) => {
        alertInstance.handleOpen('error', err?.message ?? 'Something error!');
      });
  };

  const removeTabRequest = async (id: string) => {
    try {
      const indexSelected = data[dateSelected].posTabs.findIndex((item) => item === id);
      if (indexSelected !== -1) {
        setIndexRemoveLoading(indexSelected);
        await removeTabApi({ tabId: id });
        dispatch(removeTab(id));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIndexRemoveLoading(null);
    }
  };

  const onRemoveTab = (id: string) => {
    removeTabRequest(id);
  };

  return {
    tabsItem,
    tabsPosition,
    tabsSelected,
    addTabsWithDateSelected,
    onChangeTabId,
    onChangeTabPosition,
    onRemoveTab,
    onChangeTabColor,

    isLoadingAdd,
    indexRemoveLoading,
  };
};
