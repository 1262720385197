import React from 'react';
import { onChangeTabData, selectTabs, TabsItem, TabsItemData } from 'src/reducer/tabsSlice';
import { useAppDispatch, useAppSelector } from 'src/store';

export const useChangeTabData = (callback: (dataTabSelected: TabsItem) => void) => {
  const dispatch = useAppDispatch();
  const { data, dateSelected } = useAppSelector(selectTabs);

  const onChangeCustomerData = (data: { name?: string; data: Partial<TabsItemData> }) => {
    dispatch(onChangeTabData(data));
  };

  React.useEffect(() => {
    if (dateSelected && data) {
      const dataSelected = data[dateSelected];
      if (dataSelected) {
        const tabSelected = dataSelected.selected;
        const dataTabSelected = dataSelected.tabs.find((tab) => tab.id === tabSelected);
        if (dataTabSelected) {
          callback(dataTabSelected);
        }
      }
    }
  }, [dateSelected, data]);

  return {
    onChangeCustomerData,
    dateSelected,
    dateTabData: data[dateSelected],
  };
};
