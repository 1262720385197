import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import MenuCustom, { MenuCustomRef } from 'src/components/Menu';
import { DATE_FORMAT_TYPE_II } from 'src/constants';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import { MAIN_COLOR } from 'src/styles/colors';
import { ButtonCustom } from 'src/styles/muiCustoms';

const menuItemStyle = {
  padding: '12px',
  width: '100%',
  cursor: 'pointer',
  marginBottom: '10px',
};

const buttonFooter = {
  borderRadius: '20px !important',
  padding: '7px 25px !important',
};
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F8F8FB',
    padding: '5px 10px !important',
    borderRadius: '30px !important',
    marginLeft: '20px',
    width: 'fit-content',
    position: 'relative',
  },
  expandButton: {
    marginLeft: '10px !important',
    backgroundColor: `${MAIN_COLOR} !important`,
  },
  menuContainer: {
    // padding: '20px',
    width: '300px',
  },
  menuItemSelect: {
    ...menuItemStyle,
    backgroundColor: MAIN_COLOR,
    color: 'white',
    borderRadius: 3,
  },
  menuItemNotSelect: {
    ...menuItemStyle,
    backgroundColor: 'white',
    color: 'black',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
  },
  buttonChoose: {
    ...buttonFooter,
    marginRight: '10px !important',
  },
  buttonDelete: {
    ...buttonFooter,
    borderColor: '#EBEDF2 !important',
    color: 'gray !important',
  },
}));

export type DateMenuRange = {
  from: Date;
  to?: Date;
};

interface DateMenu {
  id: string;
  value: string;
  date?: DateMenuRange;
}

export const ID_DATE_RANGE = '7';

export const dateMenu: DateMenu[] = [
  {
    id: '1',
    value: 'Hôm nay',
    date: {
      from: moment().toDate(),
    },
  },
  {
    id: '2',
    value: 'Hôm qua',
    date: {
      from: moment().subtract(1, 'days').toDate(),
    },
  },
  {
    id: '3',
    value: 'Tuần này',
    date: {
      from: moment().startOf('isoWeek').toDate(),
      to: moment().toDate(),
    },
  },
  {
    id: '4',
    value: 'Tuần trước',
    date: {
      from: moment().subtract(1, 'weeks').startOf('week').add(1, 'days').toDate(),
      to: moment().subtract(1, 'weeks').endOf('week').add(1, 'days').toDate(),
    },
  },
  {
    id: '5',
    value: 'Tháng này',
    date: {
      from: moment().startOf('month').toDate(),
      to: moment().toDate(),
    },
  },
  {
    id: '6',
    value: 'Tháng trước',
    date: {
      from: moment().subtract(1, 'month').startOf('month').toDate(),
      to: moment().subtract(1, 'month').endOf('month').toDate(),
    },
  },
  {
    id: ID_DATE_RANGE,
    value: 'Ngày tuỳ chọn',
  },
];

const getIndexDateRange = (id: string): number => {
  return dateMenu.findIndex((item) => item.id === id);
};

export type KeyOfDateMenu = Pick<DateMenu, 'id'>;

export type DateRange = [Date, Date];

const indexInit = 0;

function DateHistoryMenu({
  onChangeDate,
  idMenuInit,
  valueDateRangeInit,
  isDisable = false,
}: {
  onChangeDate: (data: DateMenuRange, idMenu: string) => void;
  idMenuInit?: KeyOfDateMenu;
  valueDateRangeInit?: DateMenuRange;
  isDisable?: boolean;
}) {
  const theme = useTheme() as Theme;
  const classes = useStyles(theme);
  const menuRef = useRef<MenuCustomRef>();
  const [indexSelect, setIndexSelect] = useState<number>(indexInit);
  const [value, onChange] = useState<DateRange>([new Date(), new Date()]);
  const [title, setTitle] = React.useState<string>(dateMenu[indexInit].value);
  const [dateTitle, setDateTitle] = React.useState<DateMenuRange>(dateMenu[indexInit].date);

  useEffect(() => {
    if (idMenuInit) {
      const indexInit = dateMenu.findIndex((item) => item.id === idMenuInit.id);
      if (indexInit !== -1 && indexInit !== indexSelect) {
        const dataMenuInit = dateMenu[indexInit];
        setIndexSelect(indexInit);
        setTitle(dataMenuInit.value);
        if (idMenuInit.id !== ID_DATE_RANGE && dataMenuInit.date) {
          setDateTitle(dataMenuInit.date);
        } else if (valueDateRangeInit) {
          const fromDate = valueDateRangeInit.from;
          const toDate = valueDateRangeInit.to ?? fromDate;
          setDateTitle(valueDateRangeInit);
          onChange([fromDate, toDate]);
        }
      }
    }
  }, [idMenuInit, valueDateRangeInit]);

  const handleClick = (event) => {
    menuRef.current.handleOpen(event);
  };

  const onConfirm = (index: number) => {
    const dataSelect = dateMenu[index];
    let dateSelect: DateMenuRange;

    // date picker
    if (dataSelect.id === ID_DATE_RANGE) {
      dateSelect = {
        from: value[0],
        to: value[1],
      };
    } else {
      dateSelect = dataSelect.date;
    }
    setTitle(dataSelect.value);
    setDateTitle(dateSelect);
    onChangeDate(dateSelect, dataSelect.id);
    menuRef.current.handleClose();
  };

  const dateShow = React.useMemo<string>(() => {
    const dateFrom = dateTitle.from ? moment(dateTitle.from).format(DATE_FORMAT_TYPE_II) : '';
    const dateTo = dateTitle.to ? moment(dateTitle.to).format(DATE_FORMAT_TYPE_II) : '';
    return `${dateFrom}${dateTo && ` - ${dateTo}`}`;
  }, [dateTitle.from, dateTitle.to]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
      <Box className={classes.container}>
        <Box
          onClick={handleClick}
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Typography sx={{ marginRight: 1 }}>{title}: </Typography>
          <Typography sx={{ color: '#6F6DC6' }}>{dateShow}</Typography>
          {!isDisable && (
            <IconButton
              className={classes.expandButton}
              disabled={isDisable}
              sx={{
                ':disabled': {
                  background: 'gray !important',
                },
              }}
            >
              <ExpandMoreIcon style={{ color: 'white' }} />
            </IconButton>
          )}
        </Box>
        <MenuCustom
          ref={menuRef}
          style={{
            padding: '20px 20px',
            width: '270px',
            ...breakpointDown(theme, {
              lg: {
                padding: '5px 15px',
              },
              sm: {},
              xs: {
                left: '15% !important',
                top: '15% !important',
                '::before': {
                  display: 'none',
                },
              },
            }),
          }}
        >
          {dateMenu.map((menu, index) => (
            <Box
              className={indexSelect === index ? classes.menuItemSelect : classes.menuItemNotSelect}
              onClick={(e) => {
                setIndexSelect(index);
                menu.id !== ID_DATE_RANGE && onConfirm(index);
              }}
            >
              {menu.value}
              {menu.id === ID_DATE_RANGE && (
                <Box
                  sx={{
                    mt: '5px',
                    input: {
                      color: indexSelect !== index ? 'black' : 'white',
                    },
                    '.react-daterange-picker': {
                      width: '100%',
                    },
                    '.react-daterange-picker__range-divider': {
                      padding: '0 5px',
                    },
                    '.react-daterange-picker__wrapper': {
                      border: 0,
                    },
                    '.react-daterange-picker__inputGroup': {
                      minWidth: '0%',
                      flexGrow: 0,
                    },
                    '.react-daterange-picker__button': {
                      padding: 0,
                    },
                    'button[class*="react-daterange-picker__clear"]': {
                      display: 'none',
                    },
                    'button[class*="react-daterange-picker__calendar"]': {
                      marginLeft: '10px',
                    },
                    '.react-calendar__tile--active': {
                      background: MAIN_COLOR,
                      ':enabled:hover': {
                        background: MAIN_COLOR,
                      },
                      ':enabled:focus': {
                        background: MAIN_COLOR,
                      },
                    },
                    '.react-calendar__tile--now': {
                      background: 'pink',
                    },
                    svg: {
                      stroke: indexSelect !== index ? 'black' : 'white',
                    },
                  }}
                >
                  <DateRangePicker onChange={onChange as any} value={value} />
                </Box>
              )}
            </Box>
          ))}
          <Box className={classes.footer}>
            <ButtonCustom
              variant="contained"
              className={classes.buttonChoose}
              myBackgroundColor="#29BFA5"
              onClick={() => onConfirm(getIndexDateRange(ID_DATE_RANGE))}
            >
              Chọn
            </ButtonCustom>
            <ButtonCustom
              variant="outlined"
              className={classes.buttonDelete}
              myBackgroundColor="white"
            >
              Xoá
            </ButtonCustom>
          </Box>
        </MenuCustom>
      </Box>
    </Box>
  );
}

export default DateHistoryMenu;
