import { Box, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { TextFieldCustom } from 'src/styles/muiCustoms';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
  },
  input: {
    width: '100px',
    marginLeft: '20px !important',
    ...breakpointDown(theme, {
      lg: {
        marginLeft: '5px !important',
        width: '70px',
      },
    }),
  },
}));

interface DividePercentCustomerProps {
  title: string;
  value?: number;
  handleChange: (value: number) => void;
}

const DividePercentCustomer: React.FC<DividePercentCustomerProps> = ({
  title,
  value,
  handleChange,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box className={classes.container}>
      <Typography width={'90px'}>{title}</Typography>
      <TextFieldCustom
        className={classes.input}
        id="central-number"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        value={value !== null && value !== undefined ? value.toString() : ''}
        onChange={(evt) => {
          handleChange(+evt.target.value);
        }}
        inputProps={{
          style: {
            height: '10px',
          },
          min: 0,
          max: 100,
          step: 'any',
        }}
        variant="outlined"
      />
    </Box>
  );
};

export default React.memo(DividePercentCustomer, (pre, next) => pre.value === next.value);
