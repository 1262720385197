import { Box, createTheme, ThemeProvider } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ErrorBoundary from './components/ErrorBoundary';

import Routes from 'src/routes';
import { MEDIA_QUERY } from './styles/breakpoints';
import { useLogin } from './hooks/useLogin';
import { useAppSelector } from './store';
import { selectAuth } from './reducer/authSlice';
import './index.css';
import LoadingPage from './components/LoadingPage';
import AlertWrapper from './contexts/AlertContext';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        fontFamily: 'roboto',
        borderRadius: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
        display: 'flex',
        'flex-flow': 'column',
      },
    },
  }),
);

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif !important',
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 800,
  },
  breakpoints: {
    values: MEDIA_QUERY,
  },
});

function App() {
  useStyles();
  useLogin();
  const { isFetching } = useAppSelector(selectAuth);
  if (isFetching) {
    return <LoadingPage />;
  }
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AlertWrapper>
          <Routes />
        </AlertWrapper>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
