import React from 'react';
import Box from '@mui/material/Box';
import { useReactToPrint } from 'react-to-print';
import { Button, styled, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  Direction,
  getReportGeneralDetail,
  ReportGeneral,
  ReportGeneralDetail,
} from 'src/services/reportServices';
import { getDateFromTimestamp } from 'src/utils/moment';
import { useAppSelector } from 'src/store';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import ComponentToPrintDetail from '../PrintResult/ComponentToPrintDetail';
import { Dai, DaiResult } from '../PrintResult/types';
import { ERROR_NOT_FOUND } from 'src/constants';

interface PrintResultDetailProps {}

const BoxContainer = styled(Box)({
  width: '100%',
  background: '#EAEBEE',
  paddingBlock: '20px',
});

const ContentContainer = styled(Box)({
  width: '70%',
  margin: '0 auto',
  background: 'white',
  padding: '10px 30px',
  minHeight: '100%',
  borderRadius: '5px',
});

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  alignItems: 'center',
});

const ContentHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '80%',
});

const TextHeader = styled(Typography)({
  fontWeight: 'bold',
});

const TextData = styled(Typography)({
  marginLeft: '10px',
});

const HorizontalBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

interface PrintNavigateStateDetail {
  idCustomer: string;
  tin: string;
  side: Dai;
  date: string;
}

const transferDaiToDirection = (side: Dai): Direction => {
  switch (side) {
    case 'B':
      return 'N';
    case 'T':
      return 'M';
    case 'N':
      return 'S';
  }
};

const getOrderFromTin = (tin: string): number => {
  const temp = tin.split(' ');
  if (temp.length > 1) {
    return Number(temp[1]);
  } else {
    return null;
  }
};

const transferPrintResultDetailData = (data: ReportGeneralDetail[]): DaiResult[] => {
  return (
    data.map((item) => ({
      tin: item.Message,
      tong2: item.t2,
      tong3: item.t3,
      trung2: item.w2,
      trung3: item.w3,
      trung4: item.w4,
      trungDa1D: item.dt,
      trungX: item.dx,
    })) ?? []
  );
};

const PrintResultDetailPage: React.FC<PrintResultDetailProps> = ({}) => {
  const componentRef = React.useRef();
  const location = useLocation();

  const customerId = (location.state as PrintNavigateStateDetail).idCustomer;
  const createAt = (location.state as PrintNavigateStateDetail).date;
  const side = (location.state as PrintNavigateStateDetail).side;
  const tin = (location.state as PrintNavigateStateDetail).tin;
  const [isLoading, setIsLoading] = React.useState(false);
  const { agencies } = useAppSelector(selectAgencies);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [reportData, setReportData] = React.useState<ReportGeneralDetail[]>([]);

  const name = React.useMemo<string>(() => {
    return agencies.find((item) => item.key.toString() === customerId.toString())?.name ?? '';
  }, [agencies, customerId]);

  React.useEffect(() => {
    if (customerId && createAt && side && tin) {
      const order = getOrderFromTin(tin);
      if (order !== null) {
        setIsLoading(true);
        getReportGeneralDetail({
          idCustomer: customerId,
          date: createAt,
          order,
          side: transferDaiToDirection(side),
        })
          .then((res) => {
            setReportData(res.data);
          })
          .catch((err) => {
            console.error('err123', err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        console.error(ERROR_NOT_FOUND, 'Order id not found!');
      }
    }
  }, [customerId, createAt, side, tin]);

  const results = React.useMemo<DaiResult[]>(
    () => transferPrintResultDetailData(reportData),
    [reportData],
  );

  return (
    <BoxContainer>
      <ContentContainer>
        <HeaderContainer>
          <ContentHeaderContainer>
            <HorizontalBox>
              <TextHeader>Ngày:</TextHeader>
              <TextData>{getDateFromTimestamp(+createAt, 'DD/MM/YYYY hh:mm:ss')}</TextData>
            </HorizontalBox>
            <HorizontalBox>
              <TextHeader>Khách:</TextHeader>
              <TextData>{name}</TextData>
            </HorizontalBox>
            <HorizontalBox>
              <TextHeader>Miền:</TextHeader>
              <TextData>{side}</TextData>
            </HorizontalBox>
            <HorizontalBox>
              <TextHeader>Tin:</TextHeader>
              <TextData>{tin}</TextData>
            </HorizontalBox>
          </ContentHeaderContainer>
          <Button variant="contained" onClick={handlePrint} disabled={isLoading}>
            In
          </Button>
        </HeaderContainer>
        {!isLoading ? (
          <ComponentToPrintDetail ref={componentRef} tins={results} />
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Loading...
          </Box>
        )}
      </ContentContainer>
    </BoxContainer>
  );
};

export default PrintResultDetailPage;
