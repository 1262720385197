import { LoadingButton } from "@mui/lab";
import { Box, Button, Theme, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React from "react";
import Modal, { ModalRef } from "src/components/Modal";
import { breakpointDown } from "src/hooks/useBreakpoints";
import { MAIN_COLOR } from "src/styles/colors";
import { TextFieldCustom } from "src/styles/muiCustoms";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    backgroundColor: 'white',
    borderRadius: '20px',
    overflow: 'hidden',
    padding: '20px',
    ...breakpointDown(theme, {
      sm: {
        width: '60%'
      },
      xs: {
        width: '90%'
      }
    })
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

interface EditNameCustomerModalProps {
  onCancel: () => void;
  onSave: (id: string, name: string) => void;
  isLoading?: boolean
}

export interface EditNameRef {
  handleOpen: (value: string, id: string) => void;
  handleClose: () => void;
  onChangeValue: (value: string) => void;

}

const EditNameCustomerModal = React.forwardRef<EditNameRef, EditNameCustomerModalProps>(({ onCancel, onSave, isLoading = false }, ref) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const modalRef = React.useRef<ModalRef>()
  const [value, setValue] = React.useState("")
  const idRef = React.useRef("")

  const handleOpen = (value: string, id: string) => {
    modalRef.current.handleOpen()
    setValue(value)
    idRef.current = id
  }

  const handleClose = () => modalRef.current.handleClose()
  const onChangeValue = (value: string) => setValue(value)

  React.useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    onChangeValue
  }), [])

  return (
    <Modal ref={modalRef}>
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Typography variant="h5">Edit name</Typography>
        </Box>
        <Box sx={{
          marginBlock: '20px'
        }}>
          <TextFieldCustom variant="outlined" fullWidth value={value} onChange={e => onChangeValue(e.target.value)} />
        </Box>
        <Box className={classes.footerContainer}>
          <Button variant="contained" sx={{
            background: '#bcbdbe',
            padding: '10px 40px',
            marginRight: '10px',
            ":hover": {
              background: '#abacae'
            }
          }}
            onClick={() => onCancel()}
          >Cancel</Button>
          <LoadingButton variant="contained"
            sx={{
              background: MAIN_COLOR,
              opacity: '0.8',
              padding: '10px 40px',
              ":hover": {
                background: MAIN_COLOR,
                opacity: '1',
              }
            }}
            onClick={() => onSave(idRef.current, value)}
            loading={isLoading}
            disabled={value === ''}
          >Save</LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
})

export default EditNameCustomerModal