import { useEffect } from "react"
import { getUser } from "src/reducer/authSlice"
import { useAppDispatch } from "src/store"
import { getSessionStorageAuth } from "src/utils"


export const useLogin = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        const data = getSessionStorageAuth()
        if (data.assetToken) {
            dispatch(getUser(data))
        }
    }, [])
}