import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ColorTab } from 'src/components/HorizontalItem/components/Item/type';
import { MessageServicesResponse, MessageServicesState } from 'src/services/messageServices';
import { AppState } from 'src/store';
import { getIndexByBet } from 'src/utils';
import { BetRadioType } from 'src/views/Home/BetMessageBill/BetMessageBillBetItem';

export type Summary = {
  valueLeft?: number;
  valueRight: number;
};

export type TabsItemData = {
  idCustomer: string | number | null;
  idCustomer2: string | number | null;
  idTab?: string;
  search: string;
  message: [string, string, string];
  bet: BetRadioType;
  dataMessage?: MessageServicesState & {
    error_part?: [string, string, string];
    range_error?: [[number, number], [number, number], [number, number]] | [[], [], []];
  };
};

export interface TabsItem {
  id: string;
  name: string;
  data: TabsItemData;
  color?: ColorTab;
  // data: {
  //     idCustomer: string | null,
  //     idTab?: string,
  //     bet: BetRadioType,
  //     message: string,
  //     search: string,
  //     parseMessages?: ParseMessages[],
  //     // confirm?: boolean,
  //     summary: Summary[]
  // },
}

interface TabsData {
  [date: string]: {
    tabs: TabsItem[];
    posTabs: string[];
    selected: string;
  };
}

interface TabsState {
  data: TabsData;
  dateSelected: string;
}

const initialState: TabsState = {
  data: {},
  dateSelected: '',
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    updateTabsExist: (
      state,
      action: PayloadAction<{
        data: MessageServicesState;
      }>,
    ) => {
      const dataState = action.payload.data;
      const dataSelected = state.data[state.dateSelected];
      if (dataState.state.customer_id) {
        const stateData = dataSelected.tabs.find(
          (item) => item.data.idCustomer.toString() === dataState.state.customer_id.toString(),
        );
        if (stateData) {
          if (dataState.state.optional_customer_id) {
            stateData.data.idCustomer2 = dataState.state.optional_customer_id;
          }
          stateData.data.dataMessage = { ...stateData.data.dataMessage, ...dataState };
          // stateData.data.message = dataState.state.raw_messages;
          stateData.data.message.map((mess, index) => {
            if (!mess) {
              stateData.data.message[index] = dataState.state.raw_messages[index];
            }
          });
        }
      }
    },
    addDataDefault: (state, action: PayloadAction<TabsState>) => {
      Object.entries(action.payload.data).forEach(([key, value]) => {
        // check not exist
        if (!state.data[key]) {
          state.data[key] = value;
        }
      });

      // state.data = { ...state.data, ...action.payload.data };
    },
    addTabs: (state, action: PayloadAction<TabsItem>) => {
      // const dataSelected = state.data[state.dateSelected];
      // dataSelected.tabs = [...dataSelected.tabs, action.payload];
      // dataSelected.posTabs = [action.payload.id, ...dataSelected.posTabs];
      // dataSelected.selected = action.payload.id;
      Object.entries(state.data).forEach(([key, value]) => {
        value.tabs = [...value.tabs, action.payload];
        const id = action.payload.id.toString();
        value.posTabs = [id, ...value.posTabs];
        value.selected = id;
      });
    },
    changeDateSelected: (state, action: PayloadAction<string>) => {
      state.dateSelected = action.payload;
    },
    changeTabIdSelected: (state, action: PayloadAction<string>) => {
      // const dataSelected = state.data[state.dateSelected];
      // if (dataSelected.selected !== action.payload) {
      //   dataSelected.selected = action.payload;
      // }
      Object.entries(state.data).forEach(([key, value]) => {
        value.selected = action.payload;
      });
    },
    changeTabPosition: (state, action: PayloadAction<string[]>) => {
      const dataSelected = state.data[state.dateSelected];
      dataSelected.posTabs = action.payload;
    },
    removeTab: (state, action: PayloadAction<string>) => {
      // const dataSelected = state.data[state.dateSelected];

      // if (dataSelected.tabs.length > 1) {
      //   const tabsFilter = dataSelected.tabs.filter((tab) => tab.id !== action.payload);
      //   const posTabFilter = dataSelected.posTabs.filter((pos) => pos !== action.payload);
      //   if (dataSelected.selected === action.payload) {
      //     const index = dataSelected.posTabs.findIndex((pos) => pos === action.payload);
      //     let newSelected;
      //     if (index > 0) {
      //       newSelected = posTabFilter[index - 1];
      //     } else {
      //       newSelected = posTabFilter[0];
      //     }
      //     dataSelected.selected = newSelected;
      //   }
      //   dataSelected.tabs = tabsFilter;
      //   dataSelected.posTabs = posTabFilter;

      // }
      Object.entries(state.data).forEach(([key, value]) => {
        if (value.tabs.length > 1) {
          const tabsFilter = value.tabs.filter((tab) => tab.id !== action.payload);
          const posTabFilter = value.posTabs.filter((pos) => pos !== action.payload);
          if (value.selected === action.payload) {
            const index = value.posTabs.findIndex((pos) => pos === action.payload);
            let newSelected;
            if (index > 0) {
              newSelected = posTabFilter[index - 1];
            } else {
              newSelected = posTabFilter[0];
            }
            value.selected = newSelected;
          }
          value.tabs = tabsFilter;
          value.posTabs = posTabFilter;
        }
      });
    },
    onChangeTabCustomer2: (state, action: PayloadAction<{ id: string; idCustomer2: string }>) => {
      Object.entries(state.data).forEach(([key, value]) => {
        const tabSelected = value.tabs?.find(
          (tab) => tab.id?.toString() === action.payload.id?.toString(),
        );
        if (tabSelected) {
          tabSelected.data.idCustomer2 = action.payload.idCustomer2;
        }
      });
    },
    onChangeTabColorSelected: (state, action: PayloadAction<{ id: string; color: ColorTab }>) => {
      Object.entries(state.data).forEach(([key, value]) => {
        const tabSelected = value.tabs?.find(
          (tab) => tab.id?.toString() === action.payload.id?.toString(),
        );
        if (tabSelected) {
          tabSelected.color = action.payload.color;
        }
      });
    },
    onChangeTabName: (state, action: PayloadAction<{ idCustomer: string; name: string }>) => {
      const dataSelected = state.data[state.dateSelected];
      const tabSelected = dataSelected?.tabs?.find(
        (tab) => tab.data.idCustomer?.toString() === action.payload.idCustomer?.toString(),
      );

      if (tabSelected) {
        tabSelected.name = action.payload.name;
      }
    },
    onChangeTabData: (
      state,
      action: PayloadAction<{
        name?: string;
        dataMessage?: MessageServicesResponse;
        message?: string;
        side?: BetRadioType;
        data?: Partial<TabsItemData>;
      }>,
    ) => {
      const { name, dataMessage, side, data, message } = action.payload;

      const dataSelected = _.cloneDeep(state.data[state.dateSelected]);
      const tabSelected = dataSelected?.tabs?.find((tab) => tab.id === dataSelected.selected);

      if (!tabSelected) return;

      if (name) {
        tabSelected.name = name;
      }
      if (data) {
        tabSelected.data = { ...tabSelected.data, ...data };
      }
      if (side !== undefined) {
        const index = getIndexByBet(side);
        if (index !== -1) {
          if (message !== null && message !== undefined) {
            tabSelected.data.message[index] = message;
          }
          if (dataMessage && side) {
            tabSelected.data.dataMessage.messages[index] = dataMessage.messages;
            tabSelected.data.dataMessage.result[index] = dataMessage.result;
            tabSelected.data.dataMessage.state = dataMessage.state;
            tabSelected.data.dataMessage.error_part[index] = dataMessage.error_part ?? '';
            tabSelected.data.dataMessage.range_error[index] = dataMessage.range_error ?? [];
            tabSelected.data.message[index] =
              dataMessage.raw_message ?? dataMessage?.state?.raw_messages?.[index] ?? '';
          }
        }
      }

      state.data = {
        ...state.data,
        [state.dateSelected]: dataSelected,
      };
    },
  },
});

export const {
  addDataDefault,
  addTabs,
  changeDateSelected,
  changeTabIdSelected,
  changeTabPosition,
  removeTab,
  onChangeTabName,
  onChangeTabData,
  onChangeTabCustomer2,
  updateTabsExist,
  onChangeTabColorSelected,
} = tabsSlice.actions;

export { TabsData, TabsState };

export const selectTabs = (state: AppState) => state.tabs;

export default tabsSlice.reducer;
