import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AlertColor } from '@mui/lab';
import Slide, { SlideProps } from '@mui/material/Slide';

interface AlertSnackBarProps extends AlertProps {
  // duration?: number;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export type AlertSnackBarRef = {
  handleOpen: (_severity: AlertColor, message: string, duration?: number) => void;
  handleClose: () => void;
};

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction="left" />;
}

const AlertSnackBar = React.forwardRef<AlertSnackBarRef, AlertSnackBarProps>(({}, ref) => {
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState<AlertColor>();
  const [message, setMessage] = React.useState<string>('');
  const [duration, setDuration] = React.useState(1000);

  const handleOpen = (_severity: AlertColor, message: string, duration?: number) => {
    setOpen(true);
    setSeverity(_severity);
    setMessage(message);
    if (duration) {
      setDuration(duration);
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  React.useImperativeHandle(
    ref,
    () => ({
      handleOpen,
      handleClose,
    }),
    [],
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      TransitionComponent={TransitionLeft}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
});

export default AlertSnackBar;
