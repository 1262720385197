import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import { IS_MOCK_API } from 'src/configs';
import { NODE_ENV } from 'src/constants';
import {
  getSessionAccessToken,
  getSessionRefreshToken,
  isDevelopment,
  NODE_ENV_TYPES,
  saveSessionStorageAuth,
} from 'src/utils';
import { BASE_URL, LOGIN_WITH_EMAIL_PASSWORD_API, REFRESH_TOKEN } from './configs';

const isAxiosMock = IS_MOCK_API && isDevelopment(NODE_ENV as NODE_ENV_TYPES) ? true : false;

const config = {
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const interceptors = (axiosMockInstance: any) => {
  axiosMockInstance.interceptors.request.use(
    (config) => {
      const token = getSessionAccessToken();
      if (token) {
        config.headers['Barrier'] = `${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axiosMockInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== LOGIN_WITH_EMAIL_PASSWORD_API && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          // if (err.response.status === 401 && !originalConfig._retry && refreshTokenCount === 0) {
          originalConfig._retry = true;
          try {
            const refreshTokenLocal = getSessionRefreshToken();
            axiosMockInstance.defaults.headers.common['Barrier'] = `${refreshTokenLocal}`;

            const axiosLiveInstanceNew = axios.create(config);
            axiosLiveInstanceNew.defaults.headers.common['Barrier'] = refreshTokenLocal;
            const { data } = await axiosLiveInstanceNew.get(REFRESH_TOKEN);
            const assetToken = data?.token;

            const refreshToken = data?.refresh_token;
            saveSessionStorageAuth('', assetToken, refreshToken, '');
            return axiosMockInstance(originalConfig);
          } catch (_error) {
            if (_error.response && _error.response?.data?.error === 'Session expired') {
              return Promise.reject(err.response.data);
            }
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
        }
        if (err.response.status === 403 && err.response.data) {
          return Promise.reject(err.response.data);
        }
      }
      return Promise.reject(err);
    },
  );
  return axiosMockInstance;
};

const axiosMockInstance = axios.create(config);
export const axiosMockAdapterInstance = new AxiosMockAdapter(interceptors(axiosMockInstance), {
  delayResponse: 0,
});

const axiosLiveInstance = axios.create(config);
interceptors(axiosLiveInstance);
export default isAxiosMock ? axiosMockInstance : axiosLiveInstance;
