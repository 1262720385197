import { AxiosResponse, CancelToken } from 'axios';
import axios from 'src/api';
import { MESSAGES, STATE } from 'src/api/configs';
import { BetRadioType } from 'src/views/Home/BetMessageBill/BetMessageBillBetItem';

export type Check = 0 | 1 | 2;

export type ParseMessages = {
  dai: string[];
  donVi: number;
  kieu: string;
  lo: number;
  loi: number;
  so: string;
  soTrung: string;
  tien: string;
  tin: string;
  trung: boolean;
  xac: number;
  _id: string;
};

export interface MessagesData {
  channels: string[];
  create_at: number;
  customer_id: string | number;
  date_checked: number;
  key: string;
  minus_brokers_money: number;
  money: number;
  numbers: (string | number)[];
  order: string;
  real_money: number;
  side: string;
  total_money: number;
  type: string;
  user_id: string | number;
  win_money: number;
  win_numbers: any[];
}

export interface ResultData {
  c3: [number, number];
  c4: [number, number];
  create_at: string;
  customer_id: string;
  date_checked: number;
  dauduoi: [number, number];
  dt: [number, number];
  dx: [number, number];
  key: string;
  lo: [number, number];
  loilo: number;
  plus2c: [number, number];
  plus3c: [number, number];
  side: string;
  xc: [number, number];
}

export interface StateData {
  create_at: number;
  customer_id: string;
  enabled: boolean;
  has_result: number;
  key: string;
  optional_customer_id: string;
  raw_messages: [string, string, string];
  results_id: [string, string, string];
  user_id: string | number;
  total: number;
}

export interface MessageServicesResponse {
  // date: string,
  // customerName: string,
  // check: Check,
  // messageContent: string,
  // agency: string,
  // id?: string,
  // messages: ParseMessages[],
  // profit?: number,
  // loss?: number,
  // confirmed: boolean,
  // summary: string[]
  messages: MessagesData[];
  result: ResultData;
  state: StateData;
  success: boolean;
  error_part: string;
  range_error: [number, number];
  raw_message: string;
}

export interface MessageServicesState {
  messages: [MessagesData[], MessagesData[], MessagesData[]];
  result: [ResultData, ResultData, ResultData];
  state: StateData;
  // total: number
}

export const postMessageApi = (data: {
  customer_id: string;
  side: BetRadioType;
  date_checked: number;
  message: string;
  type?: string;
  optional_customer_id?: string;
}): Promise<AxiosResponse<MessageServicesResponse>> => {
  return axios.post(`${MESSAGES}/bet`, {
    optional_customer_id: data.optional_customer_id || '',
    type: 'new',
    ...data,
  });
};

export const findMessageByDate = ({
  date,
  customerId,
  cancelToken,
}: {
  date: number; // timestamp
  customerId: string;
  cancelToken?: CancelToken;
}): Promise<AxiosResponse<MessageServicesState>> => {
  return axios.get(`${STATE}/${customerId}/${date}`, {
    cancelToken,
  });
};

export const deleteMessage = ({
  customerId,
  date,
  side,
}: {
  customerId: string;
  side: string;
  date: number;
}): Promise<AxiosResponse<void>> => {
  return axios.delete(`${MESSAGES}/delete-message/${customerId}/${side}/${date}`);
};

export const deleteAllMessage = ({
  userId,
  fromDate,
  side,
  toDate,
}: {
  userId: string;
  fromDate: number;
  toDate: number;
  side: string;
}): Promise<AxiosResponse<void>> => {
  return axios.delete(`${MESSAGES}/delete-all-message/${userId}/${side}/${fromDate}/${toDate}`);
};

//TODO: Edit message
export const editMessage = ({
  id,
  ...params
}: {
  id: string;
  customerName: string;
  messageContent: string;
  check: 0 | 1 | 2;
}): Promise<AxiosResponse<void>> => {
  return axios.patch(`${MESSAGES}/${id}`, params);
};
