import React from 'react'
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
interface LoadingPageProps {

}

interface LoadingPageRef {

}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  txtTitle: {
    marginTop: '50px !important'
  }
}))

const LoadingPage = React.forwardRef<LoadingPageRef, LoadingPageProps>(({ }, ref) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box sx={{ width: '50%' }}>
        <LinearProgress />
      </Box>
      <Typography variant='h6' className={classes.txtTitle}>
        Connecting...
      </Typography>
    </Box>
  );
})

export default LoadingPage