import { Box, MenuItem, SelectChangeEvent, Theme, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import React from 'react'
import { SelectCustom } from 'src/styles/muiCustoms'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
    },
    select: {
        marginLeft: '20px',
        height: '40px',
        width: '200px'
    }
}))

interface StorkTypes {
    id: StorkTypeID,
    value: string
}

export type StorkTypeID = '0' | '1'

const storks: StorkTypes[] = [
    {
        id: "0",
        value: 'Chưa Nhân'
    },
    {
        id: "1",
        value: "Đã Nhân"
    }
]

interface StorkCustomerProps {
    value?: StorkTypeID
    handleChange: (value: StorkTypeID) => void
}

const StorkCustomer: React.FC<StorkCustomerProps> = ({ value, handleChange }) => {
    const theme = useTheme()
    const classes = useStyles(theme)

    return (
        <Box className={classes.container}>
            <Typography>Loại Cò</Typography>
            <SelectCustom
                className={classes.select}
                value={value ? value.toString() : ''}
                onChange={e => {
                    handleChange(e.target.value as StorkTypeID)
                }}
                inputProps={{ 'aria-label': 'syntax' }}
            >
                {
                    storks.map(stork => (
                        <MenuItem value={stork.id} key={stork.id}>{
                            stork.value
                        }</MenuItem>
                    ))
                }
            </SelectCustom>
        </Box>
    )
}

export default React.memo(StorkCustomer, (preValue, nextValue) => preValue.value === nextValue.value)