import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/styles';
import React from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import EnhancedTableFooter from './Footer';
import EnhancedTableHead, { HeadCell } from './Header';

interface EnhancedTableProps<T> {
  initOrderBy: keyof T;
  bodyCells: Array<T>;
  children: (value: T) => React.ReactNode;
  headCells: HeadCell<T>[];
  styleTableRows?: React.CSSProperties;
  styleTableContainer?: SxProps<Theme>;
  styleContainer?: SxProps<Theme>;
  styleTable?: SxProps<Theme>;
  isPagination?: boolean;
  isFooter?: boolean;
  isLoading?: boolean;
  initOrder?: Order;
}

export type Order = 'asc' | 'desc';

const StyledTableRow = styled(TableRow)(({ theme, style }) => ({
  height: '50px',
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  '&.td': {},
  ...style,
  // hide last border
  // '&:last-child td, &:last-child th': {
  //     // border: 0,
  // },
}));

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F2F2F2',
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: '1px solid #E5E5E5',
    maxWidth: '300px',
    wordBreak: 'break-word',
    paddingBlock: '5px',
  },
}));

const LoadingComponent = () => <Typography>Loading...</Typography>;

function fixedHeaderContent<T>(columns: HeadCell<T>[]) {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.id.toString()}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent<T>(_index: number, row: T, columns: HeadCell<T>[]) {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell key={column.id.toString()} align={column.numeric || false ? 'right' : 'left'}>
          {row[column.id.toString()]}
        </TableCell>
      ))}
    </React.Fragment>
  );
}

function EnhancedTable<T>({
  initOrderBy,
  headCells,
  bodyCells,
  children,
  styleTableRows,
  styleTableContainer,
  styleContainer,
  styleTable,
  isPagination,
  isFooter,
  isLoading,
  initOrder = 'asc',
}: EnhancedTableProps<T>) {
  const [order, setOrder] = React.useState<Order>(initOrder);
  const [orderBy, setOrderBy] = React.useState<keyof T>(initOrderBy);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dataRows = React.useMemo(
    () => stableSort(bodyCells, getComparator(order, orderBy) as any),
    [bodyCells, order, orderBy],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        ...styleContainer,
      }}
    >
      <TableVirtuoso
        data={dataRows}
        components={{
          Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
            <TableContainer
              component={Paper}
              {...props}
              ref={ref}
              sx={{
                maxHeight: '100%',
                height: 'fit-content !important',
                '& > div': {
                  height: 'fit-content',
                  position: 'relative !important',
                },
                ...styleTableContainer,
              }}
            />
          )),
          Table: (props) => (
            <Table
              {...props}
              style={{ borderCollapse: 'separate' }}
              stickyHeader
              sx={{ minWidth: 750, ...styleTable }}
              aria-labelledby="tableTitle"
            />
          ),
          TableHead: () => <></>,
          TableRow: ({ item: _item, ...props }) => {
            return (
              <StyledTableRow style={styleTableRows} {...props} key={(_item as any).key}>
                {children(_item)}
              </StyledTableRow>
            );
          },
          TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
            <>
              <EnhancedTableHead<T>
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                onRequestSort={handleRequestSort}
              />
              {isLoading ? (
                <TableBody {...props} ref={ref}>
                  <TableRow>
                    <TableCell align="center" colSpan={headCells.length} sx={{ height: 100 }}>
                      <LoadingComponent />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : bodyCells.length === 0 ? (
                <TableBody {...props} ref={ref}>
                  <TableRow>
                    <TableCell align="center" colSpan={headCells.length} sx={{ height: 100 }}>
                      <Typography>No record found</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody {...props} ref={ref} />
              )}
              {isFooter && <EnhancedTableFooter<T> bodyCells={bodyCells} headCells={headCells} />}
            </>
          )),
        }}
        fixedHeaderContent={() => fixedHeaderContent(headCells)}
        itemContent={(_index, row) => rowContent(_index, row, headCells)}
      />
    </Box>
  );
}

export default EnhancedTable;
// export default memo(EnhancedTable, (prevProps, nextProps) => {
//   return _.isEqual(prevProps.bodyCells, nextProps.bodyCells);
// }) as any;
