import { DateTimestamp, getFromToToday } from 'src/utils/moment';
import { dateMenu, KeyOfDateMenu } from 'src/views/Report/DateHistoryMenu';
import { RegionSide } from 'src/views/Report/RegionSelect';
import { createCtx } from '.';
import { DateOfWeekType } from 'src/views/Report/DayOfWeekSelect';

export type FilterReportType = {
  search: string;
  side: RegionSide;
  dateOfWeek: DateOfWeekType[];
  date: DateTimestamp;
  idMenu: KeyOfDateMenu;
};

const [ctx, Provider] = createCtx<FilterReportType>({
  search: '',
  side: 'all',
  date: getFromToToday(),
  idMenu: {
    id: dateMenu[0].id,
  },
  dateOfWeek: [0, 1, 2, 3, 4, 5, 6],
});
export const FilterReportContext = ctx;
export const FilterReportProvider = Provider;
