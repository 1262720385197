import React from 'react';
import Menu from '@mui/material/Menu';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

export interface MenuCustomRef {
  handleOpen: (event) => void;
  handleClose: () => void;
}

interface MenuCustomProps {
  children: React.ReactNode;
  style?: SxProps<Theme>;
}

const MenuCustom = React.forwardRef<MenuCustomRef, MenuCustomProps>(({ children, style }, ref) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useImperativeHandle(
    ref,
    () => ({
      handleOpen,
      handleClose,
    }),
    [],
  );

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      // onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
          ...style,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </Menu>
  );
});

export default MenuCustom;
