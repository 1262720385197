import { BASE_URL_ENV } from 'src/constants';

export const LOGIN_WITH_EMAIL_PASSWORD_API = '/auth/login';
export const USER = '/user';
export const LOG_OUT = '/auth/logout';
export const REFRESH_TOKEN = '/auth/refresh-token';
export const AGENCIES = '/customer';
export const MESSAGES = '/message';
export const CHANGE_PASSWORD = '/auth/change-password';
export const RESET_PASSWORD = '/auth/reset-password';
export const GET_USERS_BY_ADMIN = '/user/list';
export const CREATE_USERS_BY_ADMIN = '/user/create';
export const CREATE_USERS_BY_ADMIN_V2 = '/user/v2/create';
export const LOCK_USERS_BY_ADMIN = '/user/lock';
export const UPDATE_USERS_BY_ADMIN = '/user/update';
export const EXPIRED_DATE_USERS_BY_ADMIN = '/user/subscription';

export const CUSTOMER_CREATE = 'customer/create';
export const CUSTOMER_LIST = 'customer/list';

export const REPORT_GENERAL = 'report/general';
// export const REPORT_DETAIL = 'report/detail';
export const REPORT_DETAIL_V2 = 'report/detailv2';
export const REPORT_DETAIL_V3 = 'report/detailv3';
export const REPORT_MESSAGE = 'report/messenger';
export const REPORT_MESSAGE_V3 = '/report/messengerv3';

export const BASE_URL = BASE_URL_ENV || '';

export const GET_TABS = '/tab/get-open';
export const CREATE_TAB = '/tab/new';
export const REMOVE_TAB = '/tab/close';
export const UPDATE_TAB = '/tab/update';
export const UPDATE_TAB_COLOR = '/tab/update-color';
export const UPDATE_TAB_OPTION_CUSTOMER = '/tab/update-optional-customer';

export const STATE = '/state';
