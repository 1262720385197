export const LOGIN_PAGE_PATH = '/login';
export const HOME_PAGE_PATH = '/';
export const CUSTOMER_PAGE_PATH = '/customer';
export const REPORT_PAGE_PATH = '/report';
export const USER_MANUAL_PAGE_PATH = '/user-manual';
export const LOGIN_ADMIN_PAGE_PATH = '/admin/login';
export const ADMIN_PAGE_PATH = '/admin';
// export const ADMIN_DASH_BOARD_PAGE_PATH = "/admin/dashboard"
export const PRINT_RESULT_PAGE_PATH = '/print-result';
export const PRINT_DETAIL_RESULT_PAGE_PATH = '/print-result/detail';
