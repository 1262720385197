import { Box, Button, Drawer, Typography, IconButton, Divider, Grid, TextField } from "@mui/material";
import React, { forwardRef, useImperativeHandle } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment";
import { postExpiredDateUser } from "src/services/userServices";
import { LoadingButton } from "@mui/lab";

interface AddExprireDateDrawerProps {
    onUpdateSuccess?: () => void;
}

export interface AddExprireDateDrawerRef {
    onOpen: (initValue: Date, initId: string) => void;
    onClose: (callback?: () => void) => void;
}

const MONTH_DAY = 30

type OptionDate = {
    id: string;
    label: string;
    value: number;
}

const OptionAdditional: OptionDate[] = [
    {
        id: '1',
        label: 'Thêm 1 tuần',
        value: 7
    },
    {
        id: '2',
        label: 'Thêm 1 tháng',
        value: MONTH_DAY
    },
    {
        id: '3',
        label: 'Thêm 3 tháng',
        value: 3 * MONTH_DAY
    },
    {
        id: '4',
        label: 'Thêm 6 tháng',
        value: 6 * MONTH_DAY
    },
    {
        id: '5',
        label: 'Thêm 1 năm',
        value: 365
    }
]

export const AddExprireDateDrawer = forwardRef<AddExprireDateDrawerRef, AddExprireDateDrawerProps>(
    ({ onUpdateSuccess }, ref) => {
        const [open, setOpen] = React.useState(false);
        const [value, setValue] = React.useState<Date>(
            moment().toDate()
        );
        const valueInit = React.useRef<Date | null>(null)
        const idInit = React.useRef<string>(null)
        const [isLoading, setIsLoading] = React.useState(false);

        const handleChange = (newValue: any) => {
            setValue(newValue);
        };


        const handleOpen = (initValue: Date, initId: string) => {
            setOpen(true)
            setValue(initValue)
            valueInit.current = initValue
            idInit.current = initId
        };
        const handleClose = (callback?: () => void) => {
            setOpen(false)
            callback && callback()
        };

        useImperativeHandle(ref, () => ({
            onOpen: handleOpen,
            onClose: handleClose
        }), [])

        const onClickAdditionalDate = (item: OptionDate) => {
            setValue(pre => moment(pre).add(item.value, 'days').toDate())
        }

        const onReset = () => {
            setValue(valueInit.current)
        }

        const onSave = () => {
            const postExpiredDateUserApi = async () => {
                try {
                    setIsLoading(true)
                    const timestamp = moment(value).unix()
                    await postExpiredDateUser({
                        id: idInit.current,
                        subscribe: timestamp
                    })
                    onUpdateSuccess && onUpdateSuccess()
                } catch (error) {

                } finally {
                    setIsLoading(false)
                }
            }
            if (idInit.current && value) {
                postExpiredDateUserApi()
            }
        }

        return (
            <>
                <Drawer
                    anchor={'right'}
                    open={open}
                >
                    <Box sx={{
                        width: '360px',
                        padding: '20px',
                        position: 'relative'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            alignItem: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Typography variant="h6" textTransform={'capitalize'}>Chỉnh sửa ngày hết hạn</Typography>
                            <IconButton onClick={() => {
                                setOpen(false)
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Divider sx={{
                            height: '0.0625rem',
                            margin: '1rem 0',
                            borderColor: 'rgba(0, 0, 0, 0.08)',
                            borderStyle: 'solid',
                            opacity: 0.25,
                            borderBottom: 'none',
                            backgroundImage: "linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0))!important"
                        }}
                        />
                        <Grid container spacing={'20px'}>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns as any}>
                                    <DesktopDatePicker
                                        label="Ngày Hết Hạn"
                                        inputFormat="dd/MM/yyyy"
                                        // minDate={moment().add(1, 'days').toDate()}
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField variant="standard" sx={{
                                            width: '100%'
                                        }} {...params} />}
                                    />

                                </LocalizationProvider>
                            </Grid>
                            {
                                OptionAdditional.map(item => (
                                    <Grid item xs={6} key={item.id}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            sx={{
                                                textTransform: 'capitalize'
                                            }}
                                            onClick={() => onClickAdditionalDate(item)}
                                        >{item.label}</Button>
                                    </Grid>
                                ))
                            }
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        textTransform: 'capitalize'
                                    }}
                                    onClick={onReset}
                                >Reset</Button>
                            </Grid>
                        </Grid>
                    </Box>


                    <Box sx={{ width: '100%', position: 'absolute', bottom: 0, padding: '10px' }}>
                        <LoadingButton loading={isLoading} fullWidth variant="contained"
                            onClick={onSave}
                        >Save</LoadingButton>
                    </Box>
                </Drawer>
            </>
        );
    }
);
export default AddExprireDateDrawer