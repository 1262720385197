import { Box, FormControlLabel, RadioGroup, Theme, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import React from 'react'
import { breakpointDown } from 'src/hooks/useBreakpoints'
import { RadioCustom } from 'src/styles/muiCustoms'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    txtTitle: {
        marginRight: '20px'
    },
    radioGroup: {
        marginLeft: '20px',
        ...breakpointDown(theme, {
            lg: {
                marginLeft: '10px'
            }
        })
    }
}))

interface Deliver {
    value: DeliverValue,
    label: string
}

export type DeliverValue = 'take' | 'deliver'

const delivers: Deliver[] = [
    {
        value: 'take',
        label: 'Nhận'
    },
    {
        value: 'deliver',
        label: 'Giao'
    }
]

interface DeliveryCustomerProps {
    value?: DeliverValue
    handleChange: (value: string) => void
}

const DeliveryCustomer: React.FC<DeliveryCustomerProps> = ({
    value,
    handleChange
}) => {
    const theme = useTheme()
    const classes = useStyles(theme)

    return (
        <Box className={classes.container}>
            <Typography>Giao, Nhận</Typography>
            <RadioGroup name="use-radio-group"
                value={value?.toString() ?? ''}
                row
                className={classes.radioGroup}
                onChange={e => {
                    handleChange(e.target.value)
                }}
            >
                {
                    delivers.map(deliver => (
                        <FormControlLabel value={deliver.value} control={<RadioCustom />} label={deliver.label} />
                    ))
                }
            </RadioGroup>
        </Box>
    )
}

export default React.memo(DeliveryCustomer, (preValue, nextValue) => {
    return preValue.value === nextValue.value
})