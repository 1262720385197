import { useEffect } from "react";
import { selectAgencies } from "src/reducer/agenciesSlice";
import { useAppSelector } from "src/store";

export const useRedirectCustomer = (callback: () => void) => {
  const { agencies } = useAppSelector(selectAgencies)

  useEffect(() => {
    if (agencies && agencies.length === 0) {
      callback()
    }
  }, [agencies?.length])
}