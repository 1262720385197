import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Agencies, AgenciesResponse, getAllAgenciesApi } from "src/services/agenciesServices";
import { AppState } from "src/store";
import { StatusResponse } from ".";

type AppSelectorAuth = {
    agencies?: AgenciesResponse[];
} & StatusResponse

export const getAllAgencies = createAsyncThunk("agencies/getAll", async ({ }: any, thunkAPI) => {
    try {

        const { data } = await getAllAgenciesApi()
        return data
    } catch (error) {
        return thunkAPI.rejectWithValue({ message: error.response?.data?.message ?? "Something error!" })
    }
})

// export const getAgenciesList = createAsyncThunk("agencies/getAgenciesList", async ({ }, thunkAPI) => {
//     try {
//         const { data } = await getAllAgenciesApi()
//         return data
//     } catch (error) {
//         return thunkAPI.rejectWithValue({ message: error.response?.data?.message ?? "Something error!" })
//     }
// })

const initialState: AppSelectorAuth = {
    isFetching: false,
    isError: false,
    errorMessage: "",
    isSuccess: false,
    agencies: null,
}

const agenciesSlice = createSlice({
    name: 'agencies',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllAgencies.pending, state => {
            state.isFetching = true;
            state.isError = false;
            state.isSuccess = false;
        })
        builder.addCase(getAllAgencies.fulfilled, (state, action) => {
            state.isFetching = false;
            state.isError = false;
            state.isSuccess = true;
            state.agencies = action.payload as any;
            state.errorMessage = ""
        })
        builder.addCase(getAllAgencies.rejected, (state, action: any) => {
            state.isFetching = false;
            state.isError = true;
            state.errorMessage = action.payload?.message ?? "Something Error!";
        })
        // builder.addCase(getAgenciesList.pending, state => {
        //     state.isFetching = true;
        //     state.isError = false;
        //     state.isSuccess = false;
        // })
        // builder.addCase(getAgenciesList.fulfilled, (state, action) => {
        //     state.isFetching = false;
        //     state.isError = false;
        //     state.isSuccess = true;
        //     state.agenciesList = [...state.agencies.results, ...action.payload.results]
        //     state.errorMessage = ""
        // })
        // builder.addCase(getAgenciesList.rejected, (state, action: any) => {
        //     state.isFetching = false;
        //     state.isError = true;
        //     state.errorMessage = action.payload?.message ?? "Something Error!";
        // })
    }
})

export const selectAgencies = (state: AppState) => state.agencies

export default agenciesSlice.reducer