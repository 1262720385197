import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageServicesResponse } from "src/services/messageServices";
import { AppState } from "src/store";

type AppSelectorMessages = {
  date: string;
  data: MessageServicesResponse[]
}

type AppSelectorMessagesArray = AppSelectorMessages[]

const initialState: AppSelectorMessagesArray = []
const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    updateMessage: (state, action: PayloadAction<AppSelectorMessages>) => {
      const indexDate = state.findIndex(item => item.date === action.payload.date)
      if (indexDate === -1) {
        return [...state, action.payload]
      } else {
        const cloneState = [...state]
        cloneState[indexDate] = action.payload
        return cloneState
      }
    }
  }
})

export const {
  updateMessage
} = messagesSlice.actions

export const selectConfig = (state: AppState) => state.messages

export default messagesSlice.reducer