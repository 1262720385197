import React from 'react';
import BackdropMui from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface BackdropProps {}

export interface BackdropRef {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const Backdrop = React.forwardRef<BackdropRef, BackdropProps>(({}, ref) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(true);
  };

  React.useImperativeHandle(
    ref,
    () => ({
      onOpen: handleToggle,
      onClose: handleClose,
      isOpen: open,
    }),
    [open],
  );

  return (
    <BackdropMui sx={{ color: '#fff', zIndex: 1301 }} open={open} onClick={handleClose}>
      <CircularProgress color="inherit" />
    </BackdropMui>
  );
});

export default Backdrop;
