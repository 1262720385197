import { Box, Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import * as React from 'react';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import ReactDOM from 'react-dom';
import ColorizeIcon from '@mui/icons-material/Colorize';
import IconButton from '@mui/material/IconButton';
import { ColorTab } from './type';

export default function BasicPopover({
  index,
  onShow,
  onChangeColor,
}: {
  index: number;
  onShow?: (show: boolean) => void;
  onChangeColor?: (color: ColorTab) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDisplayColorPicker(!displayColorPicker);
    onShow && onShow(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDisplayColorPicker(false);
    onShow && onShow(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState<ColorTab>({
    r: 241,
    g: 112,
    b: 19,
    a: 1,
  });

  const dynamicStyles = {
    color: {
      height: '14px',
      borderRadius: '2px',
      background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    },
    swatch: {
      padding: '5px',
      minWidth: '30px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: `0 0 0 1px rgba(0,0,0,.1)`,
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '100000',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  const handleChange = (color) => {
    setColor(color.rgb);
  };

  const onConfirm = () => {
    onChangeColor && onChangeColor(color);
    handleClose();
  };

  const portalRoot = document.getElementById(`portal-root-${index}`);

  return (
    <div>
      {portalRoot &&
        ReactDOM.createPortal(
          <>
            <IconButton
              onClick={handleClick as any}
              sx={{
                padding: '0',
              }}
            >
              <ColorizeIcon
                fontSize="small"
                sx={{
                  color: 'gray',
                  opacity: 1,
                }}
              />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box
                sx={{
                  width: 220,
                  height: 330,
                }}
              >
                {displayColorPicker ? (
                  <div style={dynamicStyles.popover as any}>
                    <div style={dynamicStyles.cover as any} onClick={handleClose} />
                    <SketchPicker color={color} onChangeComplete={handleChange} />
                    <Button fullWidth color="primary" onClick={onConfirm}>
                      Xác Nhận
                    </Button>
                  </div>
                ) : null}
              </Box>
            </Popover>
          </>,
          portalRoot, // Target DOM element
        )}
    </div>
  );
}
