import { Theme } from "@mui/material"
import { CSSProperties } from "@mui/styles"
import { breakpointDesktop, breakpointDown, breakpointUp } from "src/hooks/useBreakpoints"
import { MAIN_COLOR, PRIMARY_COLOR } from "./colors"

const betBlockTheme = (theme: Theme): CSSProperties => {
    return {
        boxShadow: `${MAIN_COLOR} 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px`,
        display: 'flex',
        flexDirection: 'column',
        height: "700px",
        ...breakpointUp(theme, {
            sm: {
                height: "700px",
            },
            lg: {
                height: "100%",
            }
        })
    }
}

const betBlockHeaderTheme = (): CSSProperties => {
    return {
        backgroundColor: MAIN_COLOR,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '40px',
        padding: '0 10px',
        marginBottom: '5px'
    }
}

const buttonPrimary = (theme: Theme): CSSProperties => {
    return {
        backgroundColor: PRIMARY_COLOR,
        borderRadius: '10px',
        color: 'white',
        ...breakpointDesktop(theme, {
            borderRadius: '7px',
            padding: "4px 15px",
            fontSize: 12
        })
    }
}

const headerTitle = (theme: Theme): CSSProperties => ({
    height: '50px',
    width: '100%',
    backgroundColor: MAIN_COLOR,
    display: 'flex',
    alignItems: 'center',
    padding: '0 25px',
    ...breakpointDown(theme, {
        lg: {
            height: '45px'
        }
    })
})

const buttonConfig = (theme: Theme): CSSProperties => ({
    padding: '10px 50px !important',
    height: '40px',
    ...breakpointDown(theme, {
        xs: {
            padding: "0 !important",
            width: '100%'
        }
    })
})

export {
    betBlockTheme,
    betBlockHeaderTheme,
    buttonPrimary,
    headerTitle,
    buttonConfig
}