import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataTableConfigHookReturn } from "src/hooks/useDataTableConfig";
import { AppState } from "src/store";


type AppSelectorAuth = {
    data: DataTableConfigHookReturn | null
}

const initialState: AppSelectorAuth = {
    data: null
}
const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateConfig: (state, action: PayloadAction<DataTableConfigHookReturn>) => {
            state.data = action.payload
        }
    }
})

export const {
    updateConfig
} = configSlice.actions

export const selectConfig = (state: AppState) => state.config

export default configSlice.reducer