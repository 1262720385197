import { TableFooter, Theme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles, useTheme } from '@mui/styles';
import { roundFloat } from 'src/utils';
import { HeadCell } from './Header';

interface EnhancedTableFooterProps<T> {
  bodyCells: Array<T>;
  headCells: HeadCell<T>[];
}

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    fontWeight: '800 !important',
    backgroundColor: '#F2F2F2 !important',
    fontFamily: 'Roboto, sans-serif !important',
    fontSize: '0.875rem !important',
  },
}));

function EnhancedTableFooter<T>(props: EnhancedTableFooterProps<T>) {
  const theme = useTheme() as Theme;
  const classes = useStyles(theme);
  const { bodyCells, headCells } = props;

  const getSum = (id: keyof T, isSum: boolean): number | null => {
    if (isSum) {
      return bodyCells.reduce((temp, item) => (temp += +item[id]), 0);
    } else {
      return null;
    }
  };

  const getStyleCell = (headCell: HeadCell<T>, sum): React.CSSProperties => {
    if (headCell.isWinStyle) {
      return sum > 0 ? { color: 'blue' } : sum < 0 ? { color: 'red' } : {};
    } else {
      return {};
    }
  };

  return (
    <TableFooter>
      <TableRow>
        {headCells.map((headCell) => {
          const sum = getSum(headCell.id, headCell.isSum);
          return (
            <TableCell
              key={headCell.id as string}
              align={headCell.align ?? 'center'}
              className={classes.footer}
              style={getStyleCell(headCell, sum)}
            >
              {headCell.sumLabel ? headCell.sumLabel : headCell.isSum ? roundFloat(sum) ?? '' : ''}
            </TableCell>
          );
        })}
      </TableRow>
    </TableFooter>
  );
}

export default EnhancedTableFooter;
