import { Box, MenuItem, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { breakpointDown } from 'src/hooks/useBreakpoints';
import { SelectCustom } from 'src/styles/muiCustoms';
import { permutations } from 'src/utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
  },
  select: {
    marginLeft: '20px',
    height: '40px',
    ...breakpointDown(theme, {
      lg: {
        marginLeft: '10px',
      },
    }),
  },
}));

export interface Syntax {
  id: string;
  value: string;
}

interface SyntaxCustomerProps {
  value?: string;
  data: Syntax[];
  title: string;
  isPermutation?: boolean;
  width?: number | string;
  handleChange?: (value: string) => void;
  isValueId?: boolean;
}

const getDataSelectPermutation = (data: Syntax[]): { id: string; value: string }[] => {
  if (data?.length <= 0) return null;
  const ids = data.map((item) => item.id);
  const arrPermutations = permutations(ids);
  return arrPermutations?.map((item, index) => {
    const tt = item.reduce((rep, i) => {
      const t = data.find((k) => k.id === i);
      if (!rep) {
        return rep + t.value;
      }
      return rep + ' - ' + t.value;
    }, '');
    return {
      id: item.toString().replaceAll(',', ''),
      value: tt,
    };
  });
};

const SyntaxCustomer: React.FC<SyntaxCustomerProps> = ({
  value,
  handleChange,
  title,
  data,
  isPermutation,
  width,
  isValueId = false,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const dataSelect = isPermutation ? getDataSelectPermutation(data) : data;

  return (
    <Box className={classes.container}>
      <Typography>{title}</Typography>
      {/* <Typography>Cú Pháp</Typography> */}
      <SelectCustom
        className={classes.select}
        sx={{ width: width || 'fit-content' }}
        value={value ? value.toString() : null}
        onChange={(e) => {
          handleChange && handleChange(e.target.value as string);
        }}
        inputProps={{ 'aria-label': 'syntax' }}
      >
        {dataSelect?.length > 0 &&
          dataSelect.map((syntax) => (
            <MenuItem value={syntax.id} key={syntax.id.toString()}>
              {syntax.value}
            </MenuItem>
          ))}
      </SelectCustom>
    </Box>
  );
};

export default React.memo(
  SyntaxCustomer,
  (preValue, nextValue) => preValue.value === nextValue.value,
);
