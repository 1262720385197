import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';

type TextInputProps = {
  value: string;
  inputStyle?: any;
  isHide?: boolean;

  onClick?: () => void;
};

const TextInputWithRef = forwardRef<HTMLDivElement, TextInputProps>(
  ({ value: valueProp, inputStyle, isHide, onClick }, ref) => {
    const [value, setValue] = React.useState('');

    React.useEffect(() => {
      if (valueProp !== value) {
        setValue(valueProp);
      }
    }, [valueProp]);

    const hideStyle = isHide
      ? {
          color: null,
          textIndent: -9999999,
        }
      : {};

    return (
      <TextField
        inputRef={ref}
        hiddenLabel
        value={isHide ? null : value}
        onChange={(evt) => {
          setValue(evt.target.value);
        }}
        onClick={() => {
          isHide && onClick && onClick();
        }}
        type={'number'}
        variant="outlined"
        disabled={isHide}
        size="small"
        sx={{
          padding: 0,
          height: '35px',
          input: {
            padding: 0,
            height: '35px',
            paddingLeft: '5px',
            textAlign: 'center',
            ...inputStyle,
            ...hideStyle,
          },
          fieldset: {
            border: '0px',
          },
        }}
      />
    );
  },
);

export default TextInputWithRef;
