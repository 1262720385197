import React from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Settings from '@mui/icons-material/Settings';
import { makeStyles } from '@mui/styles';
import MenuCustom, { MenuCustomRef } from 'src/components/Menu';
import EditPasswordModal, { EditPasswordRef } from 'src/views/Customer/EditPasswordModal';
import { useLogout } from 'src/hooks/useLogout';
import { getDateFromTimestamp } from 'src/utils/moment';
import { alertInstance } from 'src/contexts/AlertContext';
import { useChangePassword } from 'src/hooks/useChangePassword';

const useStyles = makeStyles(() => ({
  txtExpirationDate: {
    fontWeight: 'bold',
    color: 'red',
  },
}));

function SettingMenus({ expired }: { expired: number }) {
  const classes = useStyles();
  const menuRef = React.useRef<MenuCustomRef>(null);
  const {
    onLogout,
    onCancelModal,
    onChangePassword,
    onFailEditPassword,
    editPasswordRef,
    onSuccessEditPassword,
  } = useChangePassword();

  const handleClick = (event) => {
    menuRef.current.handleOpen(event);
  };

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleClick} size="small" aria-haspopup="true">
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
        </IconButton>
      </Tooltip>
      <MenuCustom ref={menuRef}>
        <MenuItem>
          <Typography variant="h6" className={classes.txtExpirationDate}>
            Ngày Hết Hạn: {expired ? getDateFromTimestamp(expired) : null}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onChangePassword}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Đổi Mật Khẩu
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Thoát
        </MenuItem>
      </MenuCustom>
      <EditPasswordModal
        ref={editPasswordRef}
        onCancel={onCancelModal}
        onSuccess={onSuccessEditPassword}
        onFail={onFailEditPassword}
      />
    </>
  );
}

export default SettingMenus;
