import React from 'react'
import { Outlet, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { REDIRECT_TO } from 'src/constants/searchParams'
import { selectAuth } from 'src/reducer/authSlice'
import { LOGIN_PAGE_PATH } from 'src/routes/paths'
import { useAppSelector } from 'src/store'

interface ProtectedRouterProps {
    children?: any,
    isAllow?: boolean,
    redirectPath?: string
}

const ProtectedRouter: React.FC<ProtectedRouterProps> = ({ children, redirectPath = LOGIN_PAGE_PATH }) => {
    const { token } = useAppSelector(selectAuth)
    const prevLocation = useLocation();
    const navigate = useNavigate()
    if (!token) {
        const locationFormatted = prevLocation.pathname.replace('/', '')
        navigate(locationFormatted ? `${redirectPath}?${REDIRECT_TO}=${locationFormatted}` : redirectPath)
        return <Navigate to={locationFormatted ? `${redirectPath}?${REDIRECT_TO}=${locationFormatted}` : redirectPath} />
    }
    return children ? children : <Outlet />
}

export default ProtectedRouter