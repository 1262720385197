import { Box, FormControl, Theme, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { alertInstance } from 'src/contexts/AlertContext';
import { useChangeTabData } from 'src/hooks/useChangeTabData';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import { onChangeTabCustomer2, TabsItem } from 'src/reducer/tabsSlice';
import { updateTabOptionCustomer } from 'src/services/tabsServices';
import { useAppDispatch, useAppSelector } from 'src/store';
import { TextFieldCustom } from 'src/styles/muiCustoms';
import { CustomerOption } from './BetMessageBillCustomerItem';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

interface BetMessageBillCustomerItemProps {}

const BetMessageBillCustomerOtherItem: React.FC<BetMessageBillCustomerItemProps> = ({}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { agencies } = useAppSelector(selectAgencies);
  const [idCustomer, setIdCustomer] = useState<string | number>();
  const [value, setValue] = React.useState('');
  const dispatch = useAppDispatch();

  const { dateTabData } = useChangeTabData((dataTabSelected: TabsItem) => {
    if (dataTabSelected.data.idCustomer) {
      setIdCustomer(dataTabSelected.data.idCustomer);
    }
    if (dataTabSelected.data.idCustomer2) {
      const customer = agencies?.find(
        (item) => item.key.toString() === dataTabSelected.data.idCustomer2.toString(),
      );
      if (customer) {
        setValue(customer.name);
      } else {
        setValue('');
      }
    } else {
      setValue('');
    }
  });

  const agenciesOptions = React.useMemo<CustomerOption[]>(() => {
    return (
      agencies
        ?.filter((item) => item.key !== idCustomer)
        .map((item) => ({
          id: item.key.toString(),
          label: item.name,
        })) ?? []
    );
  }, [agencies, idCustomer]);

  const handleChange = (value: CustomerOption | null) => {
    if (!dateTabData) return;
    const tabIdSelect = dateTabData.selected;
    updateTabOptionCustomer({
      tabId: tabIdSelect,
      customerId: idCustomer as string,
      optionCustomerId: value?.id as string,
    })
      .then((res) => {
        dispatch(
          onChangeTabCustomer2({
            id: tabIdSelect,
            idCustomer2: value ? value.id : null,
          }),
        );
        alertInstance.handleOpen('success', 'Update successfully');
      })
      .catch((err) => {
        alertInstance.handleOpen('error', err?.message ?? 'Something error');
      });
  };

  return (
    <Box className={classes.container}>
      <Typography sx={{ marginTop: '10px' }}>Khách 2</Typography>
      <FormControl sx={{ marginTop: '10px', width: '100%' }} size={'small'} variant={'standard'}>
        <Autocomplete
          sx={{ marginLeft: '12px' }}
          inputValue={value ?? ''}
          onInputChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          value={value as any}
          onChange={(e, newValue: any) => {
            handleChange(newValue);
          }}
          options={agenciesOptions}
          renderInput={(params) => {
            return <TextFieldCustom {...params} variant="standard" />;
          }}
        />
      </FormControl>
    </Box>
  );
};

export default BetMessageBillCustomerOtherItem;
