import { TextField, Theme, useTheme } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => ({
    inputLabel: {
        color: '#D6C9FA !important'
    },
    input: {},
    loginInput: {
        borderRadius: '7px',
        backgroundColor: '#9978F3',
        width: '60%',
    }
}))

interface TextInputProps {
    value: string,
    label: string,
    type?: React.InputHTMLAttributes<unknown>['type'];
    onChange: (value: string) => void
    variant?: 'default' | 'primary'
    className?: string
}

const TextInput: React.FC<TextInputProps> = ({ value,
    label,
    type, onChange,
    variant = 'primary',
    className
}) => {
    const theme = useTheme()
    const classes = useStyles(theme)

    return (
        <TextField
            InputLabelProps={{
                className: variant === 'primary' ? classes.inputLabel : null
            }}
            name="foo"
            InputProps={{
                className: variant === 'primary' ? classes.input : null,
                style: type === 'mk' ? {
                    "-webkit-text-security": "disc"
                } as any : {}
            }}
            autoComplete="off"
            size="small"
            className={className ?? classes.loginInput}
            label={label}
            variant="outlined"
            type={'text'}
            value={value}
            onChange={evt => onChange(evt.target.value)}
        />
    )
}

export default TextInput