import React from 'react';
import AlertSnackBar, { AlertSnackBarRef } from 'src/components/Alert';

export let alertInstance: AlertSnackBarRef;

const AlertWrapper: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const alertRef = React.useRef<AlertSnackBarRef>();
  React.useEffect(() => {
    alertInstance = alertRef.current;
  }, []);

  return (
    <>
      {children}
      <AlertSnackBar ref={alertRef} />
    </>
  );
};

export const useAlert = () => {};

export default AlertWrapper;
