import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DaiResult, HeaderDaiResult } from './types';
import { getTotalByColum } from './ComponentToPrint';

const Table = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  'th, td': {
    border: '1px solid #dddddd',
    width: '300px',
    fontSize: '16px',
    padding: '10px',
  },
  th: {
    textAlign: 'center',
    padding: '5px 0',
  },
  td: {
    textAlign: 'right',
    paddingTop: '10px',
    minWidth: '100px',
  },
  'td:first-child': {
    textAlign: 'left',
  },
  '.totalColumn': {
    fontWeight: 'bold',
  },
});

const headerResult: { key: HeaderDaiResult; value: string }[] = [
  { key: 'tin', value: 'Tin con' },
  { key: 'tong2', value: 'Tổng 2' },
  { key: 'tong3', value: 'Tổng 3' },
  { key: 'trung2', value: 'Trúng 2' },
  { key: 'trung3', value: 'Trúng 3' },
  { key: 'trung4', value: 'Trúng 4' },
  { key: 'trungDa1D', value: 'Trúng đá 1d' },
  { key: 'trungX', value: 'Trúng X' },
];

const ComponentToPrintDetail = React.forwardRef(
  (
    {
      tins,
    }: {
      tins: DaiResult[];
    },
    ref,
  ) => {
    return (
      <Box ref={ref}>
        <Table>
          <thead>
            <tr>
              {headerResult.map((item) => (
                <th>{item.value}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tins.map((item) => {
              return (
                <>
                  <tr>
                    <td
                      style={{
                        maxWidth: '500px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {item.tin}
                    </td>
                    <td>{item.tong2}</td>
                    <td>{item.tong3}</td>
                    <td>{item.trung2}</td>
                    <td>{item.trung3}</td>
                    <td>{item.trung4}</td>
                    <td>{item.trungDa1D}</td>
                    <td>{item.trungX}</td>
                  </tr>
                </>
              );
            })}
            <tr className="totalColumn">
              <td>Tổng:</td>
              <td>{getTotalByColum(tins, 'tong2')}</td>
              <td>{getTotalByColum(tins, 'tong3')}</td>
              <td>{getTotalByColum(tins, 'trung2')}</td>
              <td>{getTotalByColum(tins, 'trung3')}</td>
              <td>{getTotalByColum(tins, 'trung4')}</td>
              <td>{getTotalByColum(tins, 'trungDa1D')}</td>
              <td>{getTotalByColum(tins, 'trungX')}</td>
            </tr>
          </tbody>
        </Table>
      </Box>
    );
  },
);

export default ComponentToPrintDetail;
