import React from 'react';
import Box from '@mui/material/Box';
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint, { PrintResultData } from './ComponentToPrint';
import { Button, styled, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PrintNavigateState } from 'src/views/Home/BetStatistical';
import { getReportGeneral, ReportGeneral } from 'src/services/reportServices';
import { getDateFromTimestamp } from 'src/utils/moment';
import { useAppSelector } from 'src/store';
import { selectAgencies } from 'src/reducer/agenciesSlice';

interface PrintResultPageProps {}

const BoxContainer = styled(Box)({
  width: '100%',
  background: '#EAEBEE',
  paddingBlock: '20px',
});

const ContentContainer = styled(Box)({
  width: '70%',
  margin: '0 auto',
  background: 'white',
  padding: '10px 30px',
  minHeight: '100%',
  borderRadius: '5px',
});

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  alignItems: 'center',
});

const ContentHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '30%',
});

const TextHeader = styled(Typography)({
  fontWeight: 'bold',
});

const transferPrintResultData = (data: ReportGeneral): PrintResultData => {
  return {
    B:
      data['N']?.map((item) => ({
        tin: item.name,
        tong2: item.data.t2,
        tong3: item.data.t3,
        trung2: item.data.w2,
        trung3: item.data.w3,
        trung4: item.data.w4,
        trungDa1D: item.data.dt,
        trungX: item.data.dx,
      })) ?? [],
    T:
      data['M']?.map((item) => ({
        tin: item.name,
        tong2: item.data.t2,
        tong3: item.data.t3,
        trung2: item.data.w2,
        trung3: item.data.w3,
        trung4: item.data.w4,
        trungDa1D: item.data.dt,
        trungX: item.data.dx,
      })) ?? [],
    N:
      data['S']?.map((item) => ({
        tin: item.name,
        tong2: item.data.t2,
        tong3: item.data.t3,
        trung2: item.data.w2,
        trung3: item.data.w3,
        trung4: item.data.w4,
        trungDa1D: item.data.dt,
        trungX: item.data.dx,
      })) ?? [],
  };
};

const PrintResultPage: React.FC<PrintResultPageProps> = ({}) => {
  const componentRef = React.useRef();
  const location = useLocation();
  const { agencies } = useAppSelector(selectAgencies);

  const customerId = (location.state as PrintNavigateState).customerId;
  const createAt = (location.state as PrintNavigateState).createAt;
  const [isLoading, setIsLoading] = React.useState(false);

  const name = React.useMemo<string>(() => {
    return agencies.find((item) => item.key.toString() === customerId.toString())?.name ?? '';
  }, [agencies, customerId]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [reportData, setReportData] = React.useState<ReportGeneral>({
    M: [],
    N: [],
    S: [],
  });

  React.useEffect(() => {
    if (customerId && createAt) {
      setIsLoading(true);
      getReportGeneral({ idCustomer: customerId, date: createAt })
        .then((res) => {
          setReportData(res.data);
        })
        .catch((err) => {
          console.error('err123', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [customerId, createAt]);

  const printResultType = React.useMemo<PrintResultData>(
    () => transferPrintResultData(reportData),
    [reportData],
  );

  return (
    <BoxContainer>
      <ContentContainer>
        <HeaderContainer>
          <ContentHeaderContainer>
            <TextHeader>{name}</TextHeader>
            <TextHeader>{getDateFromTimestamp(+createAt, 'DD/MM/YYYY hh:mm:ss')}</TextHeader>
          </ContentHeaderContainer>
          <Button variant="contained" onClick={handlePrint} disabled={isLoading}>
            In
          </Button>
        </HeaderContainer>
        {!isLoading ? (
          <ComponentToPrint
            ref={componentRef}
            printResultType={printResultType}
            idCustomer={customerId}
            date={createAt}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Loading...
          </Box>
        )}
      </ContentContainer>
    </BoxContainer>
  );
};

export default PrintResultPage;
