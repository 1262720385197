import { LoadingButton } from '@mui/lab';
import { Box, Button, Theme, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Modal, { ModalRef } from 'src/components/Modal';
import { changePassword } from 'src/services/authServices';
import { MAIN_COLOR } from 'src/styles/colors';
import { TextFieldCustom } from 'src/styles/muiCustoms';
import { encryptPassword } from 'src/utils/encrypt';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '30%',
    backgroundColor: 'white',
    borderRadius: '20px',
    overflow: 'hidden',
    padding: '20px',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.7rem !important',
  },
}));

interface EditPasswordModalProps {
  onCancel: () => void;
  onSuccess?: () => void;
  onFail?: (message: string) => void;
}

export interface EditPasswordRef {
  handleOpen: () => void;
  handleClose: () => void;
}

type ChangePasswordForm = {
  password: string;
  newPassword: string;
  confirmPassword: string;
};

const EditPasswordModal = React.forwardRef<EditPasswordRef, EditPasswordModalProps>(
  ({ onCancel, onSuccess, onFail }, ref) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const modalRef = React.useRef<ModalRef>();
    const [isLoading, setIsLoading] = React.useState(false);
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
      control,
      reset,
    } = useForm<ChangePasswordForm>({
      defaultValues: {
        password: '',
        newPassword: '',
      },
    });

    const handleOpen = () => {
      modalRef.current.handleOpen();
    };

    const handleClose = () => modalRef.current.handleClose();

    React.useImperativeHandle(
      ref,
      () => ({
        handleOpen,
        handleClose,
      }),
      [],
    );

    const onSubmit = async (dataForm: ChangePasswordForm) => {
      const passwordEncrypt = encryptPassword(dataForm.password);
      const newPasswordEncrypt = encryptPassword(dataForm.newPassword);

      try {
        if (passwordEncrypt && newPasswordEncrypt) {
          setIsLoading(true);
          await changePassword(passwordEncrypt, newPasswordEncrypt);
          onSuccess && onSuccess();
        } else {
          throw new Error('Password Encrypt Fail!');
        }
      } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        onFail && onFail(errorResponse ?? error?.message ?? 'Something wrong!');
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Modal ref={modalRef}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            className={classes.container}
            sx={{
              width: {
                md: '30%',
                sm: '50%',
                xs: '90%',
              },
            }}
          >
            <Box className={classes.headerContainer}>
              <Typography variant="h5">Thay Đổi Mật Khẩu</Typography>
            </Box>
            <Box
              sx={{
                marginBlock: '20px',
              }}
            >
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextFieldCustom
                    variant="outlined"
                    label="Mật Khẩu Cũ"
                    isError={errors.password?.type === 'required'}
                    fullWidth
                    type={'password'}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                marginBlock: '20px',
              }}
            >
              <Controller
                name="newPassword"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextFieldCustom
                    variant="outlined"
                    label="Mật Khẩu Mới"
                    isError={errors.newPassword?.type === 'required'}
                    fullWidth
                    type={'password'}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                marginBlock: '20px',
              }}
            >
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    if (watch('newPassword') !== value) {
                      return 'Mật Khẩu Của Bạn Không Khớp';
                    }
                  },
                }}
                render={({ field }) => (
                  <TextFieldCustom
                    variant="outlined"
                    label="Nhập Lại Mật Khẩu Mới"
                    isError={
                      errors.confirmPassword?.type === 'required' ||
                      errors.confirmPassword?.type === 'validate'
                    }
                    fullWidth
                    type={'password'}
                    {...field}
                  />
                )}
              />
              <Typography className={classes.errorMessage}>
                {errors.confirmPassword?.message}
              </Typography>
            </Box>
            <Box className={classes.footerContainer}>
              <Button
                variant="contained"
                sx={{
                  background: '#bcbdbe',
                  padding: '10px 40px',
                  marginRight: '10px',
                  ':hover': {
                    background: '#abacae',
                  },
                }}
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                sx={{
                  background: MAIN_COLOR,
                  opacity: '0.8',
                  padding: '10px 40px',
                  ':hover': {
                    background: MAIN_COLOR,
                    opacity: '1',
                  },
                }}
                type="submit"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </Modal>
    );
  },
);

export default EditPasswordModal;
