import React, { useRef, useState } from 'react';
import { selectAgencies } from 'src/reducer/agenciesSlice';
import { addDataDefault, changeDateSelected, selectTabs, TabsItem } from 'src/reducer/tabsSlice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getTabsApi, createTabApi, Tab } from 'src/services/tabsServices';

export const ID_TAB_DEFAULT = '1';

export const tabDataDefault = {
  message: ['', '', ''],
  bet: 'S',
  search: '',
  dataMessage: {
    messages: [[], [], []],
    result: [{}, {}, {}],
    error_part: ['', '', ''],
    range_error: [[], [], []],
  },
};

export const useDateTabs = (date: string) => {
  const { data } = useAppSelector(selectTabs);
  const { agencies } = useAppSelector(selectAgencies);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isGetTab = useRef(false);

  const dataSelected = React.useMemo(() => {
    return Object.entries(data)?.[0]?.[1];
  }, [data]);
  const idTabSelected = dataSelected?.selected;
  const tabsSelected = dataSelected?.tabs;

  React.useEffect(() => {
    const getTabsApiRequest = async ({ key, name }: { key: string; name: string }) => {
      try {
        isGetTab.current = true;
        setIsLoading(true);
        const { data } = await getTabsApi();
        if (data.length === 0) {
          // add default
          const { data: dataTab } = await createTabApi({ customerId: key });
          addTabDefault({
            name: dataTab.customer_name ?? '',
            idCustomer: dataTab.customer_id.toString(),
            idTab: dataTab.key.toString(),
          });
        } else {
          addBulkTabsData(data, null, null);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (date && !isGetTab.current) {
      if (agencies?.length > 0) {
        // if (tabs.length < 1 && agencies?.length > 0) {
        const { key, name } = agencies[0];
        if (key) {
          getTabsApiRequest({
            key: key.toString(),
            name,
          });
        }
      }
    }
  }, [date, agencies?.length]);

  React.useEffect(() => {
    if (date && isGetTab.current && tabsSelected?.length > 0) {
      addBulkTabsData(null, tabsSelected, idTabSelected);
    }
  }, [date, tabsSelected?.length, idTabSelected]);

  React.useEffect(() => {
    if (date) {
      dispatch(changeDateSelected(date));
    }
  }, [date]);

  const addTabDefault = ({
    name,
    idCustomer,
    idTab,
  }: {
    name: string;
    idCustomer: string;
    idTab: string;
  }) => {
    const tabsDefault: any = {
      data: {
        [date]: {
          tabs: [
            {
              id: idTab,
              name: name ?? '',
              data: {
                idCustomer,
                ...tabDataDefault,
              },
            },
          ],
          posTabs: [idTab],
          selected: idTab,
        },
      },
      dateSelected: date,
    };
    dispatch(addDataDefault(tabsDefault));
  };

  const addBulkTabsData = (
    tabs: Tab[] | null,
    tabsItem: TabsItem[] | null,
    idTabSelected: string | null,
  ) => {
    if (tabs?.length > 0 || tabsItem?.length > 0) {
      const bulkTabs =
        tabs?.length > 0
          ? tabs.map<any>((item) => {
              return {
                id: item.key.toString(),
                name: item.customer_name ?? '',
                color: item.color ? JSON.parse(item.color) : '',
                data: {
                  idCustomer: item.customer_id,
                  idCustomer2: item.optional_customer_id,
                  ...tabDataDefault,
                },
              };
            })
          : tabsItem?.length > 0
          ? tabsItem.map((item) => ({
              id: item.id,
              name: item.name ?? '',
              color: item.color,
              data: {
                ...item.data,
                ...tabDataDefault,
              },
            }))
          : [];
      if (bulkTabs.length > 0) {
        const tabsDefault: any = {
          data: {
            [date]: {
              tabs: bulkTabs,
              posTabs: bulkTabs.map((item) => item.id.toString()),
              selected: idTabSelected ? idTabSelected : bulkTabs[0].id,
            },
          },
          dateSelected: date,
        };
        dispatch(addDataDefault(tabsDefault));
      }
    }
  };

  return {
    isLoading,
  };
};
