import React from 'react';
import { Switch } from '@mui/material';
import _ from 'lodash';

export type ChangeSwitchEvent = {
  id: string;
  isChecked: boolean;
};

interface SwitchToggleProps {
  initChecked?: boolean;
  id: string;
  onChangeSwitch?: (params: ChangeSwitchEvent) => void;
}

const SwitchToggle: React.FC<SwitchToggleProps> = ({ id, initChecked, onChangeSwitch }) => {
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    // if (initChecked && initChecked !== checked) {
    setChecked(initChecked);
    // }
  }, [initChecked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChangeSwitch &&
      onChangeSwitch({
        id,
        isChecked: event.target.checked,
      });
  };

  return <Switch checked={checked} onChange={handleChange} />;
};

export default SwitchToggle;
