import { Box, Divider, Typography } from '@mui/material';
import React, { Suspense } from 'react';
import { Link, Outlet } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import SideNavCollapse from 'src/components/SidenavCollapse';
import { SidebarData } from 'src/routes/sidebar';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Button from '@mui/material/Button';
import EditPasswordModal from 'src/views/Customer/EditPasswordModal';
import { useChangePassword } from 'src/hooks/useChangePassword';

interface AdminContainerProps {}

const AdminContainerLayout: React.FC<AdminContainerProps> = ({}) => {
  const [isActive, setIsActive] = React.useState(0);
  const {
    onLogout,
    onCancelModal,
    onChangePassword,
    onFailEditPassword,
    editPasswordRef,
    onSuccessEditPassword,
  } = useChangePassword();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          background: '#f0f2f5',
        }}
      >
        <Box
          sx={{
            width: '250px',
            borderRadius: '0.75rem',
            margin: '1rem',
            background: ' linear-gradient(195deg, #42424a, #191919)',
            justifyContent: 'space-between',
            flexDirection: 'column',
            flexGrow: 1,
            display: { xs: 'none', sm: 'none', md: 'flex' },
          }}
        >
          <Box>
            <Box
              sx={{
                paddingTop: '24px',
                paddingBottom: '8px',
                pl: '32px',
                pr: '32px',
                textAlign: 'center',
                opacity: 1,
                background: 'transparent',
                color: '#344767',
              }}
            >
              <Link
                to={'#'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AdminPanelSettingsIcon
                  sx={{
                    color: 'white',
                    width: '2rem',
                    height: '2rem',
                  }}
                />
                <Typography
                  sx={{
                    color: 'white',
                    fontSize: '0.875rem',
                    ml: '5px',
                  }}
                >
                  Admin Dashboard
                </Typography>
              </Link>
            </Box>
            <Divider
              sx={{
                height: '0.0625rem',
                margin: '1rem 0',
                borderColor: 'rgba(0, 0, 0, 0.08)',
                borderStyle: 'solid',
                opacity: 0.25,
                borderBottom: 'none',
                backgroundImage:
                  'linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0))!important',
              }}
            />
            {SidebarData.map((item, index) => {
              return (
                <SideNavCollapse
                  icon={item.icon}
                  name={item.name}
                  path={item.path}
                  active={isActive === index}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Button color={'primary'} fullWidth onClick={onChangePassword}>
              Thay đổi mật khẩu
            </Button>
            <Button color={'error'} fullWidth onClick={onLogout}>
              Đăng Xuất
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            padding: { xs: '5px', sm: '24px' },
            width: '100%',
          }}
        >
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
      <EditPasswordModal
        ref={editPasswordRef}
        onCancel={onCancelModal}
        onSuccess={onSuccessEditPassword}
        onFail={onFailEditPassword}
      />
    </>
  );
};

export default AdminContainerLayout;
